//Basic redux toolkit imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  createConsultation,
  getConsultation,
  updateConsultation,
  listConsultations
} from '../../api/consultation';

import { getConsultationById } from '../commonAsyncThunks';
import { logout } from './authSlice';

const getConsultations = createAsyncThunk(
  'consultations/getConsultationsList',
  async (data, thunkAPI) => {
    try {
      const response = await listConsultations(data);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const createNewConsultation = createAsyncThunk(
  'consultations/createConsultation',
  async (data, thunkAPI) => {
    try {
      const response = await createConsultation(data);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }

  }
);

const endConsultation = createAsyncThunk(
  'consultations/endConsultation',
  async (id, thunkAPI) => {
    const data = { status: 'finished' };
    try {
      const response = await updateConsultation(id, data);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
)

const updateConsultationWithData = createAsyncThunk(
  'consultations/update',
  async (data, thunkAPI) => {
    const { id, ...restData } = data || {};
    try {
      const response = await updateConsultation(id, restData);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
)

const refreshCurrentConsultation = createAsyncThunk(
  'consultation/refreshCurrent',
  async (id, thunkAPI) => {
    let idToFetch = id ?? thunkAPI.getState().current.id;
    try {
      const response = await getConsultation(idToFetch);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// const getConsultationById = createAsyncThunk(
//   'consultation/getConsultationById',
//   async (id, thunkAPI) => {
//     let idToFetch = id ?? thunkAPI.getState().current.id;
//     try {
//       const response = await getConsultation(idToFetch);
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const initialState = {
  current: {},
  outgoing: null,
  consultationsList: [],
  isFetchingConsultationList: false
};

const setCurrentConsultationHandler = (state, action) => {
  const consultationId = action.payload.id;

  state.current = action.payload;
  if (state.outgoing?.id != null && state.outgoing?.id === consultationId) {
    state.outgoing = action.payload;
  }
};

const consultationSlice = createSlice({
  name: 'consultation',
  initialState,
  reducers: {
    setCurrentConsultation: setCurrentConsultationHandler,
    clearCurrentConsultation: (state) => {
      state.current = {};
    },
    clearOutgoingConsultation: (state) => {
      state.outgoing = null;
    }
  },
  extraReducers: {
    [createNewConsultation.fulfilled]: (state, action) => {
      state.outgoing = action.payload;
    },
    [endConsultation.fulfilled]: (state, action) => {
      state.current = {};
      state.outgoing = null;
    },
    [updateConsultationWithData.fulfilled]: (state, action) => {
      const consultationId = action.payload.id;
      if (state.current.id === consultationId) {
        state.current = {
          ...state.current,
          ...action.payload,
        };
      }
      if (state.outgoing?.id != null && state.outgoing?.id === consultationId) {
        state.outgoing = {
          ...state.outgoing,
          ...action.payload,
        };
      }

      const index = state.consultationsList.findIndex(tmp => tmp.id === consultationId);
      if (index > -1) {
        const tmp = [...state.consultationsList];
        tmp[index] = {
          ...tmp[index],
          ...action.payload,
        };
        state.consultationsList = tmp;
      }
    },
    [refreshCurrentConsultation.fulfilled]: setCurrentConsultationHandler,
    [getConsultations.pending]: (state, action) => {
      state.isFetchingConsultationList = true;
    },
    [getConsultations.fulfilled]: (state, action) => {
      const page = action.meta?.arg?.page || 1;
      let theList = [...action.payload.results];
      if (page > 1) {
        // append
        theList = [...state.consultationsList, ...theList];
      }
      state.consultationsList = theList;
      state.isFetchingConsultationList = false;
    },
    [getConsultations.rejected]: (state, action) => {
      state.isFetchingConsultationList = false;
    },
    [getConsultationById.fulfilled]: (state, {payload}) => {
      const { current, outgoing } = state;
      if (current.id != null && current.id === payload.id) {
        state.current = {...payload};
      }
      if (outgoing?.id != null && outgoing?.id === payload.id) {
        state.outgoing = {...payload};
      }
    },
    [logout]: (state) => {
      state = {...initialState};
    }
  }
});

export const getConsultationsList = ({ consultation }) => consultation.consultationsList;

// Destructure and export the plain action creators
// export const { } = authSlice.actions;
export { createNewConsultation, endConsultation, updateConsultationWithData, getConsultationById, getConsultations, refreshCurrentConsultation };
export const { clearOutgoingConsultation, clearCurrentConsultation, setCurrentConsultation } = consultationSlice.actions;

export const consultationReducer = consultationSlice.reducer;
