// @flow
import { useState, useEffect } from 'react';

export type MediaDeviceInfo = {
  /** Returns a DOMString that is an identifier for the represented device that is persisted across sessions. It is un-guessable by other applications and unique to the origin of the calling application. It is reset when the user clears cookies (for Private Browsing, a different identifier is used that is not persisted across sessions). */
  deviceId: string,
  /** Returns a DOMString that is a group identifier. Two devices have the same group identifier if they belong to the same physical device — for example a monitor with both a built-in camera and a microphone. */
  groupId: string,
  /** Returns an enumerated value that is either "videoinput", "audioinput" or "audiooutput". */
  kind: 'videoinput' | 'audioinput' | 'audiooutput',
  /** Returns a DOMString that is a label describing this device (for example "External USB Webcam"). */
  label: string,
};

export default function useDevices() {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const getDevices = () =>
      navigator?.mediaDevices
        ?.enumerateDevices()
        ?.then((newDevices) => setDevices(newDevices));
    navigator?.mediaDevices?.addEventListener('devicechange', getDevices);
    getDevices();

    return () => {
      navigator?.mediaDevices?.removeEventListener('devicechange', getDevices);
    };
  }, []);

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(
      (device) => device.kind === 'audiooutput',
    ),
    hasAudioInputDevices:
      devices.filter((device) => device.kind === 'audioinput').length > 0,
    hasVideoInputDevices:
      devices.filter((device) => device.kind === 'videoinput').length > 0,
  };
}
