import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './slices/rootReducer';

import saveAuthToken from './middleware/auth-middleware';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['symptoms_checker', 'application', 'doctors'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }).concat(saveAuthToken)
})

let persistor = persistStore(store);

export { store, persistor };
