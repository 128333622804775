//Basic redux toolkit imports
import { createSlice } from '@reduxjs/toolkit';
// import { useAuth0 } from '@auth0/auth0-react';
import { isValidJwtToken, isTokenExpired } from '../../utils/jwt';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null
  },
  reducers: {
    login: (state, { payload }) => {
      state.accessToken = payload;
    },
    logout: (state, action) => {
      state.accessToken = null;
    }
  }
});

export const isUserAuthenticated = ({ auth }) =>
  isValidJwtToken(auth.accessToken) && !isTokenExpired(auth.accessToken);

export const getUser = ({ account }) => account.user;
export const getToken = ({ auth }) => auth.accessToken;

// Destructure and export the plain action creators
export const { login, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
