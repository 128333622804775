// @flow
import { useSelector } from 'react-redux';
import type { Consultation, ConsultationParticipant } from '../config/types';

/**
 * Retrieves the participants of a given consultation.
 *
 * @param {Consultation} [consultation]
 * @returns {{
 *   me?: ConsultationParticipant,
 *   participant1?: ConsultationParticipant,
 *   participant2?: ConsultationParticipant,
 * }}
 */
export const useConsultationParticipants = (consultation?: Consultation): {

  me?: ConsultationParticipant,
  participant1?: ConsultationParticipant,
  participant2?: ConsultationParticipant,
} => {
  const currentUser = useSelector(state => state.profile.user);

  let me;
  let participant1;
  let participant2;

  console.log('consultation', consultation);
  if (consultation != null) {
    me = consultation.participants?.find(
      (item: ConsultationParticipant) => item.user?.id === currentUser.id,
    );
    if (me) {
      let participant1Type;
      let participant2Type;
      if (me.type === 'patient') {
        participant1Type = 'doctor';
        participant2Type = 'guest';
      } else if (me.type === 'doctor') {
        participant1Type = 'patient';
        participant2Type = 'guest';
      } else {
        participant1Type = 'patient';
        participant2Type = 'doctor';
      }

      participant1 = consultation.participants?.find(
        (item) => item.type === participant1Type,
      );
      if (participant2Type === 'guest') {
        participant2 = consultation.participants?.find(
          (participant: ConsultationParticipant) => (

            (participant.status === 'new' ||
              participant.status === 'joined' ||
              participant.status === 'finished') &&
            participant.type === 'guest'
          )
        );
      } else {
        participant2 = consultation.participants?.find(
          (item: ConsultationParticipant) => item.type === participant2Type,

        );
      }
    }
  }

  return {
    me,
    participant1,
    participant2
  };
};
