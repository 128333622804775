import React from 'react';
import { Participant } from 'twilio-video';

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../../icons/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';

import { Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import useIsTrackSwitchedOff from '../../../plugins/video_call/hooks/useIsTrackSwitchedOff';
import usePublications from '../../../plugins/video_call/hooks/usePublications';
import useTrack from '../../../plugins/video_call/hooks/useTrack';
import useParticipantIsReconnecting from '../../../plugins/video_call/hooks/useParticipantIsReconnecting';
import useParticipantDisplayInfo from '../../../plugins/video_call/hooks/useParticipantDisplayInfo';

const useStyles = () => {
  const theme = {
    participantBorderWidth: 1,
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      up: () => 50,
      down: () => 50,
      between: () => 50,
      only: () => 50,
      width: () => 50,
    },
    sidebarMobileHeight: 90,
  };
  return {
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 0,
      overflow: 'hidden',
      marginBottom: '2em',
      '& video': {
        filter: 'none',
        objectFit: 'contain !important',
      },
      borderRadius: '4px',
      border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      paddingTop: `calc(${(9 / 16) * 100}% - ${
        theme.participantBorderWidth
      }px)`,
      background: 'black',
    },
    selectedContainer: {
      border: `${theme.participantBorderWidth}px solid rgb(255, 0, 0)`,
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    networkQualityContainer: {
      width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    typeography: {
      color: 'white',
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  };
};

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  textInfo?: 'none' | 'minimum' | 'extended';
}

function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  textInfo,
}: ParticipantInfoProps) {
  const { t } = useTranslation();
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => p.kind === 'video');

  const videoTrack = useTrack(videoPublication);

  const isVideoEnabled = Boolean(videoTrack) && videoTrack.isEnabled;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);

  const audioTrack = useTrack(audioPublication);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const { name, subtitle } = useParticipantDisplayInfo(participant);

  const classes = useStyles();
  return (
    <div
      style={{
        ...classes.container,
        ...(hideParticipant ? classes.hideParticipant : {}),
        ...(Boolean(onClick) === true ? classes.cursorPointer : {}),
        ...(isSelected ? classes.selectedContainer : {}),
      }}
      onClick={onClick}
      data-cy-participant={participant.identity}>
      <div className={'participant_info-info_container'}>
        <div style={classes.networkQualityContainer}>
          <NetworkQualityLevel participant={participant} />
        </div>
        <div style={classes.infoRowBottom}>
          <span style={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            {(textInfo === 'minimum' || textInfo === 'extended') && (
              <div>
                <Typography.Text style={classes.typeography}>
                  {name}
                  {isLocalParticipant &&
                    ` ${t('(You)', {
                      context: 'Indicating that is you during a video call',
                    })}`}
                </Typography.Text>
                {textInfo === 'extended' && (
                  <>
                    <br />
                    <Typography.Text style={{ color: 'inherit' }}>
                      {subtitle}
                    </Typography.Text>
                  </>
                )}
              </div>
            )}
          </span>
        </div>
      </div>
      <div style={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div style={classes.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
        {isParticipantReconnecting && (
          <div style={classes.reconnectingContainer}>
            <Typography.Text style={classes.typeography}>
              {t('Reconnecting…')}
            </Typography.Text>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

ParticipantInfo.defaultProps = {
  textInfo: 'extended',
};

export default ParticipantInfo;
