export default class Authentication {
  constructor (options) {
    this.token = null
  }
  setAuthToken (token) {
    this.token = token;
  }
  getAuthToken () {
    return this.token;
  }
}
