// @flow
import * as React from 'react';
import axios from 'axios';
import { Avatar } from 'antd';
import { API_BASE_URL } from '../../constants';
import { getAuthorizationHeaders } from '../../api/lib/defaults';

type Props = Object & {
  className?: string,
  imgUrl: {
    uri: string
  },
};

const AvatarWithAuth = (props: Props): React$Element<"img"> => {
  const { className, imgUrl, ...restProps } = props;
  const { uri } = imgUrl || {};
  const [imgSrc, setImgSrc] = React.useState(null);

  const getImageSource = (source) => {
    return axios
      .get(source?.uri, {
        headers: getAuthorizationHeaders(),
        responseType: 'arraybuffer'
      })
      .then(response => {
        const tmpSrc = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
        setImgSrc(tmpSrc);
      });
  };

  React.useEffect(() => {
    if (uri?.startsWith(API_BASE_URL)) {
      getImageSource({ uri });
    } else {
      setImgSrc(uri);
    }
  }, [uri]);

  return <Avatar className={props.className} src={imgSrc} {...restProps} />
}

export default AvatarWithAuth;
