import React, { useState } from 'react';
import { ConfigProvider } from 'antd';
import { useLanguage } from './hooks/useLanguage';
import { Router } from 'react-router-dom';
import Root from './screens/Root';
import { I18nextProvider } from 'react-i18next';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { createBrowserHistory } from 'history';
import { VideoProvider } from './plugins/video_call/VideoProvider';
import VideoCallErrorHandler from './components/video_call/VideoCallErrorHandler/VideoCallErrorHandler';
// import logo from './logo.svg';
import 'react-image-lightbox/style.css';
import './App.css';

import i18n from './locales';

export const history = createBrowserHistory();

function App() {
  const [videoCallError, setVideoCallError] = useState(null);
  const { locale } = useLanguage();

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider locale={locale}>
        <div className="App">
          <Router history={history}>
            <Auth0ProviderWithHistory>
              <VideoProvider onError={setVideoCallError}>
                <VideoCallErrorHandler onDismiss={() => setVideoCallError(null)} error={videoCallError} />
                <Root />
              </VideoProvider>
            </Auth0ProviderWithHistory>
          </Router>
        </div>
      </ConfigProvider>
    </I18nextProvider>
  );
}

export default App;
