/**
 * Styleguide colors to be used when you need to use inline styles
 * and cannot use variables from *main.less*.
 */
export const colors = {
  brand: '#C21C1D',
  brand_dark: '#881516',
  secondary: '#E9573D',
  brand_10: 'rgba(197,40,28,0.1)',
  gray: '#484848',
  light_gray: '#818D99',
  steam_gray: '#ddd',
  white: '#fff',
  black: '#000',
  black_20: 'rgba(0,0,0,0.2)',
  kashmir_blue: '#537599',
  backgroundColor: '#f5f5f5',
  purpleText: '#8E1230',
  error_40: 'rgba(255,0,0,0.4)',
  warning: '#ffe59e',
  dark_green: '#004725',
  lime: '#02E378',
  call_green: '#1EC760',
};
