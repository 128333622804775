import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDataLoader } from '../../hooks/useDataLoader';
import { useTranslation } from 'react-i18next';

// Components imports
import { List, Typography } from 'antd';
import AvatarWithAuth from '../AvatarWithAuth';

// Misc imports
import {
  getUserNotifications,
  getAllUserNotifications,
  markAllUserNotificationsAsRead,
  markNotificationAsReadLocally } from '../../store/slices/notificationsSlice';
import { NAV_VISITS, NAV_APPOINTMENTS } from '../../constants';
import { unwrapResult } from '@reduxjs/toolkit';

const { Text, Title } = Typography;
const baseClassName = 'notifications';

const NotificationsList = (props) => {
  const { onItemPress } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const list = useSelector(getAllUserNotifications);

  const [notificationsRequest, setNotificationsRequest] = useState([]);

  const apiCaller = useCallback(
    (params: Object) => {
      return dispatch(
        getUserNotifications({
          ...params
        })
      );
    },
    [dispatch]
  );

  const {
    page,
    isLoading,
    canRenderContent,
    reloadData,
    renderLoadMoreFooter,
    renderRefreshControl,
    renderEmptySpace
  }: DataLoaderHookProps = useDataLoader(apiCaller, null, {
    disableAutoLoad: false
  });

  const _onItemPress = (React.useCallback(
    (item: UserNotification) => {
      onItemPress && onItemPress(item);

      let payload: ?UserNotificationPayloadObject;
      if (typeof item.payload === 'object' && item.payload != null) {
        payload = item.payload;
      }

      if (item.id) {
        dispatch(markNotificationAsReadLocally(item.id));
      }

      if (payload?.type?.startsWith('appointments.')) {
        history.push(`${NAV_APPOINTMENTS}/${payload?.appointment_id}`);
      } else if (payload?.type?.startsWith('consultations.')) {
        history.push(`${NAV_VISITS}/${payload?.consultation_id}`);
      }
    },
    [history, dispatch, onItemPress]
  ));

  const getIconUri = (item) => {
    return (typeof item?.payload === 'object' && item?.payload?.icon != null
      ? { uri: item.payload.icon }
      : null)
  }

  // ======================================================
  // USE-EFFECTS
  // ======================================================
  useEffect(() => {
    async function markAsRead() {
      try {
        await dispatch(markAllUserNotificationsAsRead()).then(unwrapResult);
      } catch (e) {
        console.log('failed to mark notifications as read', e);
      }
    }

    if (canRenderContent && !isLoading && page === 1) {
      markAsRead();
    }
  }, [canRenderContent, page, isLoading, dispatch]);

  return (
    <div className={baseClassName}>
      {
        canRenderContent
          ? (
            <>
              <List
                itemLayout="horizontal"
                dataSource={list}
                rowKey={item => `${item.id}`}
                renderItem={item => (
                  <List.Item
                    className={`${!item.read ? 'not-read': ''} ${baseClassName}__item`}
                    data-item={JSON.stringify(item)}
                    onClick={(e) => {
                      e.preventDefault();
                      _onItemPress(item);
                    }}>
                    <AvatarWithAuth className="as-fs" imgUrl={getIconUri(item)} size="large"/>
                    <div className={`${baseClassName}__item-body`}>
                      {
                        item?.title
                          ? <Title level={5}>{item.title}</Title>
                          :null
                      }
                      {
                        item?.subtitle
                          ? <Text>{item?.subtitle}</Text>
                          : null
                      }
                      <Text className="time">{moment(item?.created_at).fromNow()}</Text>
                    </div>
                  </List.Item>
                )}
              />
              {
                list.length > 0 ? (
                  <div className="d-flex jc-c">
                    {renderLoadMoreFooter()}
                  </div>
                ) : null
              }
            </>
          )
          : <Text>{t('There are no notifications at the moment.')}</Text>
      }

    </div>

  )
}

export default NotificationsList;
