import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDataLoader } from '../../hooks/useDataLoader';
import { useQuery } from '../../hooks/useQuery';
import type { DataLoaderHookProps } from '../../hooks/useDataLoader';

// Components imports
import { Row, Col, PageHeader, Typography, List, Input } from 'antd';
import InfoCard from '../../components/InfoCard';
import NavDatePicker from '../../components/NavDatePicker';
import AppointmentTime from '../../components/AppointmentTime';
import { SearchOutlined } from '@ant-design/icons';

//Misc imports
import { clearDoctorsList, listDoctors } from '../../store/slices/doctorsSlice';
import { getQueryString } from '../../api/lib/utils';
import { removeKeysWithUndefinedValue, searchableString } from '../../utils';
import { debounce } from '../../utils/debounce';
import { NAV_APPOINTMENTS_DOCTORS_SINGLE } from '../../constants';
import moment from 'moment';

// Const declarations
const { Text } = Typography;
const baseClassName = 'appointments-doctors-list';

const AppointmentsDoctorsListScreen = (props) => {
  const query = useQuery();
  const defaultDate = moment().format('YYYY-MM-DD');
  const spId = query.get('spId');
  const date = query.get('date');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchedSpecialities, setSearchedSpecialities] = useState();
  const [defaultOpen, setSetDefaultOpen] = useState(true);
  const { list, specialities } = useSelector((state) => state.doctors);

  const apiCaller = useCallback(
    (params: Object) => {
      let appointment_date;
      if (Boolean(date) === true) {
        // when the date is set, use that...
        appointment_date = moment(date).format('YYYY-MM-DD');
      } else if (defaultOpen) {
        // when the date is NOT set but the user just landed on the screen,
        // use default date (today)
        appointment_date = defaultDate;
      }
      return dispatch(
        listDoctors(
          removeKeysWithUndefinedValue({
            ...params,
            speciality: Boolean(spId) === true ? spId : undefined,
            appointment_date,
            next_appointment: true,
            accept_appointments_online: true,
          })
        )
      );
    },
    [dispatch, spId, date, defaultDate, defaultOpen]
  );

  const {
    isLoading,
    canRenderContent,
    renderLoadMoreFooter,
    renderEmptySpace
  }: DataLoaderHookProps = useDataLoader(apiCaller);

  const onSelectSpeciality = (item) => (e) => {
    e.preventDefault();
    if (`${item.id}` !== spId) {
      dispatch(clearDoctorsList());
      const newSearchStr = getQueryString(removeKeysWithUndefinedValue({
        spId: item.id,
        date: date ?? undefined
      }));
      history.replace({ search: newSearchStr });
    }
  };

  const onSelectDate = (dateObj, dateString) => {
    console.log(dateObj, date, dateString);
    if (dateString !== date) {
      console.log('will clear list')
      dispatch(clearDoctorsList());
      const newSearchStr = getQueryString(removeKeysWithUndefinedValue({
        spId: spId ?? undefined,
        date: dateObj != null ? dateObj : undefined,
      }));
      console.log('new search', newSearchStr);
      history.replace({ search: newSearchStr });
      setSetDefaultOpen(false);
    }
  };

  const onSelectSlot = (item) => (slot) => {
    const query = getQueryString({
      start: slot.next_appointment ? moment(slot.start).format('YYYY-MM-DD') : slot.start,
    });
    history.push(NAV_APPOINTMENTS_DOCTORS_SINGLE.replace(':id', item.id) + query);
  };

  const searchSpecialities = debounce((e) => {
    const text = e.target.value;
    console.log(text, text.length)
    if (text.length > 2) {
      setSearchedSpecialities(specialities.filter((sp) =>
        searchableString(sp.name).includes(searchableString(text))
      ));
    } else {
      setSearchedSpecialities(null);
    }
  }, 500);

  return (
    <div className={`${baseClassName} container`}>
      <Row align="center">
        <Col lg={20} md={24}>
          <Row>
            <Col>
              <div className={`${baseClassName}__header-title`}>
                <PageHeader title={t('Appointments')} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Input
                allowClear
                placeholder={t('Type a speciality')}
                prefix={<SearchOutlined />}
                onChange={searchSpecialities}
              />
            </Col>
            <Col lg={18} className={`${baseClassName}__date-picker-col`}>
              <NavDatePicker
                minDate={moment().format('YYYY-MM-DD')}
                maxDate={moment().add(3, 'months').format('YYYY-MM-DD')}
                defaultOpen={defaultOpen}
                defaultValue={moment(defaultDate, 'YYYY-MM-DD')}
                value={date != null ? moment(date, 'YYYY-MM-DD') : undefined}
                onChange={onSelectDate}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className={`${baseClassName}__specialities-col`}>
              <List
                header={<div style={{ fontWeight: 'bold' }}>{t('All specialities')}</div>}
                dataSource={searchedSpecialities || specialities}
                renderItem={item => {
                  return (
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      className={[
                        spId === `${item.id}` ? `${baseClassName}__selected-sp` : '',
                      ].join(' ')}
                      onClick={onSelectSpeciality(item)}>
                      <Text>
                        {item.name}
                      </Text>
                    </List.Item>
                  )
                }}
              />
            </Col>
            <Col lg={18}>
              {
                canRenderContent
                  ? (<>
                    {
                      list.length === 0 && isLoading ? (
                        renderEmptySpace()
                      ) : (
                        <List
                          itemLayout="horizontal"
                          dataSource={list}
                          renderItem={item => {
                            return (
                              <List.Item
                                data-item={JSON.stringify(item)}
                                onClick={(e) => {
                                  e.preventDefault();
                                }}>
                                <InfoCard
                                  headerOnly
                                  item={item}
                                  linkTo={NAV_APPOINTMENTS_DOCTORS_SINGLE.replace(':id', item.id)}
                                >
                                  <div className={`${baseClassName}__slots-container`}>
                                    {item?.slots?.map((slot) => (
                                      <AppointmentTime
                                        key={slot.start}
                                        item={slot}
                                        onClick={onSelectSlot(item)}
                                      />
                                    ))}
                                  </div>
                                </InfoCard>
                              </List.Item>
                            )
                          }}
                        />
                      )
                    }
                    {
                      list.length > 0 ? (
                        <div className="d-flex jc-c">
                          {renderLoadMoreFooter()}
                        </div>
                      ) : null
                    }
                  </>)
                  : null
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default AppointmentsDoctorsListScreen;
