import React from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import { Modal, Typography } from 'antd';
import VideoInputList from './VideoInputList/VideoInputList';

const useStyles = () => {
  return {
    container: {
      width: '600px',
      minHeight: '400px',
    },
    button: {
      float: 'right',
    },
    headline: {
      marginBottom: '1.3em',
      fontSize: '1.1rem',
    },
    listSection: {
      marginBottom: '12px',
    },
  };
};

export default function DeviceSelectionDialog({
  open,
  onClose,
}: {
  open: boolean,
  onClose: () => void,
}) {
  const classes = useStyles();

  return (
    <Modal
      visible={open}
      onClose={onClose}
      title={'Audio and Video Settings'}
      onCancel={onClose}
      onOk={onClose}>
      <div style={classes.container}>
        <div style={classes.listSection}>
          <Typography.Title style={classes.headline}>
            Video
          </Typography.Title>
          <VideoInputList />
        </div>
        {/* <Divider /> */}
        <div style={classes.listSection}>
          <Typography.Title style={classes.headline}>
            Audio
          </Typography.Title>
          <AudioInputList />
        </div>
        <div style={classes.listSection}>
          <AudioOutputList />
        </div>
      </div>
    </Modal>
  );
}
