// @flow
import * as React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import type { File } from '../config/types';

/**
 * Manages the state for files' upload and exposes handler method and a utility
 * that process the current state and warns the user for incomplete/failed uploads.
 *
 * Pass `onFilesUpdated` to a <FilesManager onFilesUpdated={onFilesUpdated} /> component.
 *
 * Use `continueWithFilesUploadCaution` to check whether or not the files
 * are uploaded successfully before contintue with saving a resource that
 * includes files.
 *
 * @returns {{ files, filesUploadInProgress, filesUploadFailure, onFilesUpdated, continueWithFilesUploadCaution }}
 */
export const useFilesManagerHandler = (
  existingFiles?: Array<File>,
): ({
  files: Array<number | string>,
  filesUploadInProgress: boolean,
  filesUploadFailure: boolean,
  onFilesUpdated: (updatedFiles: Array<File>) => void,
  continueWithFilesUploadCaution: () => Promise<any>,
}) => {
  const theFiles =
    existingFiles != null
      ? existingFiles.map < number | string > ((tmp: File) => tmp.id)
      : [];

  const [files, setFiles] = React.useState(theFiles);
  const [filesUploadInProgress, setFilesUploadInProgress] = React.useState(
    false,
  );
  const [filesUploadFailure, setFilesUploadFailure] = React.useState(false);
  const { t } = useTranslation();

  const onFilesUpdated = React.useCallback((updatedFiles: Array<File>) => {
    const fileIDs = [];
    let uploading = false;
    let failed = false;
    updatedFiles.forEach((tmp: File) => {
      if (typeof tmp.id === 'number' && tmp.status == null) {
        fileIDs.push(tmp.id);
      } else if (tmp.status === 'uploading') {
        uploading = true;
      } else if (tmp.status === 'failed') {
        failed = true;
      }
    });

    setFiles(fileIDs);
    setFilesUploadInProgress(uploading);
    setFilesUploadFailure(failed);
  }, []);

  const continueWithFilesUploadCaution = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      if (filesUploadInProgress || filesUploadFailure) {
        let msg = t(
          'Some files are still uploaded. Do you want to continue anyway?',
        );
        if (filesUploadFailure) {
          msg = t(
            'Some files could not be uploaded. Do you want to continue anyway?',
          );
        }

        Modal.confirm({
          title: t('Message'),
          content: msg,
          cancelText: t('Cancel'),
          onCancel: () => reject(new Error('cancelled')),
          okText: t('Continue'),
          onOk: resolve,
        });
      } else {
        resolve();
      }
    });
  }, [filesUploadInProgress, filesUploadFailure, t]);

  return {
    files,
    filesUploadInProgress,
    filesUploadFailure,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  };
};
