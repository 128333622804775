// @flow
import * as CONSTANTS from './constants';
import { translate } from '../../locales';

export const getApiErrorMessage = (
  errorCode?: string | number,
  fallbackDescription?: string,
) => {
  switch (errorCode) {
    case CONSTANTS.NETWORK_OFFLINE_CODE:
      return translate('No internet connection.');
    case 2000:
      return translate('Internal server error');
    case 2001:
      return translate('Invalid request parameters');

    case 2400:
      return translate('Not found');

    // Validation errors
    case 3000:
      return translate('Malformed user input');
    case 3001:
      return translate('Malformed path parameters');

    // User errors
    case 4000:
      return translate('User is not authorized to access this resource');
    case 4001:
      return translate('User is not a GP doctor');
    case 4002:
      return translate('User is not a patient');
    case 4003:
      return translate('User access_token is not valid or not provided');
    case 4004:
      return translate('Too many requests');
    case 4005:
      return translate('User is not a guest doctor');
    case 4006:
      return translate('Doctor exists in DB as a patient');
    case 4007:
      return translate('Patient exists in DB as a doctor');

    // Consultation error
    case 5000:
      return translate('Reached maximum number of participants');
    case 5001:
      return translate('Failed to retrieve access_token for participant');
    case 5002:
      return translate(
        'Participant access_token failed because of inconsistent user and instance configurations',
      );
    case 5003:
      return translate('Unable to create room');
    case 5004:
      return translate(
        'No doctor available at this time. Please call again in a few minutes',
      );
    case 5005:
      return translate('Doctor is not available');

    // Notifications error
    case 6000:
      return translate('Unable to register new device binding');
    case 6001:
      return translate('Failed to unregister device binding');
    case 6002:
      return translate('Unable to match a twilio notify service');

    // Appointment error
    case 7000:
      return translate('Appointments overlapping');
    case 7001:
      return translate('Date is in past');
    case 7002:
      return translate('Start date is after finish date');
    case 7003:
      return translate('Doctor does not accept this type of appointment');
    case 7004:
      return translate('Doctor does not accept appointments');
    case 7005:
      return translate('Slot is not available');
    case 7006:
      return translate(
        'Invalid period to request consultation for appointment',
      );
    case 7007:
      return translate('Appointment is not confirmed');
    case 7008:
      return translate('Minimum appointment notice time exceeded');
    default:
      return (
        fallbackDescription ||
        translate('A network error occurred. Please try again.')
      );
  }
};
