// @flow
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Components import
import {
  Popconfirm,
  Spin,
  Button,
  Input,
  PageHeader,
  Row,
  Col,
  Form
} from 'antd';
import QuickReplies from '../../components/QuickReplies';
import SymptomsCheckerMessage from '../../components/SymptomsCheckerMessage';
import { RedoOutlined, ArrowUpOutlined } from '@ant-design/icons';

// Misc imports
import { getDiagnosisWithFallback } from '../../api/symptomsChecker';
import { isNoInternetConnectionError } from '../../api/lib/utils';
import { useSymptomsCheckerActions } from './useSymptomsCheckerActions';
import { useInputState } from '../../hooks/useInputState';
import { wait, safeParseInt } from '../../utils';
import type {
  SymptomCheckerDiagnosisData,
  SCMessage
} from '../../config/types';
import { openCallInitializationModal } from '../../store/slices/applicationSlice';
import message from '../../utils/message';

// Const imports
const LoadingView = (props) => <Spin size={'small'} />;
// eslint-disable-next-line react/display-name
const getSendButton = (onClick: any, disabled: boolean) => (
  <Button
    icon={<ArrowUpOutlined />}
    shape="circle"
    disabled={disabled}
    onClick={onClick}
    type="primary"
    size="small"
  />
);

const RestartIcon = <RedoOutlined />;

const baseClassName = 'symptoms-checker';

const SymptomsCheckerScreen = (): React$Element<any> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const inputState = useInputState();
  const initialChatMessages: Array<SCMessage> = useMemo(
    () => [
      {
        id: '1',
        message: t('Hello! I will help you do an automated symptom check.'),
        sender: 'other'
      },
      {
        id: '2',
        message: t(
          'This service is for informational purposes only and is not a medical opinion.'
        ),
        sender: 'other'
      },
      {
        id: '3',
        message: t(
          'In an emergency, call the local emergency number immediately.'
        ),
        sender: 'other'
      },
      {
        id: '4',
        message: t('What is your age?'),
        sender: 'other'
      }
    ],
    [t]
  );

  const scrollViewRef = useRef(null);
  const inputRef = useRef(null);
  const symptoms_checker_reducer = useSelector(
    (state) => state.symptoms_checker
  );
  const {
    introInitiated,
    state,
    sex,
    age,
    isTyping,
    symptoms,
    existingEvidences,
    quickReplies,
    quickReplyMeta,
    chatMessages,
    diagnosisDataToRetry
  } = symptoms_checker_reducer;
  const {
    setIntroInitiated,
    setState,
    setSex,
    setAge,
    setIsTyping,
    setSymptoms,
    setExistingEvidences,
    setQuickReplies,
    setQuickReplyMeta,
    setDiagnosisDataToRetry,
    appendChatMessage,
    resetChecker
  } = useSymptomsCheckerActions();

  const handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      onSendPress();
      // inputRef.current.value
    }
  }

  // ======================================================
  // USE-CALLBACKS
  // ======================================================
  const appendMessage = useCallback(
    (msg: SCMessage) => {
      appendChatMessage(msg);
      setTimeout(() => {
        let objDiv = document.getElementsByClassName(`${baseClassName}__main-container`)[0];
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        // scrollViewRef.current?.scrollIntoView({block: 'end'});
      }, 200);
    },
    [appendChatMessage]
  );

  const sendIntroMessages = useCallback(() => {
    initialChatMessages.forEach((msg, index) => {
      const delay = index * 1200;
      setTimeout(async () => {
        setIsTyping(true);
        await wait(1000);
        appendMessage(msg);
        setIsTyping(false);
        await wait(200);
        if (index === initialChatMessages.length - 1) {
          setState('ask_age');
          inputRef.current?.focus();
        }
      }, delay);
    });
  }, [initialChatMessages, appendMessage, setState, setIsTyping]);

  const askForSymptoms = useCallback(() => {
    setState('ask_symptom');
    setTimeout(() => {
      inputRef.current?.focus();
      setTimeout(() => {
        appendMessage({
          id: `${Date.now()}-ask_symptom`,
          message: t(
            'Now tell me, what do you care most about your health? Describe your symptoms.'
          ),
          sender: 'other'
        });
      }, 500);
    }, 100);
  }, [appendMessage, setState, t]);

  const askForAge = useCallback(() => {
    setState('ask_age');
    appendMessage({
      id: `${Date.now()}-ask_age`,
      message: t('What is your age?'),
      sender: 'other'
    });
  }, [appendMessage, setState, t]);

  const askForSex = useCallback(() => {
    setState('ask_sex');
    appendMessage({
      id: `${Date.now()}-ask_sex`,
      message: t('What is your gender?'),
      sender: 'other'
    });
  }, [appendMessage, setState, t]);

  const sendDiagnosisData = useCallback(
    async (data) => {
      setIsLoading(true);
      let response;
      try {
        // make sure that the age will be sent in the proper format
        // for infermedica's API v3
        const ageObj =
          typeof data.age === 'number'
            ? {
              value: data.age,
              unit: 'year'
            }
            : data.age;

        const dataToSend = { ...data, age: ageObj };
        response = await getDiagnosisWithFallback(dataToSend);
        setExistingEvidences(response?.requestedEvidence);
        if (response.should_stop) {
          setState('finished');
          appendMessage({
            id: `${Date.now()}_verdict`,
            message: t(
              'Here are some possible explanations we\'ve come up with for these symptoms'
            ),
            sender: 'other'
          });
          response.conditions.forEach((cond) => {
            setTimeout(() => {
              appendMessage({
                id: cond.id,
                condition: cond,
                sender: 'other'
              });
            });
          });
        } else if (response.question?.text == null) {
          appendMessage({
            id: `${Date.now()}_do not understand`,
            message: t('I am sorry but I don\'t understand what you said'),
            sender: 'other'
          });
        } else {
          setState('interview');
          appendMessage({
            id: `${Date.now()}`,
            message: response.question.text,
            sender: 'other'
          });

          const item = response.question.items?.[0];
          if (item) {
            setQuickReplies(item.choices);
            setQuickReplyMeta({
              id: item.id,
              name: item.name
            });
          }
        }
      } catch (error) {
        if (isNoInternetConnectionError(error)) {
          setDiagnosisDataToRetry(data);
        } else {
          setState('finished');
        }
        message.error(error.message);
      }
      setIsLoading(false);
      return Promise.resolve(response);
    },
    [
      appendMessage,
      t,
      setIsLoading,
      setExistingEvidences,
      setState,
      setQuickReplies,
      setQuickReplyMeta,
      setDiagnosisDataToRetry
    ]
  );

  const onSelectChoice = useCallback(
    (choice) => {
      if (age != null && sex != null) {
        const data: SymptomCheckerDiagnosisData = {
          age,
          sex,
          evidence: [
            ...existingEvidences /* add existing evidences from previous questions */,
            {
              id: quickReplyMeta?.id || '',
              choice_id: choice.id
            }
          ]
        };
        appendMessage({
          id: `${Date.now()}-${choice.id}`,
          message: choice.label,
          sender: 'me'
        });
        setQuickReplies([]);
        setQuickReplyMeta(null);
        sendDiagnosisData(data);
      }
    },
    [
      age,
      existingEvidences,
      sex,
      quickReplyMeta,
      appendMessage,
      sendDiagnosisData,
      setQuickReplies,
      setQuickReplyMeta
    ]
  );

  const onSelectSex = useCallback(
    (choice) => {
      // $FlowFixMe
      const sexSelection: 'male' | 'female' = choice.id;
      setSex(sexSelection);
      setQuickReplies([]);
      setQuickReplyMeta(null);
      appendMessage({
        id: `${Date.now()}-ask_sex_selection`,
        message: choice.label,
        sender: 'me'
      });

      if (age == null) {
        askForAge();
      } else if (symptoms == null) {
        askForSymptoms();
      } else {
        setState('interview');
        const data: SymptomCheckerDiagnosisData = {
          age,
          sex: sexSelection,
          text: symptoms,
          evidence: []
        };
        sendDiagnosisData(data);
      }
    },
    [
      age,
      symptoms,
      askForAge,
      askForSymptoms,
      appendMessage,
      sendDiagnosisData,
      setSex,
      setQuickReplies,
      setQuickReplyMeta,
      setState
    ]
  );

  const onSendPress = useCallback(async () => {
    const text = inputState.value;
    inputState.onChange('');
    appendMessage({
      id: `${Date.now()}-input`,
      message: text,
      sender: 'me'
    });
    if (state === 'ask_symptom') {
      if (age == null) {
        setSymptoms(text);
        askForAge();
      } else if (sex == null) {
        setSymptoms(text);
        askForSex();
      } else {
        if (text.trim() === '') {
          appendMessage({
            id: `${Date.now()}_empty`,
            message: t('Please describe your symptoms.'),
            sender: 'other'
          });
        } else {
          const msg = { age, sex, text, evidence: [] };
          setSymptoms('');
          sendDiagnosisData(msg);
        }
      }
    } else if (state === 'ask_age') {
      const theAge = safeParseInt(inputState.value);
      //Check if the age exist and the user is older then 12 years old//
      if (theAge && theAge >= 12 && theAge <= 130) {
        setAge(theAge);
        if (sex == null) {
          askForSex();
        } else {
          const msg = {
            age: theAge,
            sex,
            text: symptoms,
            evidence: []
          };
          appendMessage({
            id: `${Date.now()}`,
            message: inputState.value,
            sender: 'me'
          });
          inputState.onChange('');
          sendDiagnosisData(msg);
        }
      } else {
        appendMessage({
          id: `${Date.now()}`,
          message: t('Please enter an age between 12 and 130 years'),
          sender: 'other'
        });
      }
    }
  }, [
    age,
    sex,
    symptoms,
    inputState,
    state,
    appendMessage,
    sendDiagnosisData,
    askForAge,
    askForSex,
    t,
    setSymptoms,
    setAge
  ]);

  // ======================================================
  // USE-LAYOUT-EFFECTS
  // ======================================================
  useLayoutEffect(() => {
    let headerHeight = document.getElementsByClassName('header-bar__wrapper')[0].offsetHeight;
    let pageHeaderHeight = document.getElementsByClassName(`${baseClassName}__header-title`)[0].offsetHeight;
    let inputBarHeight = document.getElementsByClassName(`${baseClassName}__input-bar-container`)[0]?.offsetHeight;
    let footerHeight = document.getElementsByClassName('footer-container')[0]?.offsetHeight;

    let sumHeight = headerHeight + pageHeaderHeight + inputBarHeight + footerHeight;

    if (sumHeight < 800) {
      sumHeight -= 120;
    }

    document.documentElement?.style.setProperty('--sumSCHeight', `${sumHeight}px`);
  }, []);

  // ======================================================
  // USE-EFFECTS
  // ======================================================
  useEffect(() => {
    // scroll to the most recent message
    setTimeout(() => {
      let objDiv = document.getElementsByClassName(`${baseClassName}__main-container`)[0];
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
      // scrollViewRef.current?.scrollIntoView({block: 'end'});
    }, 200);
  }, []);

  useEffect(() => {
    if (state === 'intro' && chatMessages.length === 0 && !introInitiated) {
      setIntroInitiated(true);
      sendIntroMessages();
    }
  }, [
    chatMessages,
    introInitiated,
    state,
    setIntroInitiated,
    sendIntroMessages
  ]);

  const inputDisabled = !['ask_age', 'ask_symptom'].includes(state);
  return (
    <div className={`${baseClassName} container`}>
      <Row>
        <Col>
          <div className={`${baseClassName}__header-title`}>
            <PageHeader
              title={t('Symptoms checker')}
              subTitle={t('Powered by Infermedica')}
              extra={[
                <Popconfirm
                  key="1"
                  title={t(
                    'Are you sure you want to clear all messages and start a new check?'
                  )}
                  onConfirm={() => resetChecker()}
                  onCancel={() => console.log('Cancel Pressed')}
                  okText={t('Yes')}
                  cancelText={t('No')}
                >
                  <Button
                    icon={RestartIcon}
                    type="link"
                  >
                    {t('Reset')}
                  </Button>
                </Popconfirm>
              ]}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={`${baseClassName}__main-container`}>
            {chatMessages.map((msg) => (
              <SymptomsCheckerMessage key={msg.id} message={msg} />
            ))}
            {isTyping ? (
              <SymptomsCheckerMessage
                message={{
                  id: 'typing-indication',
                  message: '...',
                  sender: 'other'
                }}
              />
            ) : null}
            {state === 'ask_sex' ? (
              <QuickReplies
                choices={[
                  { id: 'male', label: t('Man') },
                  { id: 'female', label: t('Woman') }
                ]}
                onSelectChoice={onSelectSex}
              />
            ) : (
              <QuickReplies
                choices={quickReplies}
                meta={quickReplyMeta}
                onSelectChoice={onSelectChoice}
              />
            )}
            {diagnosisDataToRetry != null ? (
              <Button
                className={`${baseClassName}__action-btn`}
                onClick={() => {
                  const data = { ...diagnosisDataToRetry };
                  setDiagnosisDataToRetry(null);
                  sendDiagnosisData(data);
                }}>
                {t('Retry')}
              </Button>
            ) : null}
            {state === 'finished' ? (
              <Button
                className={`${baseClassName}__action-btn`}
                onClick={() => dispatch(openCallInitializationModal())}>
                {t('Call a doctor')}
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            ref={scrollViewRef}
            className={`${baseClassName}__input-bar-container`}
          >
            {state === 'finished' ? (
              <Button
                className={`${baseClassName}__restart-btn`}
                appearance={'outline'}
                onClick={() => resetChecker()}>
                {t('Start new check')}
              </Button>
            ) : (
              <Form onKeyUp={handleKeyUp}>
                <Input
                  ref={inputRef}
                  disabled={inputDisabled}
                  type={state === 'ask_age' ? 'number' : 'text'}
                  suffix={isLoading
                    ? LoadingView
                    : getSendButton(onSendPress, inputDisabled)}
                  placeholder={
                    state === 'ask_symptom'
                      ? t('Describe your symptoms')
                      : state === 'ask_age'
                        ? t('Type your age')
                        : ''
                  }
                  {...inputState}
                />
              </Form>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SymptomsCheckerScreen;
