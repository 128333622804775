// @flow
import * as React from 'react';

// Components imports
import { Button } from 'antd';

// const declarations
const baseClassName = 'call-to-action';

type Props = Object & {
  icon?: React.Node,
  className?: string,
  title: string,
  onBtnClick: Function
}
const CallToAction = (props: Props): React$Element<any> => {
  const { icon, title, onBtnClick, className, ...rest } = props;

  return (
    <Button
      className={`${baseClassName} ${className}`}
      icon={icon}
      onClick={onBtnClick}
      {...rest}
    >
      {title}
    </Button>
  )
}

export default CallToAction;
