// @flow
import * as React from 'react';
import { Button, Spin } from 'antd';
import { getDefaultPageSize } from '../../api/lib/defaults';

type Props = {
  totalDataEntries: number,
  pageSize?: number,
  selectedPage: number,
  isLoading?: boolean,
  /**
   * When this is `true` and the number of pages is `1`, the component will return `null`.
   */
  hideOnSinglePage?: boolean,
  style?: any,
  onSelectPage?: (page: number) => any,
};

const Paginator = (props: Props) => {
  const pageSize =
    props.pageSize == null || props.pageSize <= 0
      ? getDefaultPageSize()
      : props.pageSize;

  let totalPages = 1;
  if (props.totalDataEntries != null || props.totalDataEntries === 0) {
    totalPages = Math.ceil(props.totalDataEntries / pageSize);
  }

  if ((props.hideOnSinglePage && totalPages === 1) || totalPages < 1) {
    return null;
  }

  // FIXME: make it more efficient
  const buttons = [];
  for (let i = 0; i < totalPages; i++) {
    buttons.push(i + 1);
  }

  return (
    <div style={{ ...styles.container, ...props.style }}>
      {
        buttons.map((i) => {
          const isLoading = props.isLoading && props.selectedPage === i;
          return (
            <Button
              key={`key${i}`}
              style={styles.navButton}
              size={'small'}
              loading={isLoading}

              onClick={() => props.onSelectPage?.(i)}>
              {isLoading ? '' : i}
            </Button>
          )
        })
      }
    </div>
  );
};

const styles = {
  container: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px',
    alignSelf: 'center',
  },
  navButton: {
    width: '40px',
    height: '40px',
    marginRight: '8px',
    paddingHorizontal: '0px',
    marginBottom: '8px',
  },
};

export default Paginator;
