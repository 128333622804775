import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components import
import { Layout, Row, Col, Typography, Button, Modal } from 'antd';
import GLogo from '../../assets/images/logos/generali-red-logo-scaled.png';
import { SocialIcon } from 'react-social-icons';

// Misc imports
import {
  TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
  FACEBOOK_URL,
  YOUTUBE_URL,
  CONTACT_US_PHONE,
} from '../../constants';

// Const declarations
const { Footer } = Layout;
const { Text } = Typography;
const baseClassName = 'footer-container';


const FooterContainer = () => {
  const { t } = useTranslation();
  const getCurrentYear = () => new Date().getFullYear();

  const content = (
    <div>
      <Text>{t('Call now at the phone:') + CONTACT_US_PHONE}</Text>
    </div>
  );

  return (
    <Footer className={`${baseClassName}`}>
      <div className="container">
        <Row align="middle">
          <Col lg={18} md={24} className={`${baseClassName}__links-wrapper`}>
            <Button type="link" onClick={() => window.open(TERMS_OF_SERVICE_URL, '_blank')}>
              {t('Terms of Service')}
            </Button>
            <Button type="link" onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}>
              {t('Privacy policy')}
            </Button>
            <Button type="link" onClick={() => Modal.info({ title: t('Contact us'), content, centered: true })}>
              {t('Contact us')}
            </Button>
            <Button type="link" onClick={() => window.open('https://my.generali.gr/services.html#my-e-doctor', '_blank')}>
              {t('Learn more')}
            </Button>
          </Col>
          <Col lg={6} md={24} className={`${baseClassName}__logo-wrapper as-c`}>
            <div className={`${baseClassName}__social-wrapper`}>
              <SocialIcon target="_blank" bgColor="#9c9c9d" url={YOUTUBE_URL} />
              <SocialIcon target="_blank" bgColor="#9c9c9d" url={FACEBOOK_URL} />
            </div>
            <a
              href='https://www.generali.gr'
              target='_blank'
              rel="noopener noreferrer">
              <img className={`${baseClassName}__logo`} src={GLogo} alt="generali" />
            </a>
            <br />
            <Text>&copy; {getCurrentYear()} {t('Generali Hellas')}</Text>
          </Col>
        </Row>
      </div>
    </Footer>
  )
}

export default FooterContainer;
