//@flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Row, Col, List, Popover, Modal, Card, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import PersonalInformation from '../../components/MedicalProfile/PersonalInformation';
import Sidebar from '../../components/MedicalProfile/Sidebar';
import { getMedicalProfile, measurementSelectors, selectors } from '../../store/slices/medicalProfileSlice';
import PaginatedMedicalProfileResource from '../../components/MedicalProfile/PaginatedMedicalProfileResource';
import moment from 'moment';
import { getUserFullname } from '../../utils/index';
import MPEditMedicalHistoryItem from '../../components/MedicalProfile/MPEditMedicalHistoryItem';
import MPEditFamilyHistoryItem from '../../components/MedicalProfile/MPEditFamilyHistoryItem';
import MPEditMeasurementItem from '../../components/MedicalProfile/MPEditMeasurementItem';
import MPEditExaminationItem from '../../components/MedicalProfile/MPEditExaminationItem';
import MPEditAllergyItem from '../../components/MedicalProfile/MPEditAllergyItem';
import MPMedicineItem from '../../components/MedicalProfile/MPEditMedicationItem';
import MPEditReportItem from '../../components/MedicalProfile/MPEditReportItem';
import { unwrapResult } from '@reduxjs/toolkit';
import type {
  MPMedication,
  MPMedicine,
  MPMedicalHistory,
  MPFamilyHistory,
  MPReport,
  MPAllergy,
  MPExamination,
  MPMeasurement,
  MPResourceChoiceObject,
} from '../../config/types-mp';
import type { Doctor } from '../../config/types';

const { Text } = Typography;

const ProfileScreen = (): React$Element<'div'> => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [visiblePopover, setVisiblePopover] = useState(false);

  const handleVisiblePopover = visible => {
    setVisiblePopover(visible);
  };

  const measurementTypesObj: Object = useSelector((state) =>
    measurementSelectors.getMeasurementTypesObject(state.medical_profile),
  );

  const keysPerMeasurementType = useSelector((state) =>
    measurementSelectors.getMeasurementValidFieldKeysPerType(
      state.medical_profile,
    ),
  );
  const medicationInfo: Object = useSelector((state) =>
    selectors.getOptionsForResourceItem(state.medical_profile, 'medications'),
  );
  const medicationFrequencyChoicesObj = {};
  medicationInfo?.frequency?.choices?.forEach((tmp) => {
    medicationFrequencyChoicesObj[tmp.value] = tmp.display_name;
  });

  const allergiesInfo: Object = useSelector((state) =>
    selectors.getOptionsForResourceItem(state.medical_profile, 'allergies'),
  );
  const allergiesChoicesObj = {};
  allergiesInfo?.type?.choices?.forEach((tmp) => {
    allergiesChoicesObj[tmp.value] = tmp.display_name;
  });

  const [item, setItem] = useState({});
  const [isModalVisible, setModalVisible] = useState(false);
  const [addItemBtn, setAddItemBtn] = useState(0);

  React.useEffect(() => {
    async function fetchProfile() {
      try {
        await dispatch(
          getMedicalProfile({
            patient_id: null,
            consultation_id: null,
          }),
        ).then(unwrapResult);
      } catch (error) {
        console.log('error fetching profile', error);
      }
    }
    fetchProfile();
  }, [dispatch]);

  //Set profile section with values from 0 to 7// 
  const [profileSection, setProfileSection] = useState(0);
  const { profile } = useSelector(state => state.medical_profile);

  const onItemClick = (item: Object) => {
    setModalVisible(true);
    setAddItemBtn(profileSection);
    setItem(item);
  }

  const paginatedlistItem = (item: Object, title?: string, description?: any) => {
    return (
      <List.Item
        className={'profile-screen-listItem'}
      >
        <List.Item.Meta
          title={
            <Text>
              {title}
            </Text>
          }
          description={description}
          onClick={() => {
            onItemClick(item);
          }}
        />
      </List.Item>
    )
  }

  const renderProfileSection = () => {
    let component;
    switch (profileSection) {
      case 0:
        component = <PersonalInformation profile={profile} />
        break;
      case 1:
        component = <PaginatedMedicalProfileResource
          editable={true}
          key={profileSection}
          profile={profile}
          onItemEdit={() => onItemClick({})}
          iconName={'file-add-outline'}
          title={t('Medical history')}
          addNewButtonTitle={t('Add new medical history')}
          resource={'medical_history'}
          onRenderItem={(item: MPMedicalHistory, index) => paginatedlistItem(item, item.name, moment(item.date).format('D/M/YYYY'))}
        />
        break;
      case 2:
        component = <PaginatedMedicalProfileResource
          profile={profile}
          key={profileSection}
          onItemEdit={() => onItemClick({})}
          iconName={'people-outline'}
          title={t('Family history')}
          addNewButtonTitle={t('Add new family history')}
          resource={'family_history'}
          onRenderItem={(item: MPFamilyHistory, index) => paginatedlistItem(item, item.notes, moment(item.created_at).format('D/M/YYYY'))}
        />
        break;
      case 3:
        component = <PaginatedMedicalProfileResource
          key={profileSection}
          profile={profile}
          onItemEdit={() => onItemClick({})}
          iconName={'clipboard-outline'}
          title={t('Consultation reports')}
          addNewButtonTitle={t('Add new consultation report')}
          resource={'reports'}
          onRenderItem={(item: MPReport, index) => {
            const doctor: Doctor | Object =
              typeof item?.consultation?.doctor === 'object'
                ? item?.consultation?.doctor
                : {};
            return paginatedlistItem(item, getUserFullname(doctor), [
              doctor?.speciality || '',
              moment(item.created_at).format('ddd D-M-YYYY'),
            ].join(' - '))
          }}
        />
        break;
      case 4:
        component = <PaginatedMedicalProfileResource
          profile={profile}
          key={profileSection}
          onItemEdit={() => onItemClick({})}
          title={t('Medications')}
          addNewButtonTitle={t('Add new medication')}
          resource={'medications'}
          onRenderItem={(item: MPMedication, index) => paginatedlistItem(item, item.name,
            `${item.dose || ''} - ${medicationFrequencyChoicesObj[item.frequency || ''] || ''}`)}
        />
        break;
      case 5:
        component = <PaginatedMedicalProfileResource
          profile={profile}
          key={profileSection}
          onItemEdit={() => onItemClick({})}
          title={t('Allergies')}
          addNewButtonTitle={t('Add new allergy')}
          resource={'allergies'}
          onRenderItem={(item: MPAllergy, index) => paginatedlistItem(item, allergiesChoicesObj[item.type || ''],
            Boolean(item.notes) === true ? (<Text>{item.notes}</Text>) : null)}
        />
        break;
      case 6:
        component = <PaginatedMedicalProfileResource
          profile={profile}
          key={profileSection}
          onItemEdit={() => onItemClick({})}
          title={t('Examinations')}
          addNewButtonTitle={t('Add new examination')}
          resource={'examinations'}
          onRenderItem={(item: MPExamination, index) => paginatedlistItem(item, item.type, moment(item.date).format('D/M/YYYY'))}
        />
        break;
      case 7:
        component = <PaginatedMedicalProfileResource
          profile={profile}
          onItemEdit={() => onItemClick({})}
          title={t('Measurements')}
          addNewButtonTitle={t('Add new measurement')}
          iconName={'thermometer-outline'}
          resource={'measurements'}
          onRenderItem={(item: MPMeasurement, index) => {
            const values = item.values || [];
            const displayVals = values
              .filter((v) => keysPerMeasurementType[item.type].includes(v.key))
              .map((tmp) => `${tmp.value || ''} ${tmp.unit || ''}`)
              .join(' / ');
            return paginatedlistItem(item, measurementTypesObj[item.type] || item.type, `${displayVals} - ${moment(item.date).format('D/M/YYYY',)}`)
          }}
        />
        break;
      default:
        component = <PersonalInformation />
    }
    return component;
  }

  const renderAddItemBtn = (title: string, onClickCase: number) => {
    return (
      <p>
        <Button
          type="primary"
          ghost={true}
          onClick={() => {
            onItemClick({});
            setAddItemBtn(onClickCase);
            handleVisiblePopover(false);
          }}
          size="small">
          {title}
        </Button>
      </p>
    )
  }

  const content = (
    <div>
      {renderAddItemBtn(t('Medical history'), 1)}
      {renderAddItemBtn(t('Family history'), 2)}
      {renderAddItemBtn(t('Consultation details'), 3)}
      {renderAddItemBtn(t('Medication'), 4)}
      {renderAddItemBtn(t('Allergy'), 5)}
      {renderAddItemBtn(t('Examination'), 6)}
      {renderAddItemBtn(t('Measurement'), 7)}
    </div>
  );

  const renderModal = () => {
    let modal;
    switch (addItemBtn) {
      case 1:
        modal = <MPEditMedicalHistoryItem onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} dataItem={item} visible={isModalVisible}></MPEditMedicalHistoryItem>
        break;
      case 2:
        modal = <MPEditFamilyHistoryItem onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} dataItem={item} visible={isModalVisible}></MPEditFamilyHistoryItem>
        break;
      case 3:
        modal = <MPEditReportItem onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} dataItem={item} visible={isModalVisible}></MPEditReportItem>
        break;
      case 4:
        modal = <MPMedicineItem dataItem={item} onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} visible={isModalVisible} />
        break;
      case 5:
        modal = <MPEditAllergyItem dataItem={item} onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} visible={isModalVisible} />
        break;
      case 6:
        modal = <MPEditExaminationItem dataItem={item} onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} visible={isModalVisible} />
        break;
      case 7:
        modal = <MPEditMeasurementItem dataItem={item} onCloseModal={(modalVisibility) => setModalVisible(modalVisibility)} visible={isModalVisible} />
        break;
    }
    if (isModalVisible) {
      return modal;
    } else {
      return null;
    }
  }

  return (
    <div className={`container profile-footer`}>
      <Row align="center">
        <Col lg={24} md={24}>
          <Row>
            <Col>
              <PageHeader
                title={t('Profile')}
                extra={[
                  <Popover
                    placement="bottom"
                    key="1"
                    content={content}
                    trigger="click"
                    visible={visiblePopover}
                    onVisibleChange={handleVisiblePopover}
                  >
                    <Button
                      type="primary"
                      ghost={true}
                      icon={<PlusCircleOutlined />}
                    >
                      {t('Add new')}
                    </Button>
                  </Popover>,
                ]}
              />
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xl={6} lg={8} md={10}>
              <Sidebar selectedSection={profileSection} onChangeProfileSection={(newProfileSection) => {
                setAddItemBtn(0)
                setProfileSection(newProfileSection)
              }} />
            </Col>
            <Col xl={18} lg={16} md={14} className="profile-section">
              <Card className="drop-shadow">
                {renderProfileSection()}
                {renderModal()}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>

  )
}

export default ProfileScreen;