import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import localeGR from 'antd/es/locale/el_GR';
import localeEnUS from 'antd/es/locale/en_US';

const langToLocale = (lang) => {
  if (lang === 'el') {
    return localeGR;
  }
  return localeEnUS;
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(langToLocale(i18n.language));

  const onChange = useCallback((lang) => {
    setLocale(langToLocale(i18n.language));
  }, [i18n]);

  useEffect(() => {
    i18n.on('languageChanged', onChange);
    i18n.on('initialized', onChange);

    return () => {
      i18n.off('languageChanged', onChange);
      i18n.off('initialized', onChange);
    }
  }, [i18n, onChange]);

  return {
    locale,
    language: i18n.language,
    i18n,
  }
};

