// @flow
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewUserNotifications } from '../store/slices/notificationsSlice';
import { isUserAuthenticated } from '../store/slices/authSlice';
import { useInterval } from './useInterval';
import { unwrapResult } from '@reduxjs/toolkit';
import { POLLING_INTERVAL_NEW_NOTIFICATIONS } from '../constants';

/**
 * Polls every X seconds and fetches new notifications (if any).
 */
export const useNewNotificationsPolling = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isUserAuthenticated);

  const check = useCallback(() => {
    const fetchNewNotifications = async () => {
      try {
        const action = await dispatch(getNewUserNotifications());
        unwrapResult(action);
      } catch (error) {
        console.log('error fetching new notifications', error);
      }
    }
    fetchNewNotifications();
  }, [dispatch]);

  const delay = isAuthenticated
    ? POLLING_INTERVAL_NEW_NOTIFICATIONS * 1000
    : null;

  useInterval(check, delay);
};
