import axios from 'axios';
// import { appConfig } from '../setup';
import { API_BASE_URL } from '../constants';
import authentication from '../plugins/authentication';
import i18n from 'i18next';
import { getApiErrorMessage } from './lib/apiErrors';

console.log('API_BASE_URL: ', API_BASE_URL);
/**
 *
 * @param {object} options
 * @param {string} options.baseURL
 * @param {number} options.timeout
 * @param {object} options.headers
 */
export const createAxiosInstance = (options) => {
  const baseURL = options.baseURL || API_BASE_URL;
  const needsAuth = options.needsAuth;
  const timeout = options.timeout || 5000;
  const instance = axios.create(options);

  instance.interceptors.request.use(async request => {
    const authToken = authentication.getAuthToken();
    // construct url
    let url = new URL(baseURL + request.url);
    request.url = url;
    if (request.params && request.method === 'get') {
      Object.keys(request.params).forEach(key => url.searchParams.append(key, request.params[key]));
    }

    if (i18n.language) {
      request.headers = {
        ...request.headers,
        'Accept-Language': i18n.language,
      }
    }

    if (needsAuth) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${authToken}`
      }
    }
    request.timeout = timeout;
    return request;
  }, error => Promise.reject(error));

  instance.interceptors.response.use(null, error => {
    console.log('error on axios', error);
    const tempErrorMessage = getApiErrorMessage(error.response.data.code, error.response.data.message);
    const tempErrorObject = {
      ...error.response.data,
      status: error.response.data.status,
      code: error.response.data.code,
      message: tempErrorMessage,
    };
    return Promise.reject(tempErrorObject);
  })

  return {
    get: (url, params) => instance.get(url, {
      params
    }),
    post: (url, payload, config = {}) =>
      instance.post(url, payload, config),
    patch: (url, payload) =>
      instance.patch(url, payload),
    options: (url, params) => instance.options(url, {
      params
    }),
    delete: (url, params) => instance.delete(url, { params }),
  };
};

// function errorResponseHandler(error) {
//   if (DEBUG) {
//     console.warn('Error as caught in Axios error handler', error);
//   }
//   if (error.response) {
//     if (error.response.status === 500) {
//       return Promise.reject(new Error('Api error: Network error: ' + error.response.statusText));
//     }
//     if (error.response.status === 403 || error.response.status === 401) {
//       return Promise.reject(new Error('Api error: Network error: ' + error.response.statusText));
//     }
//     const msg = error.response.data.errorMessage || error.response.data.message;
//     return Promise.reject(new Error('Api error: Bad request: ' + msg, ERROR_CODE.api, ERROR_CODE.api));
//   } else if (error.request) {
//     return Promise.reject(new Error('Api error: Network error: ' + error.message, ERROR_CODE.api_network_error));
//   } else {
//     return Promise.reject(new Error('Run time error: ' + error.message));
//   }
// }
