// @flow
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDataLoader } from '../../hooks/useDataLoader';
import type { DataLoaderHookProps } from '../../hooks/useDataLoader';
// Components imports
import { Row, Col, PageHeader, List, Button } from 'antd';
import InfoCard from '../../components/InfoCard';
import { PlusOutlined } from '@ant-design/icons';

// Misc imports
import {
  getConsultations,
  getConsultationsList,
} from '../../store/slices/consultationSlice';
import { openCallInitializationModal } from '../../store/slices/applicationSlice';

// const declarations
const baseClassName = 'visits';

const VisitsScreen = (): React$Element<'div'> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const list = useSelector(getConsultationsList);

  const apiCaller = useCallback(
    (params: Object) => {
      return dispatch(
        getConsultations({
          ...params,
        }),
      );
    },
    [dispatch],
  );

  const {
    canRenderContent,
    renderLoadMoreFooter,
    renderEmptySpace,
  }: DataLoaderHookProps = useDataLoader(apiCaller, null, {
    disableAutoLoad: false,
  });

  return (
    <div className={`${baseClassName} container`}>
      <Row align="center">
        <Col lg={16} md={24}>
          <Row>
            <Col>
              <div className={`${baseClassName}__header-title`}>
                <PageHeader
                  title={t('Visits')}
                  extra={[
                    <Button
                      key="1"
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() => dispatch(openCallInitializationModal())}>
                      {t('Call a doctor')}
                    </Button>,
                  ]}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {canRenderContent ? (
                <>
                  <List
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => (
                      <List.Item
                        data-item={JSON.stringify(item)}
                        onClick={(e) => {
                          e.preventDefault();
                          console.log(e);
                        }}>
                        <InfoCard item={item} />
                      </List.Item>
                    )}
                  />
                  {list.length > 0 ? (
                    <div className="d-flex jc-c">{renderLoadMoreFooter()}</div>
                  ) : null}
                </>
              ) : (
                renderEmptySpace(t('No calls available'))
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default VisitsScreen;
