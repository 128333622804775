// @flow
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshCurrentConsultation } from '../store/slices/consultationSlice';
import type { Consultation } from '../config/types';
import { unwrapResult } from '@reduxjs/toolkit';

/**
 * Custom hook to get the current consultation.
 * When providing an `id`, the current consultation will be replaced with the consultation matching this id.
 * You can pass `fallbackToLocalSearch` with value of `true` in order to look for
 * the consultation with the given `id` in other places.
 * This can improve the UX as you can get the consultation (if exists),
 * before becoming "current".
 *
 * @param {{
 *   id?: number,
 *   refresh?: boolean,
 *   fallbackToLocalSearch?: boolean
 * }} [opts]
 * @returns {{
 *   consultation?: Consultation,
 *   loading: boolean,
 * }}
 */
export const useCurrentConsultation = (opts?: {
  id?: number,
  refresh?: boolean,
  fallbackToLocalSearch?: boolean,
}): {
  consultation?: Consultation,
  loading: boolean,
} => {
  const { id, refresh, fallbackToLocalSearch } = opts || {};

  const dispatch = useDispatch();
  const { current, consultationsList } = useSelector(
    (state) => state.consultation,
  );
  const [loading, setLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);

  useEffect(() => {
    if (id != null && current.id !== id) {
      setLoadedOnce(false);
    }
  }, [id, current.id]);

  useEffect(() => {
    const fetchConsultation = async () => {
      try {
        setLoading(true);
        await dispatch(refreshCurrentConsultation(id)).then(unwrapResult);
        setLoadedOnce(true);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    if (!loadedOnce && (refresh || (id != null && current.id !== id))) {
      fetchConsultation();
    }
  }, [loadedOnce, current.id, id, refresh, dispatch]);

  /**
   * Searches for a consultation with the given (from opts) id.
   * The search happens in the list of consultations and not in "current".
   */
  const searchLocal = useCallback(() => {
    let searchResult;
    if (id && fallbackToLocalSearch) {
      searchResult = consultationsList.find((tmp) => tmp.id === id);
    }
    return searchResult;
  }, [id, consultationsList, fallbackToLocalSearch]);
  return {
    consultation:
      id == null || (id != null && parseInt(id) === parseInt(current?.id))
        ? current
        : searchLocal(),
    loading,
  };
};
