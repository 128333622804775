import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDataLoader } from '../../hooks/useDataLoader';
import type { DataLoaderHookProps } from '../../hooks/useDataLoader';

// Components imports
import { Row, Col, PageHeader, List, Button } from 'antd';
import InfoCard from '../../components/InfoCard';
import { PlusOutlined } from '@ant-design/icons';

//Misc imports
import {
  getAppointments,
  getAppointmentsList,
} from '../../store/slices/appointmentsSlice';
import { NAV_APPOINTMENTS_DOCTORS } from '../../constants';

// Const declarations
const baseClassName = 'appointments';

const AppointmentsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const list = useSelector(getAppointmentsList);

  const apiCaller = useCallback(
    (params: Object) => {
      return dispatch(
        getAppointments({
          ...params,
          finish__gte: new Date().toISOString(),
          ordering: 'start',
        }),
      );
    },
    [dispatch],
  );

  const {
    canRenderContent,
    renderLoadMoreFooter,
    renderEmptySpace,
  }: DataLoaderHookProps = useDataLoader(apiCaller, null, {
    disableAutoLoad: false,
  });

  return (
    <div className={`${baseClassName} container`}>
      <Row align="center">
        <Col lg={16} md={24}>
          <Row>
            <Col>
              <div className={`${baseClassName}__header-title`}>
                <PageHeader
                  title={t('Appointments')}
                  extra={[
                    <Button
                      key="1"
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() => history.push(NAV_APPOINTMENTS_DOCTORS)}>
                      {t('Book appointment')}
                    </Button>,
                  ]}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {canRenderContent ? (
                <>
                  <List
                    itemLayout="horizontal"
                    dataSource={list}
                    renderItem={(item) => {
                      return (
                        <List.Item
                          data-item={JSON.stringify(item)}
                          onClick={(e) => {
                            e.preventDefault();
                          }}>
                          <InfoCard item={item} isAppointment />
                        </List.Item>
                      );
                    }}
                  />
                  {list.length > 0 ? (
                    <div className="d-flex jc-c">{renderLoadMoreFooter()}</div>
                  ) : null}
                </>
              ) : (
                renderEmptySpace(t('No appointments available'))
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentsScreen;
