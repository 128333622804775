// @flow
import React from 'react';
import { Button } from 'antd';

const baseClassName = 'quick-replies'

type Props = {
  choices: Array<{
    id: string,
    label: string,
  }>,
  meta?: Object,
  className?: string,
  onSelectChoice: (
    {
      id: string,
      label: string,
    },
    meta?: Object,
  ) => void,
};

const QuickReplies = (props: Props): React$Element<any> => {
  return (
    <div className={`${baseClassName} ${props.className ? props.className : ''}`}>
      {props.choices?.map((c, index) => {
        const isLast = index === props.choices.length - 1;
        return (
          <Button
            key={c.id}
            size="small"
            className={`${baseClassName}__btn ${isLast ? 'last-btn' : ''}`}
            onClick={() => props.onSelectChoice?.(c, props.meta)}>
            {c.label}
          </Button>
        );
      })}
    </div>
  );
};

export default QuickReplies;
