import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

//Components
import { Button, Checkbox, Col, Form, Row, Typography } from 'antd';

// Misc
import HealthWatch from '../../assets/images/logos/healthwatch_logo.png';
import { patchProfile } from '../../store/slices/profileSlice';
import { TERMS_OF_SERVICE_URL, PRIVACY_POLICY_URL } from '../../constants';

const baseClassName = 'tos';
const {Text} = Typography;

const TOS = (props: Props): React$Element<any> => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [tosChecked, setTosChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [gdprChecked, setGdprChecked] = useState(false);
  const [form] = Form.useForm();
  const { tos_accepted, policy_accepted, gdpr_accepted, id } = useSelector((state) => state.profile.user);
  const { isUpdatingProfile, updateProfileError } = useSelector((state) => state.profile);

  useEffect(() => {
    setTosChecked(tos_accepted);
    setPrivacyChecked(policy_accepted);
    setGdprChecked(gdpr_accepted);
  }, [tos_accepted, policy_accepted, gdpr_accepted]);

  // On form submit button pressed
  const onFinish = (values) => {
    dispatch(patchProfile({
      id,
      data: {
        tos_accepted: tosChecked,
        policy_accepted: privacyChecked,
        gdpr_accepted: gdprChecked
      }
    }));
  }

  return (<div className={`${baseClassName} container pt-5 pb-5`}>
    <Row justify="center">
      <Col md={16}>
        <img className="d-block text-center" src={HealthWatch} alt="healthwatch"/>
        <h2>{t('Terms and conditions')}</h2>
        <p>{t('Please read and accept the following Terms of Use as well as the Generali My e-Doctor Privacy Policy, so that you can continue.')}</p>
        <Form
          form={form}
          onFinish={onFinish}>
          <Form.Item
            name="tos"
          >
            <Checkbox
              checked={tosChecked}
              onChange={e => setTosChecked(e.target.checked)}
            >
              <Trans i18nKey="I accept the <2>Terms of Service</2>">
                I accept the{' '}
                <a target="_blank" rel="noreferrer" href={TERMS_OF_SERVICE_URL}>Terms of Service</a>
              </Trans></Checkbox>
          </Form.Item>

          <Form.Item
            name="privacy"
          >
            <Checkbox
              checked={privacyChecked}
              onChange={e => setPrivacyChecked(e.target.checked)}
            >
              <Trans i18nKey="I have become aware of <2>Privacy Policy</2>">
                I have become aware of{' '}
                <a target="_blank" rel="noreferrer" href={PRIVACY_POLICY_URL}>Privacy Policy</a>
              </Trans></Checkbox>
          </Form.Item>

          <Form.Item
            name="gdpr"
          >
            <Checkbox
              checked={gdprChecked}
              onChange={e => setGdprChecked(e.target.checked)}
            >
              {t('I consent to the processing of my health data by Healthwatch S.A.',)}
            </Checkbox>
          </Form.Item>

          {
            updateProfileError
              ? <p><Text type="danger">{t('Internal server error')}</Text></p>
              : null
          }

          <Button
            disabled={!tosChecked || !privacyChecked || !gdprChecked}
            loading={isUpdatingProfile}
            type="primary"
            htmlType="submit"
          >
            {t('Next')}
          </Button>
        </Form>
      </Col>
    </Row>
  </div>)
}
export default TOS;
