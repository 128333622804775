/*
Generic reducer that holds the state for various things about the application.
*/
import { createSlice } from '@reduxjs/toolkit';

const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    callInitModalVisible: false,
    callInitAppointment: null,
    bookAppointmentModalVisible: false,
    bookAppointmentSlot: null,
    bookAppointmentDoctor: null,
    appointmentInvalidationModalVisible: false,
    appointmentInavlidationAppointment: null
  },
  reducers: {
    openCallInitializationModal: (state, { payload }) => {
      state.callInitModalVisible = true;
      state.callInitAppointment = payload?.appointment || null;
      state.bookAppointmentModalVisible = false;
      state.appointmentInvalidationModalVisible = false;
    },
    closeCallInitializationModal: (state, action) => {
      state.callInitModalVisible = false;
      state.callInitAppointment = null;
    },
    openBookAppointmentModal: (state, { payload }) => {
      state.callInitModalVisible = false;
      state.appointmentInvalidationModalVisible = false;
      state.bookAppointmentModalVisible = true;
      state.bookAppointmentSlot = payload.slot;
      state.bookAppointmentDoctor = payload.doctor;
    },
    closeBookAppointmentModal: (state, action) => {
      state.bookAppointmentModalVisible = false;
      state.bookAppointmentSlot = null;
      state.bookAppointmentDoctor = null;
    },
    openAppointmentInvalidationModal: (state, { payload }) => {
      state.callInitModalVisible = false;
      state.bookAppointmentModalVisible = false;
      state.appointmentInvalidationModalVisible = true;
      state.appointmentInavlidationAppointment = payload?.appointment;
    },
    closeAppointmentInvalidationModal: (state, action) => {
      state.appointmentInvalidationModalVisible = false;
      state.appointmentInavlidationAppointment = null;
    },
  }
});

// Destructure and export the plain action creators
export const {
  /**
   * Opens up a modal in which the user can start a new video call.
   * You can pass information like "appointment".
   *
   * @example
   * ```
   * dispatch(openCallInitializationModal({ appointment }));
   * ```
   */
  openCallInitializationModal,
  closeCallInitializationModal,
  openBookAppointmentModal,
  closeBookAppointmentModal,
  openAppointmentInvalidationModal,
  closeAppointmentInvalidationModal,
} = applicationSlice.actions;
export const applicationReducer = applicationSlice.reducer;
