import React, { useState } from 'react';
// import { Button } from 'antd';
import ToggleAudioButton from '../ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../ToggleVideoButton/ToggleVideoButton';
import EndCallButton from '../EndCallButton/EndCallButton';
import DeviceSelectionDialog from '../DeviceSelectionDialog/DeviceSelectionDialog';

const useStyles = () => {
  return {
    container: {
      position: 'absolute',
      zIndex: 5,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    controlsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'rgba(252,252,252,1)',
      marginBottom: '12px',
      borderRadius: '4px',
      paddingLeft: '38px',
      paddingRight: '38px',
    },
  };
};

export default function Room() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <div style={classes.container}>
      <div style={classes.controlsContainer}>
        <ToggleAudioButton />
        <ToggleVideoButton />
        {/* <Button onClick={() => setOpen(!open)}>Settings</Button> */}
        <EndCallButton />
      </div>
      <DeviceSelectionDialog open={open} onClose={() => setOpen(false)} />
    </div>
  );
}
