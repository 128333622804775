import React from 'react';
import Video from 'twilio-video';
import { Result, Typography } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

export default function UnsupportedBrowserWarning({
  children,
}: {
  children: React.ReactElement,
}) {
  const { t } = useTranslation();
  if (!Video.isSupported) {
    return (
      <Result
        status="warning"
        title={t('Browser or context not supported for video calls')}
        subTitle={
          <Trans>
            <Typography.Text>
              Please open this application in one of the{' '}
              <a
                href="https://www.twilio.com/docs/video/javascript#supported-browsers"
                target="_blank"
                rel="noreferrer">
                supported browsers
              </a>
              .
            </Typography.Text>
          </Trans>
        }
      />
    );
  }

  return children;
}
