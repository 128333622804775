import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { DEBUG } from '../constants';

// Import translations
import el from './translations/el.json';
import en from './translations/en.json';

let _t;

export const translate = (key, opts) => {
  if (_t != null) {
    return _t(key, opts);
  }
  return key;
};

i18n
  // detect user language
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      fallbackLng: 'el',
      debug: DEBUG,
      resources: {
        el: { translations: el },
        en: { translations: en }
      },
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      },
      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: false,
      nsSeparator: false,
    },
    (err, t) => {
      if (err) {
        console.log('something went wrong loading', err);
      } else {
        _t = t;
      }
    }
  );

// Change `lang` property for root element
if (document.documentElement != null) {
  document.documentElement.lang = i18n.language;
}

export default i18n;
