import { useEffect } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentConsultation } from '../../../hooks/useCurrentConsultation';
import { NAV_VISITS } from '../../../constants';
import { translate } from '../../../locales';

// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
function enhanceMessage(message = '', code?: number) {
  switch (code) {
    case 20101: // Invalid token error
      return (
        message + '. Please make sure you are using the correct credentials.'
      );
    case 53103: // Unable to create Room
      return translate('Your video call has been completed');
    default:
      return message;
  }
}

const VideoCallErrorHandler = (props) => {
  const { onDismiss, error } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { consultation } = useCurrentConsultation();

  useEffect(() => {
    if (error) {
      if (error.code === 53118) {
        // Room completed
        // setVideoCallError(null);
        // message.success(t('Your video call has been completed'));
        Modal.success({
          content: t('Your video call has been completed'),
          afterClose: onDismiss,
        });
        if (consultation.id) {
          history.push(`${NAV_VISITS}/${consultation.id}`);
        }
      } else {
        const { message, code } = error || {};
        const enhancedMessage = enhanceMessage(message, code);
        Modal.error({
          title: `Error Code: ${code}`,
          content: enhancedMessage,
          afterClose: onDismiss,
        });
      }
    }
  }, [error, history, onDismiss, consultation?.id, t]);

  return null;
};

export default VideoCallErrorHandler;
