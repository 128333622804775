//@flow
import React from 'react';
import { Button, Typography, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import PersonIcon from '../../assets/icons/person-outline.svg';
import HistoryIcon from '../../assets/icons/file-add-outline.svg';
import FamilyIcon from '../../assets/icons/people-outline.svg';
import ReportsIcon from '../../assets/icons/clipboard-outline.svg';
import MedicinesIcon from '../../assets/icons/pill.svg';
import AllergyIcon from '../../assets/icons/allergy.svg';
import ExaminationIcon from '../../assets/icons/activity-outline.svg';
import MeasuramentIcon from '../../assets/icons/thermometer-outline.svg';

// Const declarations
const { Text, Title } = Typography;

type Props = {
  onChangeProfileSection: Function,
  selectedSection: number,
};

const Sidebar = (props: Props): React$Element<'div'> => {
  const { t, i18n } = useTranslation();
  const { onChangeProfileSection } = props;

  const sidebarButton = (iconName: any, section: number, title: Object) => {
    return (
      <Row className={props.selectedSection === section ? 'profile-sidebar-background' : null}>
        <Button
          className="profile-sidebar-title"
          type='text'
          icon={<img className="profile-sidebar-icon" src={iconName} />}
          onClick={() => onChangeProfileSection(section)}
          size="large">
          {title}
        </Button>
      </Row>
    );
  }

  return (
    <div>
      {sidebarButton(PersonIcon, 0, t('Personal information'))}
      {sidebarButton(HistoryIcon, 1, t('Medical history'))}
      {sidebarButton(FamilyIcon, 2, t('Family history'))}
      {sidebarButton(ReportsIcon, 3, t('Consultation reports'))}
      {sidebarButton(MedicinesIcon, 4, t('Medications'))}
      {sidebarButton(AllergyIcon, 5, t('Allergies'))}
      {sidebarButton(ExaminationIcon, 6, t('Examinations'))}
      {sidebarButton(MeasuramentIcon, 7, t('Measurements'))}
    </div>
  )
}

export default Sidebar;