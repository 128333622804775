// @flow
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import type { Consultation } from '../config/types';

/**
 * Custom hook to get the outgoing consultation.
 * You can pass `fallbackToLocalSearch` with value of `true` in order to look for
 * the consultation with the status "new" in other places.
 *
 * @param {{
 *   fallbackToLocalSearch?: boolean
 * }} [opts]
 * @returns {{
 *   consultation?: Consultation,
 * }}
 */
export const useOutgoingConsultation = (
  opts?: { fallbackToLocalSearch?: boolean }
): { consultation?: Consultation } => {
  const { fallbackToLocalSearch } = opts || {};

  const { outgoing, consultationsList } = useSelector(
    (state) => state.consultation,
  );

  /**
   * Searches for an outgoing call.
   * The search happens in the list of consultations and not status is "new".
   */
  const searchLocal = useCallback(() => {
    let searchResult;
    if (fallbackToLocalSearch) {
      searchResult = consultationsList.find((tmp) => tmp.status === 'new');
    }
    return searchResult;
  }, [consultationsList, fallbackToLocalSearch]);

  return {
    consultation: outgoing?.status === 'new' ? outgoing : searchLocal(),
  };
};
