// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import FilesManager from '../../../components/filesManager/FilesManager';
import { useFilesManagerHandler } from '../../../hooks/useFilesManagerHandler';
import { useRoomState } from '../../../plugins/video_call/hooks';
import { createNewConsultation } from '../../../store/slices/consultationSlice';
// $FlowFixMe
import UnsupportedBrowserWarning from '../../../components/video_call/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

// Components imports
import { Alert, Button, Form, Input, Modal, Radio, Typography } from 'antd';
import { VideoCameraOutlined, CloseCircleFilled } from '@ant-design/icons';

import type { Appointment, Consultation } from '../../../config/types';
import { DOCTOR, CALL_TO_DOCTOR } from '../../../constants';
import message from '../../../utils/message';
import { composeStyles } from '../../../utils/styling';
import { colors } from '../../../styles';

type Props = {
  visible?: boolean,
  /**
   * When the call is related to an appointment, this is where you pass the appointment's info.
   */
  appointment?: Appointment,
  onCancel?: (e: any) => void,
  onConsultationCreated?: (newConsultation: Consultation) => void,
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const CallNowDoctorModal = (
  props: Props,
): React$Element<React$FragmentType> => {
  const { visible, appointment, onCancel, onConsultationCreated } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roomState = useRoomState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const {
    files,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  } = useFilesManagerHandler();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onFinish = async (values: any) => {
    console.log(values);

    const data = {
      files,
      ...values,
    };
    if (appointment != null) {
      data.appointment = appointment.id;
      data.call_center = false;
    }

    try {
      setLoading(true);
      await continueWithFilesUploadCaution();
      const actionData = await dispatch(createNewConsultation(data));
      const newConsultation = unwrapResult(actionData);
      if (onConsultationCreated != null) {
        // $FlowFixMe
        onConsultationCreated(newConsultation);
      }
    } catch (e) {
      if (e.message !== 'cancelled') {
        message.error(e.message);
      }
    }
    setLoading(false);
  };

  const alreadyConnectedToVideoCall = roomState !== 'disconnected';
  return (
    <Modal
      title={
        <Typography.Title level={5} style={styles.title}>
          {t('Call a doctor')}
        </Typography.Title>
      }
      centered
      closable={true}
      closeIcon={<CloseCircleFilled />}
      visible={visible}
      destroyOnClose={true}
      width={831}
      bodyStyle={styles.body}
      footer={null}
      onCancel={onCancel}>
      <UnsupportedBrowserWarning>
        <div style={styles.contentContainer}>
          {alreadyConnectedToVideoCall && (
            <Alert
              style={styles.alert}
              message={(
                <Typography.Text style={{color: colors.black}}>
                  {t('You have an active video call')}
                </Typography.Text>
              )}
              type={'warning'}
              showIcon
            />
          )}
          <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
            <Typography.Title level={5}>
              {t('How can we help you?')}
            </Typography.Title>
            <Typography.Paragraph>
              {t('Tell us about your symptoms or the reason you want to see a doctor')}
            </Typography.Paragraph>
            <Form.Item name="description" initialValue={appointment != null ? appointment.description : ''}>
              <Input.TextArea
                autoFocus
                placeholder={t('Details (optional)')}
                rows={4}
              />
            </Form.Item>
            {process.env.NODE_ENV === 'development' || CALL_TO_DOCTOR === 'true' ?
              <Form.Item name="doctor" initialValue={DOCTOR}>
                <Input placeholder={'ID γιατρού για απευθείας κλήση (προαιρετικά)'} />
              </Form.Item>
              : null}
            <Typography.Title level={5} style={styles.contentTitle}>
              {t('Can doctor access your medical profile?')}
            </Typography.Title>
            <Form.Item
              name="allow_doctor_medical_access"
              initialValue={appointment != null ? appointment.allow_doctor_medical_access : false}>
              <Radio.Group>
                <Radio value={true}>{t('Yes')}</Radio>
                <Radio value={false}>{t('No')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Typography.Title level={5} style={styles.contentTitle}>
              {t('Attach files (optional)')}
            </Typography.Title>
            <FilesManager
              editable={true}
              files={appointment?.files}
              onFilesUpdated={onFilesUpdated}
            />
            <div style={styles.buttonContainer}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                icon={<VideoCameraOutlined />}
                style={styles.callNowButton}>
                {t('Call the doctor now')}
              </Button>
            </div>
          </Form>
        </div>
      </UnsupportedBrowserWarning>
    </Modal>
  );
};

const styles = {
  alert: {
    marginBottom: '24px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  body: {
    background: '#F5F5F5',
    borderRadius: '0 0 8px 8px',
  },
  contentTitle: {
    marginTop: '24px',
  },
  contentContainer: {
    maxWidth: '451px',
    margin: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  callNowButton: {
    flexGrow: 1,
    maxWidth: '360px',
    marginTop: '98px',
    marginBottom: '24px',
    background: '#1EC760',
  },
};

export default CallNowDoctorModal;
