// @flow
import * as CONSTANTS from './constants';
import type { ApiError } from '../../config/types';

/**
 * Converts an object of parameters to a URL query string.
 *
 * @example
 * ```
 * const params = {
 *   status: 'new',
 *   type: 'video'
 * };
 *
 * ... converts to ...
 *
 *  `?id=new&type=video`
 * ```
 *
 * @param {Object} params The parameters to add to the URL.
 * @returns
 */
export const getQueryString = (params: Object = {}): string => {
  if (params == null) {
    return '';
  }
  const result = Object.keys(params)
    .map((key) => {
      const objValue = params[key];
      if (Array.isArray(objValue)) {
        return objValue.map((arrayItem) => {
          const encodedArrayKey = encodeURIComponent(`${key}[]`);
          const encodedArrayItem = encodeURIComponent(arrayItem);
          return `${encodedArrayKey}=${encodedArrayItem}`;
        });
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(objValue)}`;
    })
    .join('&');
  return result.length > 0 ? `?${result}` : result;
};

/**
 * Returns a number indicating an HTTP status.
 *
 * @param {ApiError} error An API error to get the status from.
 */
const getStatus = (error: ApiError): number => {
  let res = -1000000;
  if (error.status != null) {
    if (typeof error.status === 'number') {
      res = error.status;
    } else {
      const parsed = parseInt(`${error.status}`, 10);
      if (!isNaN(parsed)) {
        res = parsed;
      }
    }
  }
  return res;
};

/**
 * Returns `true` when a given ApiError is 400-ish HTTP status code.
 * Otherwise, returns `false`.
 *
 * @param {ApiError} error An API error to check against.
 * @returns
 */
export const isClientError = (error: ApiError): boolean => {
  const status = getStatus(error);
  return status >= 400 && status < 500;
};

/**
 * Returns `true` when a given ApiError is 500-ish HTTP status code.
 * Otherwise, returns `false`.
 *
 * @param {ApiError} error An API error to check against.
 * @returns
 */
export const isServerError = (error: ApiError): boolean =>
  getStatus(error) >= 500;

/**
 * Returns `true` when a given ApiError indicates that the user is not authorized
 * to access a resource (401 HTTP status code). Otherwise, returns `false`.
 *
 * @param {ApiError} error An API error to check against.
 * @returns
 */
export const isUnauthorizedUserError = (error: ApiError): boolean =>
  getStatus(error) === 400;

/**
 * Checks whether or not, a given error, is an error that indicates the user has no internet connection.
 *
 * @param {ApiError} error A network error to check if it is a "no internet connection" error.
 * @returns Boolean.
 */
export const isNoInternetConnectionError = (error: ApiError): boolean =>
  error.code === CONSTANTS.NETWORK_OFFLINE_CODE;

/**
 * Checks whether or not, a given error, is an error that indicates that an API's resource "entry" is duplicate.
 *
 * @param {ApiError} error An API error to check against.
 * @returns {boolean}
 */
export const isDuplicateEntryError = (error: ApiError): boolean =>
  error?.meta?.id?.find((item) => item === 'unique') != null;

/**
 * Checks whether or not, a givne error, is a "404 not found" error.
 *
 * @param {ApiError} error An API error to check against.
 * @returns {boolean}
 */
export const isNotFoundError = (error: ApiError): boolean =>
  error?.status === 404;
