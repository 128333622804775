// @flow
import * as React from 'react';
import { Button, Divider, Spin, Typography } from 'antd';
import Paginator from '../paginator/Paginator';
import Icon from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDataLoader } from '../../hooks/useDataLoader';
import { listMedicalProfileResourceItems } from '../../store/slices/medicalProfileSlice';
import { PlusCircleOutlined } from '@ant-design/icons';

import type {
  MedicalProfileResource,
  MPProfile,
} from '../../config/types-mp';

const PAGE_SIZE = 5;

const PlusIcon = (props) => <Icon {...props} name="plus-outline" />;

const LoadingIndicator = (props) => (
  <div style={props.style}>
    <Spin size={'small'} />
  </div>
);

const { Text } = Typography;

type Props = {
  title?: string,
  addNewButtonTitle?: string,
  icon?: React.Node,
  iconName?: string,
  emptyStateMessage?: string,
  profile?: MPProfile,
  /** non-patient users can possibly have access through the a consultation */
  consultationId?: number,
  editable?: boolean,
  children?: React.Node,
  style?: any,
  resource: MedicalProfileResource,
  onEditButtonPress?: () => void,
  onItemEdit?: (item: Object) => void,
  onRenderItem?: (item: Object, index: number) => React.Node,
};

/**
 *
 * @param {Props} props Component's properties.
 */
const PaginatedMedicalProfileResource = (props: Props) => {
  const { consultationId, profile, style, resource } = props;

  const { t } = useTranslation();
  const [loadedOnce, setLoadedOnce] = React.useState(false);
  const { data, pagination } = useSelector((state) => ({
    data: state.medical_profile.resources?.[resource] || [],
    pagination: state.medical_profile.resourcePagination?.[resource] || {},
  }));

  const dispatch = useDispatch();
  const patient_id = profile?.patient?.id;

  const apiCaller = React.useCallback(
    (params: Object) =>
      dispatch(
        listMedicalProfileResourceItems({
          patient_id,
          consultation_id: consultationId,
          params: {
            ...params,
            page_size: PAGE_SIZE,
          },
          resource: resource,
        }),
      ),
    [dispatch, patient_id, consultationId, resource],
  );

  const { isLoading, reloadData, loadPage } = useDataLoader(apiCaller, null, {
    disableAutoLoad: true,
  });

  React.useEffect(() => {
    
      reloadData();
    
  }, [reloadData, loadedOnce]);

  return (
    <div style={styles.accordionEmptyContainer}>
      <div className={"container profile-header"}>
        <h1 className={'profile-title'}>{props?.title}</h1>
        <Button
          type="primary"
          ghost={true}
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={() => {
            if (props.onItemEdit) {
              props.onItemEdit({});
            }
          }}>
          {props.addNewButtonTitle}
        </Button>
      </div>

      {data.length === 0 && isLoading ? (
        <LoadingIndicator style={styles.loader} />
      ) : null}
      {data.length === 0 && !isLoading ? (
        <Text category={'p1'} style={styles.text}>
          {props.emptyStateMessage != null && props.emptyStateMessage !== ''
            ? props.emptyStateMessage
            : t('No data available')}
        </Text>
      ) : null}
      {data.map((item: Object, index: number) => {
        return (
          <div key={String(item.id)}>
            {index === 0 && <Divider />}
            {props.onRenderItem != null
              ? props.onRenderItem(item, index)
              : null}
            <Divider />
          </div>
        );
      })}
      <Paginator
        hideOnSinglePage={true}
        pageSize={PAGE_SIZE}
        totalDataEntries={pagination.totalDataEntries}
        selectedPage={pagination.page}
        isLoading={isLoading}
        onSelectPage={(p) => loadPage(p)}
      />
    </div>

  );
};

const styles = {
  container: {},
  accordionEmptyContainer: {
    padding: 0,
  },
  loader: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 32,
  },
  addButton: {
    //justifyContent: 'flex-start',
    margin: 16,
    borderWidth: 0,
    backgroundColor: '#E9573D',
  },
  text: {
    marginTop: 16,
    marginBottom: 32,
    marginHorizontal: 16,
  },
};

export default PaginatedMedicalProfileResource;
