import { API_BASE_URL } from '../constants';
import { getFileMeta } from '../api/files';
import jwtUtils from './jwt';
import { translate } from '../locales';

/**
 * Function to get the search params inside a url by its name
 * @param {*} url
 * @param {*} paramName
 * @returns {String} param
 */
export const getSearchParam = (url, paramName) => {
  const stringParam = new URL(url).searchParams.get(paramName);
  if (stringParam === 'true') {
    return true;
  }
  if (stringParam === 'false') {
    return false;
  }
  return stringParam;
};

/**
 * Removes the given element from array.
 * @param {[]} arr
 * @param {any} element
 */
export const removeElementFromArr = (arr, element) => {
  const index = arr.indexOf(element);
  if (index > -1) {
    arr.splice(index, 1);
  }
};

/**
 * Waits for ms milliseconds, aka asynchronously resolves after ms.
 * @param {number} ms
 */
export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Utility function to check if the object provided is empty or not.
 * @param obj { Object }
 * @return { boolean }
 */
export const isEmptyObject = (obj) => {
  if (!obj) {
    throw new Error('Provide an argument to test for empty object.');
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Detects if the web app runs on a mobile device.
 */
export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

/**
 * Accepts an object and returns the same but without the keys that have `undefined` as a value.
 *
 * @param {Object} obj The object to remove the keys of `undefined`.
 * @returns
 */
export const removeKeysWithUndefinedValue = (obj: Object) => {
  let newObj = obj != null ? { ...obj } : {};
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      delete newObj[key];
    }
  });
  return newObj;
};

export class LocalStorageAdapter {
  /**
   *
   * @param {object} options
   * @param {boolean} [options.enabled=true]
   * @param {string} [options.prefix='my-storage']
   * @param {boolean} [options.clear=false]
   */
  constructor({ enabled = true, prefix = 'my-storage/', clear }) {
    this.opts = {
      enabled,
      prefix,
      clear
    };

    if (this.opts.clear) {
      localStorage.clear();
    }
  }

  clear() {
    localStorage.clear();
  }

  set(key, value) {
    if (!this.opts.enabled) {
      return null;
    }

    // validate payload
    if (!key || typeof key !== 'string') {
      throw new Error('Could not execute \'set\' on localStorage: Key should be string');
    }

    key = this.prefix + key;

    try {
      localStorage.setItem(key, JSON.stringify(value));
      return value;
    } catch (error) {
      throw new Error('Error with local storage');
    }
  }

  get(key, defaultValue = undefined) {
    if (!this.opts.enabled) {
      return defaultValue;
    }

    // validation
    if (!key || typeof key !== 'string') {
      throw Error('Could not execute \'set\' on localStorage: Key should be string');
    }

    key = this.prefix + key;

    try {
      const res = localStorage.getItem(key);
      if (!res) {
        return defaultValue;
      }
      return JSON.parse(res);
    } catch (error) {
      throw new Error('An error occurred with local storage');
    }
  }
}

export const objectToArray = (obj = {}) => {
  if (isEmptyObject(obj)) {
    throw new Error('Object provided is empty');
  }
  return Object.values(obj).map(item => item)
}

/**
 * Parses a string to number. If the string is not a number, will return `undefined`.
 *
 * @param {string} num The string to parse.
 * @param {number} [radix] A value between 2 and 36 that specifies the base of the number in numString. If this argument is not supplied, strings with a prefix of '0x' are considered hexadecimal. All other strings are considered decimal.
 * @returns {?number}
 */
export const safeParseInt = (num: string, radix?: number): ?number => {
  let res;
  if (num != null) {
    try {
      const tmpParse = parseInt(num, 10);
      if (!Number.isNaN(tmpParse)) {
        res = tmpParse;
      }
    } catch (error) { }
  }
  return res;
};

/**
 * Parses a string to number. If the string is not a number, will return `undefined`.
 *
 * @param {string} num The string to parse.
 * @param {number} replaceComma When `true`, the function will replace commas in `num`. Example "32,3" will be "32.3".
 * @returns {?number}
 */
export const safeParseFloat = (
  num: string,
  replaceComma: boolean = true,
): ?number => {
  let res;
  if (num != null) {
    try {
      const tmpParse = replaceComma
        ? parseFloat(num.replace(',', '.'))
        : parseFloat(num);
      if (!Number.isNaN(tmpParse)) {
        res = tmpParse;
      }
    } catch (error) { }
  }
  return res;
};

/** Returns the full name of a given user.
*
* @param {User} [user] The user to get the full name from.
* @returns {string} The user's full name or an empty string if user is null or undefined.
*/
export const getUserFullname = (user?: User): string =>
  `${user?.first_name || ''} ${user?.last_name || ''}`.trim();

/**
 * Return consultation status and consultation text tag based on status
 * @param {*} status of the consultation
 * @param {*} t  the translation method
 */
export const getConsultationStatusInfo = (status: string, t: any) => {
  let statusTag;
  let textTag;
  switch (status) {
    case 'missed':
      statusTag = 'danger';
      textTag = t('Did not happen');
      break;
    case 'rejected':
      statusTag = 'danger';
      textTag = t('Cancelled by doctor');
      break;
    case 'cancelled':
      statusTag = 'danger';
      textTag = t('You cancelled the call');
      break;
    case 'completed':
      statusTag = 'success';
      textTag = t('Report has been received');
      break;
    case 'finished':
      statusTag = 'warning';
      textTag = t('No report sent');
      break;
    case 'in_progress':
      statusTag = 'success';
      textTag = t('In progress');
      break;
    default:
      statusTag = '';
      textTag = '';
  }

  const statusInfo = {
    statusTag,
    textTag
  };
  return statusInfo;
};

/**
 * Checks if the public url's token is expired in order to fetch a fresh one
 * and display a file. In case of pdf files, Android requires a redirection
 * to google docs viewer in order to display the pdf inline in browser
 * @param file The file object
 * @returns {Promise} A promise with the proper url to use for the file display
 */
export const getUrlForFileDisplay = async (file: File) => {
  let url = file.public_url || file.file;
  if (url && url.startsWith(API_BASE_URL)) {
    const token = url.split('/').pop();
    if (jwtUtils.isValidJwtToken(token) && jwtUtils.isTokenExpired(token)) {
      try {
        const res = await getFileMeta(file.id);
        if (res.data?.public_url || res.data?.file) {
          url = res.data.public_url || res.data.file;
        }
      } catch (e) { }
    }
  } else if (url && !url.startsWith('http') && file?.blob) {
    // it's a local file
    url = URL.createObjectURL(file.blob);
  }
  return Promise.resolve(url);
};

/**
 * Checks whether or not a given file has a valid public url.
 * Will return false when the file has:
 *
 * 1. a public_url
 * 2. the url is in the format the has a JWT token on the url
 * 3. the token is not expired
 *
 * @param {*} file
 * @returns
 */
export const fileHasValidPublicUrl = (file: File): boolean => {
  let hasValidPublicUrl = true;
  const url = file.public_url;
  if (url && url.startsWith(API_BASE_URL)) {
    const token = url.split('/').pop();
    if (jwtUtils.isValidJwtToken(token) && jwtUtils.isTokenExpired(token)) {
      hasValidPublicUrl = false;
    }
  }

  return hasValidPublicUrl;
};

export const isFinishedSuccessfullyConsultation = (
  consultation: Consultation,
) => {
  return ['finished', 'completed'].includes(consultation?.status);
};

/**
 * Finds the city for of a specific doctor. If doctor doesn't have one, returns
 * empty string.
 * @param {*} doctor The doctor to get the city from.
 */
export const getCityStringForDoctor = (doctor: Doctor): string => {
  if (doctor?.city == null) {
    return '';
  }
  return translate('Municipality {{text}}', {
    text: doctor?.city?.municipality,
  });
};

/**
 * Converts a given string into a searchable string.
 * Meaning that the final string will be lower case
 * and without accents.
 *
 * @param {string} str The string to convert.
 * @returns The converted string.
 */
export const searchableString = (str) => {
  const map = {
    'α': 'ά',
    'ε': 'έ',
    'η': 'ή',
    'ι': 'ί|ϊ|ΐ',
    'ο': 'ό',
    'υ': 'ύ|ϋ|ΰ',
    'ω': 'ώ',
    'a': 'á|à|ã|â|ä|À|Á|Ã|Â|Ä',
    'e': 'é|è|ê|ë|É|È|Ê|Ë',
    'i': 'í|ì|î|ï|Í|Ì|Î|Ï',
    'o': 'ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö',
    'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    'c': 'ç|Ç',
    'n': 'ñ|Ñ'
  };

  let finalStr = str.toLocaleLowerCase();
  for (let pattern in map) {
    finalStr = finalStr.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return finalStr;
}
