// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components imports
import { Button, Modal, Typography, Row, Col } from 'antd';

import AppStoreIcon from '../../../assets/icons/App-Store-Icon.svg';
import GooglePlayIcon from '../../../assets/icons/Google-Play-Icon.svg';

import AppStoreIconEL from '../../../assets/icons/app-store-el.svg';
import GooglePlayIconEL from '../../../assets/icons/google-play-el.svg';

import { APP_STORE_APP_URL, GOOGLE_PLAY_APP_URL } from '../../../constants';
import { isMobile } from '../../../utils';

type Props = {};

const { Title } = Typography;
const baseClassName = 'home-screen';

const DownloadAppsModal = (props: Props): React$Element<React$FragmentType> => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(isMobile);

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      destroyOnClose={true}
      width={831}
      bodyStyle={styles.body}
      footer={null}
      onCancel={() => setVisible(false)}>
      <div style={styles.contentContainer}>
        {/* MOBILE APP SECTION */}
        <div className={`container-fluid ${baseClassName}__stores text-center`}>
          <Row justify="center">
            <Col>
              <Title level={5}>{t('Download the mobile application')}</Title>
              <p style={styles.paragraph}>
                {t(
                  'For a better experience, it is recommendend to download the mobile app.',
                )}
              </p>
              <Row gutter={6}>
                <Col xl={12} lg={12} xs={12} md={12}>
                  <a
                    href={APP_STORE_APP_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      className="mr-sm-2 mr-md-3 mb-3 w-100"
                      src={
                        i18n.language.includes('el')
                          ? AppStoreIconEL
                          : AppStoreIcon
                      }
                      alt="appstore-icon"
                    />
                  </a>
                </Col>
                <Col xl={12} lg={12} xs={12} md={12}>
                  <a
                    href={GOOGLE_PLAY_APP_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <img
                      className="mb-3 w-100"
                      src={
                        i18n.language.includes('el')
                          ? GooglePlayIconEL
                          : GooglePlayIcon
                      }
                      alt="googleplay-icon"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* MOBILE APP SECTION */}

        <div style={styles.buttonContainer}>
          <Button
            htmlType="submit"
            onClick={() => setVisible(false)}
            style={styles.actionButton}>
            {t('Not now')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  title: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  body: {
    background: '#F5F5F5',
    borderRadius: '8px 8px 8px 8px',
    paddingTop: '0px',
  },
  contentContainer: {
    maxWidth: '451px',
    margin: 'auto',
  },
  paragraph: {
    marginBottom: '40px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButton: {
    flexGrow: 1,
    maxWidth: '360px',
  },
};

export default DownloadAppsModal;
