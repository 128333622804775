// @flow
import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useRoomState,
  useVideoContext,
} from '../../../../plugins/video_call/hooks';

import VideoTrack from '../../VideoTrack/VideoTrack';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
import ToggleAudioButton from '../../ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../ToggleVideoButton/ToggleVideoButton';

type Props = {
  onJoin: () => void,
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
  },
  preview: {
    width: '300px',
    maxHeight: '200px',
    marginBottom: '12px',
    backgroundColor: 'red',

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '4px',
    background: 'black',
  },
  previewInfoContainer: {
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    height: '100%',
    width: '100%',
    background: 'transparent',
    top: 0,
  },
  video: {
    maxHeight: '200px',
  },
  audioIndicator: {
    display: 'flex',
    padding: '6px',
    background: 'rgba(0, 0, 0, 1)',
  },
};

const WaitingRoom = (props: Props): React$Element<React$FragmentType> => {
  const { t } = useTranslation();
  const roomState = useRoomState();
  const {
    isAcquiringLocalTracks,
    isConnecting,
    localTracks,
  } = useVideoContext();
  const localVideoTrack = localTracks.find((track) => track.kind === 'video');
  const localAudioTrack = localTracks.find((track) => track.kind === 'audio');

  return (
    <div style={styles.container}>
      {roomState === 'disconnected' ? (
        <>
          <div style={styles.preview}>
            <VideoTrack
              isLocal
              track={localVideoTrack}
              videoStyle={styles.video}
            />
            <div style={styles.previewInfoContainer}>
              <div style={styles.audioIndicator}>
                <AudioLevelIndicator audioTrack={localAudioTrack} />
              </div>
            </div>
          </div>
          <div style={styles.controls}>
            <ToggleAudioButton disabled={isAcquiringLocalTracks} />
            <ToggleVideoButton disabled={isAcquiringLocalTracks} />
          </div>
          <Button
            loading={isConnecting}
            disabled={isConnecting}
            type={'primary'}
            onClick={props.onJoin}>
            {isConnecting ? t('Connecting…') : t('Join video call')}
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default WaitingRoom;
