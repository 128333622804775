// @flow
import React, { useEffect, useState } from 'react';
import { Button, Modal, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCurrentConsultation } from '../../hooks/useCurrentConsultation';
import { useConsultationParticipants } from '../../hooks/useConsultationParticipants';
import { useRoomState, useVideoContext } from '../../plugins/video_call/hooks';
// $FlowFixMe
import Room from '../../components/video_call/Room/Room';
// $FlowFixMe
import ReconnectingNotification from '../../components/video_call/ReconnectingNotification/ReconnectingNotification';
// $FlowFixMe
import UnsupportedBrowserWarning from '../../components/video_call/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
// $FlowFixMe
import MediaErrorAlert from '../../components/video_call/pre_join/MediaErrorAlert/MediaErrorAlert';
// $FlowFixMe
import WaitingRoom from '../../components/video_call/pre_join/WaitingRoom/WaitingRoom';

import { NAV_VISITS } from '../../constants';

type Props = {
  match: {
    params: {
      id: number,
    },
  },
};

const baseClassName = 'VisitsSessionScreen';

const VisitsSessionScreen = (props: Props): React$Element<any> => {
  const { id } = props.match.params;
  const { t } = useTranslation();
  const { consultation, loading } = useCurrentConsultation({
    id,
    refresh: true,
  });
  const { me } = useConsultationParticipants(consultation);
  const [mediaError, setMediaError] = useState();
  const [switchRoomAlertVisible, setDifferentRoomAlertVisible] = useState();
  const roomState = useRoomState();
  const { room, connect, getAudioAndVideoTracks, connectionInfo } = useVideoContext();

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch((error) => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  useEffect(() => {
    if (
      roomState === 'connected' &&
      !switchRoomAlertVisible &&
      connectionInfo &&
      `${connectionInfo?.consultation?.id}` !== `${id}`
    ) {
      setDifferentRoomAlertVisible(true);
      Modal.warning({
        title: t('Video call has changed'),
        content: t('You are already connected to a video call but trying to connec to a different one. Old connection will be terminated.'),
        onOk: () => {
          setDifferentRoomAlertVisible(false);
          room.disconnect();
        },
      });
    }
  }, [switchRoomAlertVisible, roomState, connectionInfo, id, room, t]);

  const waitingRoom =
    roomState === 'disconnected' &&
    ['new', 'in_progress'].includes(consultation?.status);
  if (!consultation && !loading) {
    return (
      <Result
        status="404"
        title="404"
        subTitle={t('Sorry, the page you visited does not exist.')}
        extra={
          <Link to={{ pathname: '/' }}>
            <Button type="primary">{t('Home')}</Button>
          </Link>
        }
      />
    );
  }
  return (
    <div className={`${baseClassName}__video`}>
      <UnsupportedBrowserWarning>
        <MediaErrorAlert error={mediaError} />
        <ReconnectingNotification />
        {roomState === 'connected' ? (
          <Room />
        ) : waitingRoom ? (
          <div className={`${baseClassName}__waiting-room`}>
            <WaitingRoom onJoin={() => connect(me?.token, { consultation }) } />
          </div>
        ) : (
          <Result
            title={t('Video call for this visit is not available')}
            extra={
              <Link
                to={{ pathname: `${NAV_VISITS}/${consultation?.id || ''}` }}>
                <Button type="primary">
                  {t('Go to visit\'s details page')}
                </Button>
              </Link>
            }
          />
        )}
      </UnsupportedBrowserWarning>
    </div>
  );
};

export default VisitsSessionScreen;
