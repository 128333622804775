// @flow
import React from 'react';
import { Participant } from 'twilio-video';
import useParticipantNetworkQualityLevel from '../../../plugins/video_call/hooks/useParticipantNetworkQualityLevel';

const divStyle = {
  display: 'flex',
  alignItems: 'flex-end',
};

const STEP = 3;
const BARS_ARRAY = [0, 1, 2, 3, 4];

type Props = {
  participant: Participant,
};

export default function NetworkQualityLevel({ participant }: Props) {
  let networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  networkQualityLevel = 3;
  if (networkQualityLevel === null) {
    return null;
  }

  return (
    <div style={divStyle}>
      {BARS_ARRAY.map((level) => (
        <div
          key={level}
          style={{
            marginRight: '1px',
            width: '2px',
            height: `${STEP * (level + 1)}px`,
            background:
              networkQualityLevel > level
                ? 'white'
                : 'rgba(255, 255, 255, 0.2)',
          }}
        />
      ))}
    </div>
  );
}
