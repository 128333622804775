import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useConsultationActions } from '../../hooks/useConsultationActions';
import moment from 'moment';

//Components imports
import { Row, Col, PageHeader, Button, Spin, Typography } from 'antd';
import InfoCard from '../../components/InfoCard';
import ConsultationInfo from '../../components/ConsultationInfo';
import FilesManager from '../../components/filesManager/FilesManager';
import NewLineText from '../../components/NewLineText';
import { LeftOutlined, VideoCameraOutlined, InfoCircleOutlined } from '@ant-design/icons';

//Misc imports
import message from '../../utils/message';
import { getCalculatedStatusForAppointment } from '../../utils/appointments';
import { NAV_APPOINTMENTS, NAV_APPOINTMENTS_DOCTORS_SINGLE, NAV_VISITS_SINGLE } from '../../constants';
import { getAppointmentById, getLocalAppointment } from '../../store/slices/appointmentsSlice';
import { openAppointmentInvalidationModal } from '../../store/slices/applicationSlice';
import { unwrapResult } from '@reduxjs/toolkit';

//const declaration
const { Text, Title } = Typography;
const baseClassName = 'appointments-single';

const AppointmentsScreenSingle = (props) => {
  const { match } = props;
  const { id } = match?.params;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const appointment = useSelector(getLocalAppointment(id));

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const status = getCalculatedStatusForAppointment(appointment);

  let appointmentIsNow = null;
  let shouldCallDoctorNow = null;
  let shouldJoinConsultation = null;
  let canCancel = null;
  let hasFinishedWithConsultation = null;
  let doctorCancelled = null;
  let appointmentWillNotHappen = null;

  appointmentIsNow =
    status === 'confirmed' &&
    moment().isSameOrAfter(appointment?.start) &&
    moment().isSameOrBefore(appointment?.max_call_availability);
  shouldCallDoctorNow =
    appointmentIsNow && appointment?.type === 'online';
  shouldJoinConsultation = status === 'in_progress';
  canCancel =
    (appointment?.status === 'confirmed' || appointment?.status === 'new') &&
    moment().isBefore(appointment?.start);
  hasFinishedWithConsultation =
    status === 'finished' && appointment?.consultation != null;
  doctorCancelled =
    appointment?.status === 'invalidated' || appointment?.status === 'rejected';

  appointmentWillNotHappen =
    appointment?.status === 'cancelled' ||
    appointment?.status === 'invalidated' ||
    appointment?.status === 'rejected';

  const renderCallDoctorNow = () => {
    if (shouldCallDoctorNow) {
      return (
        <div>
          <Button
            type="primary"
            icon={<VideoCameraOutlined />}
            loading={isStartingCall}
            onClick={(e) => {
              e.preventDefault();
              startCallForAppointment(appointment);
            }}>
            {t('Call now the doctor')}
          </Button>
        </div>
      )
    }
    return null;
  }

  const renderJoinConsultation = () => {
    if (shouldJoinConsultation) {
      return (
        <Button
          type="secondary"
          icon={<VideoCameraOutlined />}
          loading={isJoiningCall}
          onClick={(e) => {
            e.preventDefault();
            joinConsultationAction(appointment?.consultation?.id)
          }}>
          {t('Join the call')}
        </Button>
      )
    }
    return null;
  }

  const renderGoToConsultation = () => {
    if (hasFinishedWithConsultation) {
      return (
        <Button
          type="secondary"
          loading={isJoiningCall}
          onClick={(e) => {
            e.preventDefault();
            history.push(NAV_VISITS_SINGLE.replace(':id', appointment?.consultation?.id));
          }}>
          {t('See more about the call')}
        </Button>
      )
    }
    return null;
  }

  const renderBookNewAppointment = () => {
    if (doctorCancelled) {
      return (
        <Button
          type="secondary"
          loading={isJoiningCall}
          onClick={(e) => {
            e.preventDefault();
            const docId = appointment?.doctor.id;
            history.push(NAV_APPOINTMENTS_DOCTORS_SINGLE.replace(':id', docId));
          }}>
          {t('Book new appointment')}
        </Button>
      )
    }
    return null;
  }

  const {
    isStartingCall,
    isJoiningCall,
    startCallForAppointment,
    joinConsultationAction,
  } = useConsultationActions();

  // ======================================================
  // USE-EFFECTS
  // ======================================================
  useEffect(() => {
    if (id != null) {
      setIsLoading(true);
      (async () => {
        try {
          await dispatch(getAppointmentById(id)).then(unwrapResult);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          message.error(err.message);
          setIsLoading(false);
          setHasError(true);
        }

      })();
    }
  }, [id, dispatch]);

  return (
    <>
      {appointmentIsNow && (
        <div className={`${baseClassName}__alert-banner`}>
          <InfoCircleOutlined />
          <Text>{t('Your appointment is starting. Call the doctor now.')}</Text>
        </div>
      )}
      <div className={`${baseClassName} container`}>
        <Row align="center">
          <Col lg={16} md={24}>
            <Row>
              <Col>
                <div className={`${baseClassName}__header-title`}>
                  <PageHeader
                    title={t('Visit details')}
                  >
                    <Button
                      type="link"
                      icon={<LeftOutlined />}
                      onClick={() => history.push(NAV_APPOINTMENTS)}
                    >
                      {t('Appointments')}
                    </Button>
                  </PageHeader>
                </div>
              </Col>
            </Row>
            {isLoading && !appointment && <div className="text-center"><Spin /></div>}
            {
              hasError
                ? <Text type="danger">{t('Could not fetch the appointment')}</Text>
                : <>
                  <Row className="mb-5">
                    <Col>
                      <InfoCard
                        item={appointment}
                        headerOnly
                        linkTo={
                          appointment
                            ? NAV_APPOINTMENTS_DOCTORS_SINGLE.replace(':id', appointment?.doctor?.id)
                            : '#'
                        }
                      />
                      <ConsultationInfo
                        item={appointment}
                        isAppointment
                        hideActions
                      />
                    </Col>
                    <Row>
                      <Col>
                        {renderCallDoctorNow()}
                        {renderJoinConsultation()}
                        {renderGoToConsultation()}
                        {renderBookNewAppointment()}
                      </Col>
                      <Col>
                        {canCancel && (
                          <Button
                            type="secondary"
                            onClick={() => dispatch(openAppointmentInvalidationModal({ appointment }))}
                          >
                            {t('I would like to cancel the appointment')}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Row>
                  {appointmentWillNotHappen && (
                    <Row className={`${baseClassName}__section`}>
                      <Col>
                        <Title level={4}>{t('Cancellation reason')}</Title>
                        <Text>
                          {appointment?.response_message
                            ? <NewLineText text={appointment?.response_message} />
                            : t('You have not provided any description')}
                        </Text>
                      </Col>
                    </Row>
                  )}
                  <Row className={`${baseClassName}__section`}>
                    <Col>
                      <Title level={4}>{t('Symptoms description')}</Title>
                      <Text>
                        {appointment?.description
                          ? <NewLineText text={appointment?.description} />
                          : t('You have not provided any description')}
                      </Text>
                      {appointment?.files?.length > 0 && (
                        <FilesManager
                          className={`${baseClassName}__files`}
                          files={appointment?.files}
                        />
                      )}
                    </Col>
                  </Row>
                </>
            }
          </Col>
        </Row>
      </div>
    </>
  )
}

export default AppointmentsScreenSingle;
