
// @flow
import * as React from 'react';
import {
  Button,
  Popconfirm,
  Spin,
  Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMedicalProfileResourceItem,
  deleteMedicalProfileResourceItem,
} from '../../store/slices/medicalProfileSlice';
import MPEditReportItemPreview from './MPEditReportItemPreview';
import MPEditReportItemAddNew from './MPEditReportItemAddNew';
import type { Consultation } from '../../config/types';
import type { MPReport } from '../../config/types-mp';
import { composeStyles } from '../../utils/styling'
import message from '../../utils/message';
import { unwrapResult } from '@reduxjs/toolkit';

type Props = {
  onCloseModal: Function,
  dataItem: Object,
  consultationId?: number,
  report?: MPReport,
  readOnly?: boolean,
  hideToolbar?: boolean,
  title?: string,
  visible: boolean,
};

const MPEditReportItemScreen = (props: Props) => {
  if (props?.title == null) {
    console.warn(
      'MPEditReportItemScreen expects "title" to be passed as navigation parameters',
    );
  }
  const { t } = useTranslation();
  const report: MPReport = props?.dataItem || {};
  const readOnly: boolean = Boolean(props?.readOnly);
  const hideToolbar: boolean = Boolean(props?.hideToolbar);
  const consultation_id: ?number = props?.consultationId;
  const patient_id = useSelector((state) => state.profile.user.id);
  let consultationSelected;

  const dispatch = useDispatch();

  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();

  const onSave = async (consultation?: Consultation) => {
    try {
      setSaving(true);
      await dispatch(
        createMedicalProfileResourceItem({
          patient_id,
          consultation_id,
          data: { consultation: consultation?.id },
          resource: 'reports',
        }),
      ).then(unwrapResult);
      props.onCloseModal(false);
    } catch (error) {
      setSaving(false);
      message.error(error.message);
    }
  };

  const onDelete = () => {
    try {
      setDeleting(true);
      if (report.id != null) {
        dispatch(
          deleteMedicalProfileResourceItem({
            patient_id,
            consultation_id,
            itemId: report.id,
            resource: 'reports',
          }),
        ).then(unwrapResult)
        .then(() => {
          props.onCloseModal(false);
        });
      }
    } catch (error) {
      setDeleting(false);
      message.error(error.message);
    }
  };

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      bodyStyle={styles.modalBackground}
      centered={true}
      maskClosable={false}
      title={t('Consultation reports')}
      className={'profile-modal'}
      okText={t('Save')}
      cancelText={t('Cancel')}
      width={800}
      onOk={() => onSave(selectedItem)}
      onCancel={() => props.onCloseModal(!props.visible)}
      visible={props.visible}
      okButtonProps={{ disabled: selectedItem == null }}
    >
      <div
        style={composeStyles([
          styles.container,
          report.consultation == null && { backgroundColor: '#ffffff' },
        ])}>
        {report.consultation == null ? (
          <MPEditReportItemAddNew
            saving={saving}
            onSaveConsultationReport={(consultation) => {
              setSelectedItem(consultation);
            }}
          />
        ) : (
          <MPEditReportItemPreview consultation={report.consultation}>
            {!readOnly ? (
              <Popconfirm
                placement="bottom"
                title={t('Are you sure you want to delete this entry?')}
                onConfirm={onDelete}
                okText={t('Yes')}
                cancelText={t('No')}>
                <Button
                  loading={deleting}
                  size={'middle'}
                  style={styles.deleteButton}>
                  {t('Delete report from medical profile')}
                </Button>
              </Popconfirm>
            ) : null}
          </MPEditReportItemPreview>
        )}
      </div>
    </Modal>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  toolbar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  deleteButton: {
    alignSelf: 'center',
    marginVertical: 24,
  },
  modalBackground: {
    backgroundColor: 'rgba(72, 72, 72, 0.1)'
  }
};

export default MPEditReportItemScreen;
