import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInterval } from '../../../hooks/useInterval';
import { useOutgoingConsultation } from '../../../hooks/useOutgoingConsultation';
import { useAudio } from '../../../hooks/useAudio';
import { clearOutgoingConsultation, updateConsultationWithData } from '../../../store/slices/consultationSlice';
import { getConsultation } from '../../../api/consultation';
import { NAV_VISITS_SESSION } from '../../../constants';
import message from '../../../utils/message';
import TeledoctorLogo from '../../../assets/images/logos/teledoctor-logo.png';
import outgoingSound from '../../../assets/sounds/outgoing_call.mp3';

// Components imports
import { Button, Modal, Typography } from 'antd';
import { unwrapResult } from '@reduxjs/toolkit';

type Props = {
  visible?: boolean,
  onCancel?: (e: any) => void,
};

const baseClassName = 'OutgoingCallModal';

const OutgoingCallModal = (
  props: Props,
): React$Element<React$FragmentType> => {
  const { visible } = props || {};
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { consultation } = useOutgoingConsultation();
  const { play, stop } = useAudio(outgoingSound);

  const onCancelConsultation = useCallback(async () => {
    try {
      await dispatch(updateConsultationWithData({
        id: consultation.id,
        status: 'cancelled',
      })).then(unwrapResult);
    } catch (e) {
      message.error(e.message);
    }
  }, [consultation, dispatch]);

  const check = useCallback(async () => {
    if (consultation?.id && visible) {
      try {
        let res = await getConsultation(consultation.id);
        let doctor = res.data.participants?.find(
          (item) => item.type === 'doctor',
        );
        // let doctor = getParticipantOfTypeFromConsultation(res, 'doctor');
        if (res.data.status === 'in_progress' || doctor?.status === 'joined') {
          history.push(`${NAV_VISITS_SESSION}/${res.data.id}`)
        } else {
          if (doctor?.status === 'rejected') {
            message.error(t('The doctor is busy. Please call again in a few minutes.'));
          } else if (doctor?.status === 'missed') {
            message.error(t('All doctors are busy. Please call again in a few minutes.'));
          }

          const hasFinished = [
            'rejected',
            'missed',
            'finished',
            'cancelled'
          ].includes(doctor?.status);
          if (hasFinished) {
            dispatch(clearOutgoingConsultation());
          }
        }

        res = null;
        doctor = null;
      } catch (error) {
        console.log(error);
      }
    }
  }, [visible, consultation, history, dispatch, t]);

  useInterval(check, 2500);

  useEffect(() => {
    if (visible) {
      play();
    } else {
      stop();
    }
  }, [visible, play, stop]);

  return (
    <Modal
      title={
        <Typography.Title level={5} style={styles.title}>
          {t('Call a doctor')}
        </Typography.Title>
      }
      centered
      closable={false}
      visible={visible}
      destroyOnClose={true}
      width={831}
      bodyStyle={styles.body}
      footer={null}
      onCancel={props.onCancel}>
      <div className={`${baseClassName}__container`}>
        <div className={'content-container'}>
          <div className={'outter-circle'}>
            <div className={'middle-circle'}>
              <div className={'inner-circle'}>
                <img src={TeledoctorLogo} alt="teledoctor-logo" />
              </div>
            </div>
          </div>
          <Typography.Title level={5} style={styles.contentTitle}>
            {t('Call to a My e-Doctor')}
          </Typography.Title>
        </div>

        <div style={styles.buttonContainer}>
          <Button
            type="primary"
            htmlType="submit"
            style={styles.actionButton}
            onClick={onCancelConsultation}>
            {t('Cancel call')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  title: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  body: {
    background: '#F5F5F5',
    borderRadius: '0 0 8px 8px',
  },
  contentTitle: {
    marginTop: '24px',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButton: {
    flexGrow: 1,
    maxWidth: '360px',
    marginTop: '60px',
    marginBottom: '24px',
  },
};

export default OutgoingCallModal;
