// @flow
import * as React from 'react';
import type { Consultation } from '../../config/types';
import VisitsScreenSingle from '../../screens/VisitsScreenSingle';

type Props = {
  consultation?: Consultation,
  children?: React.Node,
};

const MPEditReportItemPreview = (props: Props) => {

  return (
    <VisitsScreenSingle shouldNotGoBack={true} match={{ params: { id: props.consultation?.id } }}>
      {props.children}
    </VisitsScreenSingle>
  );
};

export default MPEditReportItemPreview;
