// @flow
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getConsultationById } from '../store/commonAsyncThunks';
import useVideoContext from '../plugins/video_call/hooks/useVideoContext';
import { unwrapResult } from '@reduxjs/toolkit';

/**
 * Monitors the participants' list of the in-progress call (if any)
 * and updates the consultation when changed.
 * This will update the information about the other participants
 */
export const useParticipantsChangeHandler = () => {
  const dispatch = useDispatch();
  const { room, connectionInfo } = useVideoContext();
  const [participants, setParticipants] = useState(
    Array.from(room?.participants.values() ?? []),
  );

  const consultationId = connectionInfo?.consultation?.id;

  useEffect(() => {
    if (room) {
      const participantConnected = (participant) =>
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
      const participantDisconnected = (participant) =>
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant),
        );
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  useEffect(() => {
    const refetchConsultation = async () => {
      try {
        const response = await dispatch(getConsultationById(consultationId));
        const data = unwrapResult(response);
        console.log(data);
      } catch (error) {
        console.log('error', error);
      }
    };

    if (consultationId) {
      refetchConsultation();
    }
  }, [participants, consultationId, dispatch]);
};
