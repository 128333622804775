// @flow
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components imports
import {
  Row,
  Col,
  PageHeader,
  Typography,
  Button,
  Divider,
  Spin,
} from 'antd';
import InfoCard from '../../components/InfoCard';
import ConsultationInfo from '../../components/ConsultationInfo';
import { LeftOutlined, VideoCameraOutlined } from '@ant-design/icons';
import FilesManager from '../../components/filesManager/FilesManager';
import NewLineText from '../../components/NewLineText';

// Misc imports
import { safeParseInt } from '../../utils';
import { useCurrentConsultation } from '../../hooks/useCurrentConsultation';
import { createNewConsultation } from '../../store/slices/consultationSlice';
import { NAV_APPOINTMENTS_DOCTORS_SINGLE, NAV_VISITS } from '../../constants';
import message from '../../utils/message';
import { unwrapResult } from '@reduxjs/toolkit';

// const declarations
const { Title, Text } = Typography;
const baseClassName = 'visits-single';

type Props = {
  match: {
    params: { id?: string },
    isExact: boolean,
    path: string,
    url: string,
  },
  shouldNotGoBack: boolean,
  children: React.Node,
};

const PageContainer = (props: { children: any, shouldNotGoBack: boolean }): React$Element<'div'> => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className={`${baseClassName} container`}>
      <Row align="center">
        <Col lg={16} md={24}>
          <Row>
            <Col>
              <div className={`${baseClassName}__header-title`}>
                <PageHeader title={t('Visit details')}>
                  {
                    !props.shouldNotGoBack ?
                      <Button
                        type="link"
                        icon={<LeftOutlined />}
                        onClick={() => history.push(NAV_VISITS)}
                      >
                        {t('Visits')}
                      </Button> : null
                  }
                </PageHeader>
              </div>
            </Col>
          </Row>
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

const VisitsScreenSingle = (props: Props): React$Element<any> => {
  const { id } = props.match?.params;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { consultation: currentConsultation, loading } = useCurrentConsultation(
    {
      id: safeParseInt(id),
      refresh: true,
      fallbackToLocalSearch: true,
    },
  );

  const [isCreating, setIsCreating] = useState(false);
  const consultationDidHappen = [
    'completed',
    'finished',
    'in_progress',
  ].includes(currentConsultation?.status);
  const consultationDidNotHappen = ['rejected', 'cancelled', 'missed'].includes(
    currentConsultation?.status,
  );

  if (!currentConsultation) {
    return (
      <PageContainer shouldNotGoBack={props.shouldNotGoBack}>
        {loading && (
          <div className="text-center">
            <Spin />
          </div>
        )}
      </PageContainer>
    );
  }

  const doctor = currentConsultation.doctor;
  return (
    <PageContainer shouldNotGoBack={props.shouldNotGoBack}>
      <Row className="mb-5">
        <Col>
          <InfoCard
            item={currentConsultation}
            headerOnly
            linkTo={
              typeof doctor === 'object'
                ? NAV_APPOINTMENTS_DOCTORS_SINGLE.replace(':id', doctor?.id)
                : '#'
            }
          />
          <ConsultationInfo item={currentConsultation} />
        </Col>
      </Row>
      {consultationDidHappen ? (
        <>
          {/* Report */}
          <Row className="mb-5">
            <Col>
              <Title level={4}>{t('Doctors report')}</Title>
              {currentConsultation?.status === 'completed' ? (
                <>
                  <Text className="small" type="secondary">
                    {t('Received') + ' '}
                    {moment(currentConsultation?.completed_at).format(
                      'D/M/YYYY, h:mm:ss a',
                    )}
                  </Text>
                  <Divider className="mt-0 mb-3" />
                </>
              ) : (
                <Text>{t('Report has not been send')}</Text>
              )}
              {currentConsultation?.report ? (
                <Text>
                  <NewLineText text={currentConsultation.report} />
                </Text>
              ) : null}
              {Boolean(currentConsultation.report_files?.length) === true && (
                <div className="files-gallery">
                  <FilesManager files={currentConsultation?.report_files} />
                </div>
              )}
            </Col>
          </Row>
          {/* End of Report */}

          {/* Symptoms - Conditions */}
          {Boolean(currentConsultation?.conditions?.length) === true && (
            <Row className="mb-5">
              <Col>
                <Title level={4}>{t('Symptoms - Conditions')}</Title>
                <ul>
                  {currentConsultation?.conditions?.map((condition) => (
                    <li key={condition.name}>
                      {`${condition?.code || ''} - ${condition?.name || ''}`}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
          {/* End of Symptoms - Conditions */}
        </>
      ) : null}

      {/* Description */}
      <Row className="mb-5">
        <Col>
          <div>
            <Title level={4}>{t('Symptoms description')}</Title>
            <Text>
              {currentConsultation?.description ? (
                <NewLineText text={currentConsultation.description} />
              ) : (
                t('You have not provided any description')
              )}
            </Text>
          </div>

          {Boolean(currentConsultation?.files?.length) === true && (
            <div className="files-gallery">
              <FilesManager files={currentConsultation?.files} />
            </div>
          )}
        </Col>
      </Row>
      {/* End of Description */}

      {consultationDidNotHappen && (
        <Button
          disabled={isCreating}
          loading={isCreating}
          icon={<VideoCameraOutlined />}
          onClick={async () => {
            const newConsultation = {
              description: currentConsultation?.description,
              allow_doctor_medical_access:
                currentConsultation?.allow_doctor_medical_access,
              files: (currentConsultation?.files || []).map((item) => item.id),
            };
            if (!currentConsultation?.call_center) {
              // $FlowFixMe
              const doctorId = currentConsultation?.doctor?.id;
              if (doctorId) {
                // $FlowFixMe
                newConsultation.doctor = doctorId;
              }
            }
            try {
              setIsCreating(true);
              await dispatch(createNewConsultation(newConsultation)).then(unwrapResult);
              setIsCreating(false);
            } catch (error) {
              setIsCreating(false);
              message.error(error.message);
            }
          }}>
          {t('Call again')}
        </Button>
      )}
      {props.children}
    </PageContainer>
  );
};

export default VisitsScreenSingle;
