import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
// Components import
import { Button } from 'antd';

const LoginButton = () => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  return <Button onClick={() => loginWithRedirect()}>{t('Log in')}</Button>;
};

export default LoginButton;
