// @flow
import * as React from 'react';
import { Divider, Typography } from 'antd';
import { composeStyles } from '../../utils/styling';

type Props = {
  style?: any,
  column1Style?: any,
  column2Style?: any,
  text?: string,
  /**
   * A value to be displayed in the second column of the row.
   * When `children` are provided, this value will be ignored.
   */
  value?: String,
  horizontalLayout?: boolean,
  children?: React.Node,
};

const { Text } = Typography;

/**
 * Renders a row with 2 columns for editing.
 *
 * -----------------------
 * | Text  |  {children} |
 * -----------------------
 *
 * @param {Props} props
 * @returns
 */
const EditRow = (props: Props) => {
  const rowExtra = props.horizontalLayout ? styles.rowHorizontal : {};
  const columnExtra = props.horizontalLayout ? {} : styles.column1Horizontal;
  const column2Extra = props.horizontalLayout ? {} : { paddingRight: 16 };
  return (
    <>
      <div style={composeStyles([styles.row, rowExtra, props.style])}>
        <div style={styles.test}>
          <Text
            category={'s1'}
            style={composeStyles([styles.column1, columnExtra, props.column1Style])}>
            {props.text}
          </Text>
        </div>
        <div style={composeStyles([styles.column2, column2Extra, props.column2Style])}>
          {props.children == null ? (
            <div style={styles.readOnlyTextContainer}>
              <Text style={styles.readOnlyText}>{props.value}</Text>
            </div>
          ) : (
            props.children
          )}
        </div>
      </div>
      <Divider />
    </>
  );
};

const styles = {
  row: {
    marginVertical: 8,
  },
  test: {
    marginBottom: 8,
  },
  rowHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  column1: {
    flex: 2,
    fontWeight: 'bold'
  },
  column1Horizontal: {
    marginBottom: 8,
  },
  column2: {
    flex: 3,
    paddingRight: 8,
  },
  readOnlyTextContainer: {
    minHeight: 40,
    justifyContent: 'center',
  },
  readOnlyText: {},
};

export default EditRow;
