// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components imports
import { Row, Col, PageHeader } from 'antd';
import NotificationList from '../../components/NotificationsList/NotificationsList';

// const declarations
const baseClassName = 'notificationsScreen';

const NotificationsScreen = (): React$Element<'div'> => {
  const { t } = useTranslation();

  return (
    <div className={`${baseClassName} container`}>
      <Row align="center">
        <Col lg={16} md={24}>
          <Row>
            <Col>
              <div className={`${baseClassName}__header-title`}>
                <PageHeader
                  title={t('Notifications')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <NotificationList />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationsScreen;
