// @flow
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components imports
// import LogoutButton from '../LogoutButton';
import UserPopUp from '../UserPopUp';
import NotificationsList from '../NotificationsList';
import { Menu, Badge, Popover, Typography, Grid } from 'antd';
import { UserOutlined, BellOutlined } from '@ant-design/icons';

//Misc imports
import {
  getUnreadNotificationsCount,
  markAllUserNotificationsAsRead,
  markAllNotificationsAsReadLocally,
} from '../../store/slices/notificationsSlice';
import { isEmptyObject } from '../../utils/';
import { MAIN_MENU_ITEMS, NAV_NOTIFICATIONS, NOTIFICATION_MENU_ITEM } from '../../constants';

// Constant declarations
const { useBreakpoint } = Grid;
const { Text } = Typography;
const { SubMenu } = Menu;
const baseClassName = 'main-menu';

type Props = {
  mode?: 'horizontal' | 'inline';
  onItemSelected?: (route?: string) => any,
};
const MainMenu = (props: Props): React$Element<any> => {
  const { t } = useTranslation();
  const location = useLocation();
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const isMobileMenuEnabled = () => {
    if (isEmptyObject(screens)) {
      return false;
    }
    return (!screens.lg && !screens.xl && !screens.xxl);
  }
  const FinalMenuItems = isMobileMenuEnabled() ? [...MAIN_MENU_ITEMS, NOTIFICATION_MENU_ITEM] : MAIN_MENU_ITEMS;
  const [notifsVisible, setNotifsVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState(FinalMenuItems.find(_item => _item.route !== '/' && location.pathname.startsWith(_item.route))?.key || '1');
  const { user } = useSelector(state => state.profile);
  const unreadNotifsCount = useSelector(getUnreadNotificationsCount);

  useEffect(() => {
    setSelectedKey(FinalMenuItems.find(_item => _item.route !== '/' && location.pathname.startsWith(_item.route))?.key || '1');
  }, [location]);

  useEffect(() => {
    if (notifsVisible) {
      if (unreadNotifsCount > 0) {
        dispatch(markAllUserNotificationsAsRead());
      }
    } else {
      dispatch(markAllNotificationsAsReadLocally());
    }
  }, [notifsVisible, dispatch]);

  const onSelectedNotif = () => {
    setNotifsVisible(false);
    props.onItemSelected && props.onItemSelected();
  };

  const handleNotifsVisibleChange = visible => {
    setNotifsVisible(visible);
  };

  return (
    <Menu
      className={`${baseClassName} right`}
      mode={props.mode}
      selectedKeys={selectedKey}
    >
      {
        MAIN_MENU_ITEMS.map(menuItem => (
          <Menu.Item
            key={menuItem.key}
            icon={menuItem.icon}
            className={`${baseClassName}__menu-item`}>
            <Link
              to={menuItem.route}
              onClick={() => props.onItemSelected && props.onItemSelected(menuItem.route)}
            >
              {menuItem.title}
            </Link>
          </Menu.Item>))
      }

      <Menu.Item
        key={(NOTIFICATION_MENU_ITEM.key)}
        className={`${baseClassName}__icon-link ${baseClassName}__with-badge `}
      >
        {
          isMobileMenuEnabled() ?
            (
              <Link
                to={NAV_NOTIFICATIONS}
                onClick={() => props.onItemSelected && props.onItemSelected(NAV_NOTIFICATIONS)}
              >
                <Badge size="small" count={unreadNotifsCount}>
                  <BellOutlined />
                  {<Text className={`${baseClassName}__icon-link-copy`}>{t('Notifications')}</Text>}
                </Badge>
              </Link>
            )
            :
            (
              <Popover
                placement="bottomRight"
                title={t('Notifications')}
                content={<NotificationsList onItemPress={onSelectedNotif} />}
                trigger="click"
                visible={notifsVisible}
                onVisibleChange={handleNotifsVisibleChange}
              >
                <Badge size="small" count={unreadNotifsCount}>
                  <BellOutlined />
                  {isMobileMenuEnabled() && <Text className={`${baseClassName}__icon-link-copy`}>{t('Notifications')}</Text>}
                </Badge>
              </Popover>
            )
        }

      </Menu.Item>

      <SubMenu
        key="sub1"
        className={`${baseClassName}__icon-link`}
        icon={<UserOutlined />}
        title={isMobileMenuEnabled() ? <Text className={`${baseClassName}__icon-link-copy`}>{user?.email}</Text> : null}
      >
        <Menu.Item>
          <UserPopUp />
        </Menu.Item>
      </SubMenu>
    </Menu>
  )
}

export default MainMenu;
