// @flow
import React from 'react';
import { Button, Affix, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ExpandOutlined } from '@ant-design/icons';

import { useCurrentConsultation } from '../../../hooks/useCurrentConsultation';
import { useRoomState, useVideoContext } from '../../../plugins/video_call/hooks';

import MiniPlayer from './MiniPlayer';
// $FlowFixMe
import EndCallButton from '../EndCallButton/EndCallButton';

import { NAV_VISITS_SESSION } from '../../../constants';

type Props = {};

const baseClassName = 'MiniVideoCallPlayer';

const MiniVideoCallPlayer = (props: Props): React$Element<any> | null => {
  const { t } = useTranslation();
  const location = useLocation();
  const { consultation } = useCurrentConsultation();
  const roomState = useRoomState();
  const { connectionInfo } = useVideoContext();

  const consultationId = connectionInfo?.consultation?.id || consultation?.id;

  if (roomState !== 'connected' || location.pathname.startsWith(NAV_VISITS_SESSION) || consultationId == null) {
    return null;
  }

  return (
    <Affix offsetBottom={12}>
      <div className={`${baseClassName} container`}>
        <div className={`${baseClassName}__tracks-container`}>
          <MiniPlayer />
          <div className={`${baseClassName}__controls`}>
            <Tooltip title={t('Back to video call')}>
              <Link to={{
                pathname: `${NAV_VISITS_SESSION}/${consultationId || ''}`
              }}>
                <Button
                  shape="circle"
                  onClick={() => console.log('should navigate to session page')}
                  icon={<ExpandOutlined />}
                />
              </Link>
            </Tooltip>
            <EndCallButton text={null} />
          </div>
        </div>
      </div>
    </Affix>
  )
}

export default MiniVideoCallPlayer;
