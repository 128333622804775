// @flow
import { useEffect, useState } from 'react';
import { LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';

export default function useTrack(
  publication?: LocalTrackPublication | RemoteTrackPublication,
) {
  const [track, setTrack] = useState(publication && publication.track);
  const [enabled, setEnabled] = useState(publication?.track?.isEnabled);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication && publication.track);

    if (publication) {
      const removeTrack = () => setTrack(null);
      const trackEnabled = () => setEnabled(true);
      const trackDisabled = () => setEnabled(false);

      publication.on('subscribed', setTrack);
      publication.on('unsubscribed', removeTrack);
      publication.on('trackEnabled', trackEnabled);
      publication.on('trackDisabled', trackDisabled);
      return () => {
        publication.off('subscribed', setTrack);
        publication.off('unsubscribed', removeTrack);
        publication.off('trackEnabled', trackEnabled);
        publication.off('trackDisabled', trackDisabled);
      };
    }
  }, [publication]);

  return track;
}
