import * as React from 'react';
import {
  Input,
  Typography
} from 'antd';
import { useSelector } from 'react-redux';
import { measurementSelectors } from '../../store/slices/medicalProfileSlice';
import { safeParseFloat } from '../../utils';
import { isValidValue } from '../../utils/validation';
import EditRow from '../editRow/EditRow';
import { colors } from '../../styles';

const isNumericType = (type) => {
  return ['float', 'integer', 'number'].includes(type);
};

const { Text } = Typography;

const MPEditMeasurementValues = React.forwardRef((props, ref) => {
  const { type, values } = props;

  const inputs = React.useRef([]);

  const metadata: Object = useSelector((state) =>
    measurementSelectors.getMeasurementMetadataForValueOfType(
      state.medical_profile,
      type,
    ),
  );

  const focusAtIndex = (index) => {
    if (metadata.fields.length > index) {
      inputs.current[index].focus();
    }
  };
  const validKeys = [];
  const numericTypeKeys = {};
  const initState = {};
  if (Array.isArray(values)) {
    values.forEach((item) => (initState[item.key] = item.value));
  }
  const [inputValues, setInputValues] = React.useState(initState);

  React.useImperativeHandle(ref, () => ({
    getValues: () => {
      const newValidValuesArray = [];
      validKeys?.forEach((k) => {
        const isNumeric = numericTypeKeys[k];
        const val = inputValues[k];
        const newVal = isNumeric ? safeParseFloat(val) || 0 : val;
        newValidValuesArray.push({
          key: k,
          value: newVal !== undefined ? newVal : '',
        });
      });
      return newValidValuesArray;
    },
  }));

  return (
    <div>
      {metadata.fields?.map((item, index) => {
        validKeys.push(item.key);
        const isNumeric = isNumericType(item.type);
        numericTypeKeys[item.key] = isNumeric;
        const { valid, message } = isValidValue(
          inputValues[item.key],
          item.validators,
        );
        return (
          <EditRow
            key={item.key}
            text={`${item.label} (${item.unit})`}
            value={`${inputValues[item.key]} ${item.unit}`}>
            {!props.readOnly ? (
              <>
                <Input
                  className={'fullWidth'}
                  ref={(inputRef) => (inputs.current[index] = inputRef)}
                  style={styles.input}
                  textStyle={styles.inputText}
                  placeholder={item.unit}
                  status={!valid ? 'danger' : null}
                  value={inputValues[item.key]}
                  onChange={(e) => {
                    const text = e.target.value;
                    const newValuesObj = {
                      ...inputValues,
                      [item.key]: text,
                    };
                    setInputValues(newValuesObj);
                  }}
                  onSubmitEditing={() => focusAtIndex(index + 1)}
                />
                {message != null ? <Text style={styles.errorMsg}>{message}</Text> : null}
              </>
            ) : null}
          </EditRow>
        );
      })}
    </div>
  );
});

const styles = {
  container: {
    flex: 1,
    paddingLeft: 16,
  },
  input: {
    backgroundColor: '#ffffff',
  },
  errorMsg: {
    color: colors.brand
  }
};

MPEditMeasurementValues.displayName = 'MPEditMeasurementValues';
export default MPEditMeasurementValues;
