// @flow
import React from 'react';
import { Button, Typography } from 'antd';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import { useTranslation } from 'react-i18next';
import useLocalAudioToggle from '../../../plugins/video_call/hooks/useLocalAudioToggle';
import useVideoContext from '../../../plugins/video_call/hooks/useVideoContext';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '12px',
  },
  text: {
    textAlign: 'center',
  },
};

export default function ToggleAudioButton(props: {
  disabled?: boolean,
  style?: Object,
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const { t } = useTranslation();
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

  return (
    <div
      style={{
        ...styles.container,
        ...(props.style ?? {}),
      }}>
      <Button
        shape="circle"
        onClick={toggleAudioEnabled}
        disabled={!hasAudioTrack || props.disabled}
        icon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      />
      <Typography.Text style={styles.text}>
        {!hasAudioTrack
          ? t('No Audio')
          : isAudioEnabled
            ? t('Mic is open')
            : t('Mic is closed')}
      </Typography.Text>
    </div>
  );
}
