//@flow
import { getDefaultPageSize } from './lib/defaults';
import { getQueryString } from './lib/utils';
import { createAxiosInstance } from './_axios';
import type { ApiResponseArray, UserNotification } from '../config/types';

const notificationsApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 * Update an existing Appointment.
 *
 * @param {number} id Appointment's id.
 * @param {Appointment} data Appointment data to update.
 */
export const updateUserNotification = (
  id: number,
  data: UserNotification
): Promise<UserNotification> =>
  notificationsApi.patch(`api/notifications/${id}`, data);

/**
 * Retrieves a single Notification.
 *
 * @param {number} id Notification's id.
 */
export const getUserNotification = (id: number): Promise<UserNotification> =>
  notificationsApi.get(`api/notifications/${id}`);

/**
 * Lists all Notifications.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listUserNotifications = (
  params?: Object
): Promise<ApiResponseArray<UserNotification>> => {
  const callParams = {
    page_size: getDefaultPageSize(),
    ...(params || {})
  };
  return notificationsApi.get(`api/notifications${getQueryString(callParams)}`
  );
};

/**
 * Marks all user notifications on the server, as read.
 */
export const markAllUserNotificationsAsRead = (): Promise<any> =>
  notificationsApi.patch('api/notifications/', { read: true });
