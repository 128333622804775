import React, { useState } from 'react';
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../../constants';
// import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { Typography, Select } from 'antd';
// import { LocalVideoTrack } from 'twilio-video';
// import { makeStyles } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useDevices from '../../../../plugins/video_call/hooks/useDevices';
import useMediaStreamTrack from '../../../../plugins/video_call/hooks/useMediaStreamTrack';
import useVideoContext from '../../../../plugins/video_call/hooks/useVideoContext';

const useStyles = () => {
  return {
    preview: {
      width: '300px',
      maxHeight: '200px',
      marginBottom: '12px',
      '& video': {
        maxHeight: '200px',
      },
    },
    video: {
      maxHeight: '200px',
    },
  };
};

export default function VideoInputList() {
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find((track) => track.kind === 'video');
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
  );
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS || {}),
      deviceId: { exact: newDeviceId },
    });
  }

  return (
    <div>
      {localVideoTrack && (
        <div style={classes.preview}>
          <VideoTrack
            isLocal
            track={localVideoTrack}
            videoStyle={classes.video}
          />
        </div>
      )}
      <Typography.Text strong>Video Input</Typography.Text>
      <br />
      {videoInputDevices.length > 1 ? (
        <Select
          style={{ width: '200px' }}
          onChange={(e) => replaceTrack(e.value)}
          value={localVideoInputDeviceId || ''}
          variant="outlined">
          {videoInputDevices.map((device) => (
            <Select.Option value={device.deviceId} key={device.deviceId}>
              {device.label}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Typography.Text>
          {localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}
        </Typography.Text>
      )}
    </div>
  );
}
