import React from 'react';
import { useVideoContext } from '../../../plugins/video_call/hooks';

import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import useMainParticipant from '../../../plugins/video_call/hooks/useMainParticipant';
import useSelectedParticipant from '../../../plugins/video_call/VideoProvider/useSelectedParticipant';
import useScreenShareParticipant from '../../../plugins/video_call/hooks/useScreenShareParticipant';

const MiniPlayer = (props): React$Element<any> | null => {

  const mainParticipant = useMainParticipant();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
      mainParticipant !== localParticipant
      ? 'high'
      : null;

  return (
    <ParticipantInfo
      participant={mainParticipant}
      textInfo={'none'}
    >
      <ParticipantTracks
        participant={mainParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </ParticipantInfo>
  )
}

export default MiniPlayer;
