// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import useRoomState from '../../../plugins/video_call/hooks/useRoomState';
import { ERROR_APPEARANCE_DURATION } from '../../../constants';

const key = 'reconnecting_notification_key';

export default function ReconnectingNotification() {
  const { t } = useTranslation();
  const roomState = useRoomState();
  const [prevRoomState, setPrevRoomState] = useState(roomState);

  useEffect(() => {
    if (prevRoomState !== roomState) {
      setPrevRoomState(roomState);
    }
  }, [roomState, prevRoomState]);

  useEffect(() => {
    const handleChange = () => {
      if (roomState === 'reconnecting') {
        message.loading({ content: t('Reconnecting…'), key, duration: 0 });
      } else if (prevRoomState === 'reconnecting') {
        if (roomState === 'connected') {
          message.success({
            content: t('Reconnected!'),
            key,
            duration: ERROR_APPEARANCE_DURATION,
          });
        } else if (roomState === 'disconnected') {
          message.error({
            content: t('Failed to reconnect'),
            key,
            duration: ERROR_APPEARANCE_DURATION,
          });
        }
      }
    };

    if (roomState !== prevRoomState) {
      handleChange();
    }
  }, [roomState, prevRoomState, t]);

  return null;
}
