// @flow
import * as React from 'react';
import { FileImageOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import type { File } from '../../../config/types';

export const iconForFileType = (type: string, props: Object = {}): React.Node => {
  if (type?.startsWith('image/')) {
    return <FileImageOutlined {...props} />;
  } else if (type === 'application/pdf') {
    return <FilePdfOutlined {...props} />;
  }
  return <FileOutlined {...props} />;
};

export const isImageFile = (file?: File): boolean => Boolean(file?.type?.startsWith('image/'));
export const isPdfFile = (file?: File): boolean => Boolean(file?.type === 'application/pdf');

/**
 * Tells your whether or not a given file is a file stored on the server.
 * When returns `false`, the file is either a local file or a file stored on
 * a different server.
 *
 * @param {File} file The file to check.
 * @returns {boolean}
 */
export const isFileGotFromServer = (file: File): boolean =>
  typeof file?.id === 'number' && !`${file?.id}`.startsWith('local');

export const filterImages = (file: File): boolean => isImageFile(file);

/**
 * Converts a number of bytes to a human friendly string.
 * Source: https://stackoverflow.com/a/18650828
 *
 * @param {Number} bytes The file's size in bytes.
 * @param {Number} [decimals=2] The decimal points to be displayed in the result.
 * @returns {String} The final formatted string
 */
export const humanFriendlyFileSize = (bytes: number, decimals?: number = 2): string => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
