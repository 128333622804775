//@flow
import { createAxiosInstance } from './_axios';
import { getAllDefaultHeaders } from './lib/defaults';
import type {
  SymptomCheckerMention,
  SymptomCheckerEvidence,
  SymptomCheckerNlpResponse,
  SymptomCheckerDiagnosisData,
  SymptomCheckerDiagnosisResponse
} from '../config/types';

const symptomsCheckerApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 *
 *
 * @param {string} text
 * @returns {Promise<Object>}
 */
export const processSymptomCheckerMessage = (params: {
  text: string,
  age: {
    value: number,
    unit?: 'month' | 'year',
  },
  sex?: 'male' | 'female',
}): Promise<SymptomCheckerNlpResponse> => {
  // const headers = getAllDefaultHeaders();
  return symptomsCheckerApi.post(
    `api/symptom_checker/parse`,
    params
  );
};

/**
 *
 * @param {Object} data
 * @returns {Promise<Object>}
 */
export const getSymptomCheckerDiagnosis = (
  data: SymptomCheckerDiagnosisData
): Promise<SymptomCheckerDiagnosisResponse> => {
  const defaultHeaders = getAllDefaultHeaders();
  const headers = {
    ...defaultHeaders,
    'Infermedica-Model': `infermedica-${
      defaultHeaders['Accept-Language'] || 'el'
    }`
  };

  // By default, disable grouped questions
  const { extras, ...restData } = data || {};

  const postData = {
    extras: {
      ...(extras || {}),
      disable_groups: true
    },
    ...restData
  };
  return symptomsCheckerApi.post(
    `api/symptom_checker/diagnosis`,
    postData
  ).then((response) => ({
    ...response.data,
    requestedEvidence: [...(restData.evidence || [])]
  })
  );
};

/**
 * Combines `processSymptomCheckerMessage` and `getSymptomCheckerDiagnosis`.
 * If there are no evidences, will process the text and then will try to get the diagnosis
 *
 * @param {SymptomCheckerDiagnosisData} data
 * @returns
 */
export const getDiagnosisWithFallback = async (
  data: SymptomCheckerDiagnosisData
): Promise<SymptomCheckerDiagnosisResponse> => {
  let dataForDiagnosis = { ...data };
  if (data.evidence.length === 0) {
    const resp = await processSymptomCheckerMessage({
      text: data.text,
      age: data.age,
      sex: data.sex
    });
    dataForDiagnosis.evidence = resp.data?.mentions.map(
      (m: SymptomCheckerMention): SymptomCheckerEvidence => ({
        id: m.id,
        choice_id: m.choice_id,
        source: 'initial'
      })
    );
    delete dataForDiagnosis.text;
  }

  return getSymptomCheckerDiagnosis(dataForDiagnosis);
};
