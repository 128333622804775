import React, { useCallback, useRef } from 'react';

import { Button, Typography } from 'antd';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import { useTranslation } from 'react-i18next';
import useDevices from '../../../plugins/video_call/hooks/useDevices';
import useLocalVideoToggle from '../../../plugins/video_call/hooks/useLocalVideoToggle';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '12px',
  },
  text: {
    textAlign: 'center',
  },
};

export default function ToggleVideoButton(props: {
  disabled?: boolean,
  style?: Object,
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const { t } = useTranslation();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <div
      style={{
        ...styles.container,
        ...(props.style ?? {}),
      }}>
      <Button
        shape="circle"
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        icon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
      />
      <Typography.Text style={styles.text}>
        {!hasVideoInputDevices
          ? t('No Camera')
          : isVideoEnabled
            ? t('Camera is enabled')
            : t('Camera is disabled')}
      </Typography.Text>
    </div>
  );
}
