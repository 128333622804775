// @flow
import authentication from '../../plugins/authentication';

const saveAuthToken = (store: Object): Function => (next) => (action) => {
  const storedAccessToken = store.getState().auth.accessToken;
  if (action.type === 'auth/login') {
    authentication.setAuthToken(action.accessToken);
  } else if (storedAccessToken !== authentication.getAuthToken()) {
    authentication.setAuthToken(storedAccessToken);
  }

  // continue processing this action
  return next(action);
};

export default saveAuthToken;
