// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import FilesManager from '../../filesManager/FilesManager';
import { useFilesManagerHandler } from '../../../hooks/useFilesManagerHandler';
import { createNewAppointment } from '../../../store/slices/appointmentsSlice';

// Components imports
import { Divider, Button, Form, Input, Modal, Radio, Typography } from 'antd';
import { VideoCameraOutlined, CloseCircleFilled, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import InfoCardHeader from '../../InfoCardHeader';

import { getUserFullname } from '../../../utils';
import message from '../../../utils/message';
import moment from 'moment';

import type { Appointment, Doctor, Slot } from '../../../config/types';
import { composeStyles } from '../../../utils/styling';
import { NAV_APPOINTMENTS_SINGLE } from '../../../constants';

type Props = {
  visible?: boolean,
  doctor: Doctor,
  slot: Slot,
  onCancel?: (e: any) => void,
  onAppointmentCreated?: (newConsultation: Appointment) => void,
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const BookAppointmentModal = (
  props: Props,
): React$Element<React$FragmentType> => {
  const { visible, doctor, slot, onCancel, onAppointmentCreated } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const {
    files,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  } = useFilesManagerHandler();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onFinish = async (values: any) => {
    console.log(values);

    const data = {
      files,
      type: 'online',
      start: slot?.start,
      finish: slot?.finish,
      status: 'new',
      doctor: doctor?.id,
      ...values,
    };
    if (data.description === '') {
      delete data.description;
    }

    try {
      setLoading(true);
      await continueWithFilesUploadCaution();
      const actionData = await dispatch(createNewAppointment(data));
      const newAppointment = unwrapResult(actionData);

      message.success(t('You will soon receive a confirmation from your doctor about the date and time of your appointment.'));
      history.push(NAV_APPOINTMENTS_SINGLE.replace(':id', newAppointment.id));

      if (onAppointmentCreated != null) {
        // $FlowFixMe
        onAppointmentCreated(newAppointment);
      }
    } catch (e) {
      if (e.message !== 'cancelled') {
        message.error(e.message);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      title={
        <Typography.Title level={5} style={styles.title}>
          {t('Confirm appointment')}
        </Typography.Title>
      }
      centered
      closable={true}
      closeIcon={<CloseCircleFilled />}
      visible={visible}
      destroyOnClose={true}
      width={831}
      bodyStyle={styles.body}
      footer={null}
      onCancel={onCancel}>
      <div style={styles.contentContainer}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <InfoCardHeader
            style={styles.infoCard}
            imgSrc={doctor?.avatar}
            title={getUserFullname(doctor)}
            subTitle={doctor?.speciality}
          />
          <Divider />
          <div style={styles.dateTimeContainer}>
            <CalendarOutlined />
            <Typography.Text style={styles.dateTimeText}>
              {moment(slot?.start).format('dddd, D MMMM YYYY')}
            </Typography.Text>
          </div>
          <div style={composeStyles([styles.dateTimeContainer, styles.dateTimeContainerLast])}>
            <ClockCircleOutlined />
            <Typography.Text style={styles.dateTimeText}>
              {moment(slot?.start).format('h:mm a')}
              {' - '}
              {moment(slot?.finish).format('h:mm a')}
            </Typography.Text>
          </div>

          <Form.Item name="description" initialValue={''}>
            <Input.TextArea
              autoFocus
              placeholder={t('Details (optional)')}
              rows={4}
            />
          </Form.Item>
          <Typography.Title level={5} style={styles.contentTitle}>
            {t('Can doctor access your medical profile?')}
          </Typography.Title>
          <Form.Item
            name="allow_doctor_medical_access"
            initialValue={false}>
            <Radio.Group>
              <Radio value={true}>{t('Yes')}</Radio>
              <Radio value={false}>{t('No')}</Radio>
            </Radio.Group>
          </Form.Item>
          <Typography.Title level={5} style={styles.contentTitle}>
            {t('Attach files (optional)')}
          </Typography.Title>
          <FilesManager
            editable={true}
            onFilesUpdated={onFilesUpdated}
          />
          <div style={styles.buttonContainer}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              icon={<VideoCameraOutlined />}
              style={styles.callNowButton}>
              {t('Send appointment request')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const styles = {
  title: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  body: {
    background: '#F5F5F5',
    borderRadius: '0 0 8px 8px',
  },
  infoCard: {
    marginBottom: '24px',
  },
  contentTitle: {
    marginTop: '24px',
  },
  contentContainer: {
    maxWidth: '451px',
    margin: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  callNowButton: {
    flexGrow: 1,
    maxWidth: '360px',
    marginTop: '98px',
    marginBottom: '24px',
    background: '#1EC760',
  },
  dateTimeContainer: {
    flexDirection: 'row',
    marginBottom: '8px',
  },
  dateTimeContainerLast: {
    marginBottom: '24px',
  },
  dateTimeText: {
    fontWeight: 'bold',
    marginLeft: '8px',
  },
};

export default BookAppointmentModal;
