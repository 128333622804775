import React from 'react';
import { useSelector } from 'react-redux';

// Components imports
import { Typography, Row, Col } from 'antd';
import LogoutButton from '../LogoutButton';

// Const declarations
import { APP_VERSION } from '../../constants';
const { Text, Title } = Typography;
const baseClassName = 'user-popup';

const UserPopUp = () => {
  const { user } = useSelector(state => state.profile);

  return (
    <Row>
      <Col lg={24} sm={0} xs={0}>
        <div className={baseClassName}>
          <div className={`${baseClassName}__body`}>
            <Title level={5}>{user.first_name} {user.last_name}</Title>
            <Text>{user.email}</Text>
            <br/>
            <Text>{APP_VERSION}</Text>
          </div>
          <div className={`${baseClassName}__footer`}>
            <LogoutButton />
          </div>
        </div>
      </Col>
      <Col lg={0} sm={24} xs={24}>
        <LogoutButton />
      </Col>
    </Row>

  )
}

export default UserPopUp;
