// @flow
import React from 'react';

// Components imports
import { Typography } from 'antd';
import AvatarWithAuth from '../AvatarWithAuth';

// const declarations
const { Title, Text } = Typography;
const baseClassName = 'info-card-header'
type Props = {
  imgSrc: string,
  title?: string,
  subTitle?: string,
  style?: any,
}

const AppointmentsInfoCard = (props: Props): React$Element<"div"> => {
  const { imgSrc, title, subTitle, style } = props;
  return (
    <div className={baseClassName} style={style}>
      <AvatarWithAuth size={60} imgUrl={{ uri: imgSrc }} />
      <div className={`${baseClassName}__body`}>
        {
          title
            ? <Title level={4}>{title}</Title>
            : null
        }
        {
          subTitle
            ? <Text>{subTitle}</Text>
            : null
        }
      </div>
    </div>
  )
}

export default AppointmentsInfoCard;
