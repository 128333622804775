// @flow
import { createAxiosInstance } from './_axios';
import type { MedicalProfileIDsOpts, MPProfile, MedicalProfileResource } from '../config/types-mp';
import type { ApiResponseArray, MPMedicine } from '../config/types';
import { getQueryString } from './lib/utils'
import { getDefaultPageSize } from './lib/defaults';

const medicalProfileApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 * Builds the base url for api calls for medical profile.
 *
 * @param {(number | null)} patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @returns {string}
 */
const getMedicalProfileBaseUrl = ({
  patient_id,
  consultation_id,
}: MedicalProfileIDsOpts = {}): string => {
  let path;
  if (consultation_id != null) {
    path = `consultations/${consultation_id}`;
  } else if (patient_id != null) {
    path = `patients/${patient_id}`;
  } else {
    path = 'me';
  }
  return `api/${path}/medical_profile`;
};

/**
 * Fetches the medical profile of a specific patient.
 *
 * @param {(number | null)} patient_id The patient's id. When `null` or `undefined`, will request for current user.
 */
export const getMedicalProfile = (
  opts?: MedicalProfileIDsOpts,
): Promise<> => medicalProfileApi.get(getMedicalProfileBaseUrl(), { ...opts });

/**
 * Updates the medical profile of a specific patient.
 *
 * @param {(number | null)} patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @param {MPProfile} data The medical profile's data.
 */
export const updateMedicalProfile = (
  opts: MedicalProfileIDsOpts & { data: MPProfile },
): Promise<MPProfile> => {
  const { patient, ...restData } = opts?.data || {};

  return medicalProfileApi.patch(getMedicalProfileBaseUrl(opts), { ...restData });
};

/**
 * Lists medicines.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listMedicines = (
  opts?: Object & { search?: string },
): Promise<Array<MPMedicine>> => {
  return medicalProfileApi.get(`api/medicines${getQueryString(opts)}`,
  );
};

// *****************************************
// *****************************************
// ******                             ******
// ******      Generic Resources      ******
// ******                             ******
// *****************************************
// *****************************************

/**
 * Fetch "options" related to a specific resource.
 *
 */
export const getMedicalProfileResourceOptions = (
  opts:
    | (MedicalProfileIDsOpts & {
      resource: MedicalProfileResource,
    })
    | MedicalProfileResource,
): Promise<Object> => {
  const resource = typeof opts === 'string' ? opts : opts.resource;
  const options = typeof opts === 'object' && opts != null ? opts : {};
  return medicalProfileApi.options(`${getMedicalProfileBaseUrl(options)}/${resource}`);
}

/**
 * Lists the medical profile's resource items for a specific patient.
 *
 * @param {Object} opts Options for api call that among others, should contain the `resource` of medical profile and/or the patient's id and/or the consultation's id
 * @param {(number | null)} opts.patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @param {(number | null)} opts.consultation_id The consultation's id for accessing medical profile of that specific consultation.
 * @param {MedicalProfileResource} opts.resource The medical profile's resource to make the API call.
 * @param {Object} opts.params Parameters to filter the results of the api call.
 */
function listMedicalProfileResourceItems<T>(
  opts: MedicalProfileIDsOpts & {
    params: Object,
    resource: MedicalProfileResource,
  },
): Promise<ApiResponseArray<T>> {
  return medicalProfileApi.get(`${getMedicalProfileBaseUrl(opts)}/${opts.resource}${getQueryString({
    page_size: getDefaultPageSize(),
    ...opts.params,
  })}`);
}

/**
 * Gets a medical profile's resource items for a specific patient.
 *
 * @param {Object} opts Options for api call that among others, should contain the `resource` of medical profile and/or the patient's id and/or the consultation's id
 * @param {(number | null)} opts.patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @param {(number | null)} opts.consultation_id The consultation's id for accessing medical profile of that specific consultation.
 * @param {MedicalProfileResource} opts.resource The medical profile's resource to make the API call.
 * @param {number} opts.itemId The resource item's id.
 */
function getMedicalProfileResourceItem<T>(
  opts: MedicalProfileIDsOpts & {
    itemId: number,
    resource: MedicalProfileResource,
  },
): Promise<T> {
  return medicalProfileApi.get(`${getMedicalProfileBaseUrl(opts)}/${opts.resource}${opts.itemId}`);
}

/**
 * Creates a medical profile's resource items for a specific patient.
 *
 * @param {Object} opts Options for api call that among others, should contain the `resource` of medical profile and/or the patient's id and/or the consultation's id
 * @param {(number | null)} opts.patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @param {(number | null)} opts.consultation_id The consultation's id for accessing medical profile of that specific consultation.
 * @param {MedicalProfileResource} opts.resource The medical profile's resource to make the API call.
 * @param {T} opts.data The resource item's data.
 */
function createMedicalProfileResourceItem<T>(
  opts: MedicalProfileIDsOpts & {
    data: T,
    resource: MedicalProfileResource,
  },
): Promise<T> {
  return medicalProfileApi.post(`${getMedicalProfileBaseUrl(opts)}/${opts.resource}`, opts.data);
}

/**
 * Updates a medical profile's resource items for a specific patient.
 *
 * @param {Object} opts Options for api call that among others, should contain the `resource` of medical profile and/or the patient's id and/or the consultation's id
 * @param {(number | null)} opts.patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @param {(number | null)} opts.consultation_id The consultation's id for accessing medical profile of that specific consultation.
 * @param {MedicalProfileResource} opts.resource The medical profile's resource to make the API call.
 * @param {number} opts.itemId The resource item's id.
 * @param {T} opts.data The resource item's data.
 */
function updateMedicalProfileResourceItem<T>(
  opts: MedicalProfileIDsOpts & {
    itemId: number,
    data: T,
    resource: MedicalProfileResource,
  },
): Promise<T> {
  return medicalProfileApi.patch(
    `${getMedicalProfileBaseUrl(opts)}/${opts.resource}/${opts.itemId}`,
    opts.data,
  );
}

/**
 * Deletes a medical profile's resource item for a specific patient.
 *
 * @param {Object} opts Options for api call that among others, should contain the `resource` of medical profile and/or the patient's id and/or the consultation's id
 * @param {(number | null)} opts.patient_id The patient's id. When `null` or `undefined`, will request for current user.
 * @param {(number | null)} opts.consultation_id The consultation's id for accessing medical profile of that specific consultation.
 * @param {MedicalProfileResource} opts.resource The medical profile's resource to make the API call.
 * @param {number} opts.itemId The resource item's id.
 */
export const deleteMedicalProfileResourceItem = (
  opts: MedicalProfileIDsOpts & {
    itemId: number,
    resource: MedicalProfileResource,
  },
): Promise<any> => {
  return medicalProfileApi.delete(
    `${getMedicalProfileBaseUrl(opts)}/${opts.resource}/${opts.itemId}`,
  );
}

export {
  listMedicalProfileResourceItems,
  getMedicalProfileResourceItem,
  createMedicalProfileResourceItem,
  updateMedicalProfileResourceItem,
};
