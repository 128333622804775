import { useCallback, useEffect, useState } from 'react';

/**
 * Plays sound for a given url.
 * The sound will **loop** and **auto play**.
 * @param {*} url The sound's url.
 * @returns
 */
export const useAudio = (url) => {
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState(false);

  const play = useCallback(() => {
    if (audio) {
      audio.play();
      setPlaying(true);
    }
  }, [audio]);

  const stop = useCallback(() => {
    if (audio) {
      audio.pause();
      setPlaying(false);
    }
  }, [audio]);

  useEffect(() => {
    const load = async () => {
      if (url) {
        // const _audio = await getSoundSource(url, true);
        const _audio = new Audio(url);
        _audio.loop = true;
        setAudio(_audio);
      } else {
        setAudio(null);
      }
    }
    load();

    return () => {
      setAudio(null);
      setPlaying(false);
    }
  }, [url]);

  return {
    audio,
    playing,
    play,
    stop,
  }
};
