// @flow
import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { getAppointmentsStartingNow } from '../../store/slices/appointmentsSlice';
import { NAV_APPOINTMENTS, NAV_APPOINTMENTS_SINGLE, NAV_VISITS_SESSION } from '../../constants';

const { Text } = Typography;

type Props = {
  closable?: boolean
};

const baseClassName = 'ActiveAppointmentsBanner';

const ActiveAppointmentsBanner = (props: Props): React$Element<any> | null => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const appointments = useSelector(getAppointmentsStartingNow);

  const lastPathComponent = location.pathname.split('/').pop();
  const currentAppointmentIndex = appointments.findIndex((tmp) => {
    if (location.pathname.startsWith(NAV_VISITS_SESSION)) {
      return `${tmp.consultation?.id}` === lastPathComponent;
    }
    return `${tmp.id}` === lastPathComponent;
  });
  const isPageRelatedToActiveAppointment = currentAppointmentIndex > -1;
  const shouldHideBanner = (
    appointments.length === 0
    || (
      isPageRelatedToActiveAppointment && (
        location.pathname === NAV_APPOINTMENTS_SINGLE.replace(':id', lastPathComponent) ||
        location.pathname === `${NAV_VISITS_SESSION}/${appointments[currentAppointmentIndex]?.consultation?.id}`
      )
    )
  );

  useEffect(() => {
    if (shouldHideBanner) {
      // reset so user can re-close when the time comes for the "next" appointment
      setVisible(true);
    }
  }, [shouldHideBanner]);

  if (!visible || shouldHideBanner) {
    return null;
  }

  const pathname = appointments.length === 1
    ? NAV_APPOINTMENTS_SINGLE.replace(':id', appointments[0].id)
    : NAV_APPOINTMENTS;

  const spacer = <div style={{ width: '40px' }} />;

  return (
    <div className={`${baseClassName}`}>
      {spacer}
      <div>
        <InfoCircleOutlined />
        <Text>
          {t('You have {{count}} appointment starting now.', { count: appointments.length })}
        </Text>
        <Link to={{ pathname }}>
          <Text>{t('View more.')}</Text>
        </Link>
      </div>
      {props.closable ? (
        <Button
          type={'primary'}
          shape={'circle'}
          ghost={true}
          icon={<CloseOutlined />}
          size={'small'}
          onClick={() => setVisible(false)}
        />
      ) : spacer}
    </div>
  );
}

ActiveAppointmentsBanner.defaultProps = {
  closable: true,
};

export default ActiveAppointmentsBanner;
