// @flow
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { composeStyles } from '../../utils/styling';
import { filterImages } from '../filesManager/utils/files';
import { API_BASE_URL } from '../../constants';
import Lightbox from 'react-image-lightbox';
import { getFileMeta } from '../../api/files';
import { fileHasValidPublicUrl } from '../../utils';
import type { File } from '../../config/types';

type Props = {
  style?: any,
  files: Array<File>,
  initialPage: number,
  insets: {
    top: number,
    bottom: number,
    left: number,
    right: number,
  },
  t: Function,
  onRequestClose?: () => void,
};

type State = {
  currentPageIndex: number,
  errorLoading: boolean,
  downloadedFilePath: string | null,
  files: Array<File>,
};

/**
 * Compares two arrays for equality.
 * https://stackoverflow.com/a/16436975
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
function arraysEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false
  }

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

/**
 * Loading icon that is used inside react-image-lightbox.
 * Source: https://github.com/frontend-collective/react-image-lightbox/blob/master/src/react-image-lightbox.js#L1349
 */
const LIGHTBOX_LOADING_ICON = (
  <div className="ril-loading-circle ril__loadingCircle ril__loadingContainer__icon">
    {[...new Array(12)].map((_, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className="ril-loading-circle-point ril__loadingCirclePoint"
      />
    ))}
  </div>
);

class FilesGallery extends React.Component<Props, State> {

  static defaultProps = {
    initialPage: 0,
    files: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      currentPageIndex: props.initialPage != null ? props.initialPage : -1,
      errorLoading: false,
      downloadedFilePath: null,
      files: props.files?.filter(filterImages) || [],
    };
  }

  componentDidMount() {
    const { currentPageIndex } = this.state;
    this.onMoveRequestToIndex(currentPageIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    const fileIDs = this.props.files?.filter(filterImages).map((tmp) => tmp.id);
    const prevFileIDs = prevProps.files?.filter(filterImages).map((tmp) => tmp.id);

    if (!arraysEqual(fileIDs, prevFileIDs)) {
      this.setState({ files: this.props.files });
    }
  }

  // ================
  // Helpers
  // ================

  close = () => {
    this.props.onRequestClose && this.props.onRequestClose();
  };

  getFiles = (): Array<File> => {
    const { files } = this.state;
    return files?.filter(filterImages);
  };

  getUrlForFileDisplay = (file: File): ?string => {
    let url = file.public_url || file.file;
    if (url != null && !url.startsWith('http') && file.blob) {
      url = URL.createObjectURL(file.blob);
    }
    return url;
  };

  getMainSrc = () => {
    const files = this.getFiles();
    if (this.state.currentPageIndex > -1 && this.state.currentPageIndex < files.length) {
      return this.getUrlForFileDisplay(files[this.state.currentPageIndex]);
    }
    return null;
  };

  getNextSrc = () => {
    const files = this.getFiles();
    const nextIndex = this.state.currentPageIndex + 1;
    if (nextIndex > -1 && nextIndex < files.length) {
      return this.getUrlForFileDisplay(files[nextIndex]);
    }
    return null;
  };

  getPrevSrc = () => {
    const files = this.getFiles();
    const prevIndex = this.state.currentPageIndex - 1;
    if (prevIndex > -1 && prevIndex < files.length) {
      return this.getUrlForFileDisplay(files[prevIndex]);
    }
    return null;
  };

  getReloadedFileIfNeeded = (index: number): Promise<any> => {
    const { files } = this.state;
    if (index > -1 && index < files.length) {
      const _file = files[index];
      if (fileHasValidPublicUrl(_file) || !_file.public_url?.startsWith(API_BASE_URL)) {
        return Promise.resolve(_file);
      }
      return getFileMeta(_file.id)
        .then((response) => response.data)
        .catch((error) => {
          console.log('error fetching file meta data', error);
          // don't know what went wrong...
          // return the original file
          return _file;
        });
    }

    return Promise.reject({ message: 'Index out of bounds' });
  };

  onMoveRequestToIndex = async (index: number) => {
    try {
      const { files } = this.state;
      const newFile = await this.getReloadedFileIfNeeded(index);
      const newFilesArray = [...files];
      newFilesArray[index] = newFile;
      this.setState({
        files: newFilesArray,
        currentPageIndex: index,
      });
    } catch (error) {
      console.log('error reloading image', error);
      this.setState({ currentPageIndex: index });
    }
  };

  // ====================
  // <Gallery /> handlers
  // ====================

  onPageSelected = (index) => {
    this.setState({ currentPageIndex: index });
  };

  render() {
    const {
      initialPage,
      style,
      onRequestClose,
      insets,
      t,
      ...restProps
    } = this.props;
    const { currentPageIndex } = this.state;
    const files = this.getFiles();
    return (
      <>
        {/* $FlowFixMe */}
        <div style={composeStyles([styles.container, style])} {...restProps}>
          <Lightbox
            mainSrc={this.getMainSrc()}
            nextSrc={this.getNextSrc()}
            prevSrc={this.getPrevSrc()}
            onCloseRequest={this.close}
            onMovePrevRequest={async () => {
              const prevSrcIndex = currentPageIndex - 1;
              if (prevSrcIndex > -1 && prevSrcIndex < files.length) {
                this.onMoveRequestToIndex(prevSrcIndex);
              }
            }}
            onMoveNextRequest={async () => {
              const nextSrcIndex = currentPageIndex + 1;
              if (nextSrcIndex > -1 && nextSrcIndex < files.length) {
                this.onMoveRequestToIndex(nextSrcIndex);
              }
            }}
            imageLoadErrorMessage={LIGHTBOX_LOADING_ICON}
          />
        </div>
      </>
    );
  }
}

const styles = {
  container: {
    flex: 1,
  },
  otherItemsContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  clearBackground: {
    backgroundColor: 'transparent',
  },
  text: {
    textAlign: 'center',
    marginBottom: 16,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    alignItems: 'center',
    justifyContent: 'space-between',
  },
  overlayButtonsContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  pdf: {
    flex: 1,
  },
};

// $FlowFixMe
export default withTranslation()(FilesGallery);
