//Basic redux toolkit imports
import {
  createSlice,
} from '@reduxjs/toolkit';
// import * as SymptomsCheckerAPI from '../../api/symptomsChecker';
// import { createSelector } from 'reselect';
// Async thunk to get the user notifications based on params
// export const getUserNotifications = createAsyncThunk(
//   'notifications/getUserNotifications',
//   async (params, thunkAPI) => {
//     try {
//       const { data } = await SymptomsCheckerAPI.listUserNotifications({...params});
//       return data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// )

const symptomsCheckerSlice = createSlice({
  name: 'symptom-checker',
  initialState: {
    introInitiated: false,
    state: 'intro',
    sex: null,
    age: null,
    isTyping: false,
    symptoms: null,
    existingEvidences: [],
    quickReplies: [],
    quickReplyMeta: null,
    chatMessages: [],
    diagnosisDataToRetry: null
  },
  reducers: {
    // TODO listen for auth/logout to init state
    setIntroInitiated: (state, { payload }) => {
      state.introInitiated = payload;
    },
    setState: (state, { payload }) => {
      state.state = payload;
    },
    setSex: (state, { payload }) => {
      state.sex = payload;
    },
    setAge: (state, { payload }) => {
      state.age = payload;
    },
    setIsTyping: (state, { payload }) => {
      state.isTyping = payload;
    },
    setSymptoms: (state, { payload }) => {
      state.symptoms = payload;
    },
    setExistingEvidences: (state, { payload }) => {
      state.existingEvidences = [...(payload || [])];
    },
    setQuickReplies: (state, { payload }) => {
      state.quickReplies = [...(payload || [])];
    },
    setQuickReplyMeta: (state, { payload }) => {
      state.quickReplyMeta = payload;
    },
    setChatMessages: (state, { payload }) => {
      state.chatMessages = [...(payload || [])]
    },
    setDiagnosisDataToRetry: (state, { payload }) => {
      state.diagnosisDataToRetry = payload;
    },
    appendChatMessage: (state, { payload }) => {
      state.chatMessages = [...state.chatMessages, payload];
    },
    reset: (state, { payload }) => {
      state.introInitiated = false;
      state.state = 'intro';
      state.sex = null;
      state.age = null;
      state.isTyping = false;
      state.symptoms = null;
      state.existingEvidences = [];
      state.quickReplies = [];
      state.quickReplyMeta = null;
      state.chatMessages = [];
      state.diagnosisDataToRetry = null;
    }
  }
});

// Destructure and export the plain action creators
export const {
  setIntroInitiated,
  setState,
  setSex,
  setAge,
  setIsTyping,
  setSymptoms,
  setExistingEvidences,
  setQuickReplies,
  setQuickReplyMeta,
  setChatMessages,
  setDiagnosisDataToRetry,
  appendChatMessage,
  reset
} = symptomsCheckerSlice.actions;
export const symptomsCheckerReducer = symptomsCheckerSlice.reducer;
