// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConsultationActions } from '../../hooks/useConsultationActions';
import moment from 'moment';

// Components imports
import { Row, Col, Typography, Tag, Button, Divider } from 'antd';
import { CalendarOutlined, UsergroupAddOutlined, VideoCameraOutlined, EnvironmentOutlined } from '@ant-design/icons';

// Misc imports
import { getConsultationStatusInfo } from '../../utils';
import {
  getAppointmentTypeText,
  getTagStatusForAppointment,
  getCalculatedStatusForAppointment
} from '../../utils/appointments';
import { useConsultationParticipants } from '../../hooks/useConsultationParticipants';
import { openCallInitializationModal } from '../../store/slices/applicationSlice';
import type { Consultation, Appointment } from '../../config/types';

// const declarations
import { NAV_APPOINTMENTS, NAV_VISITS_SINGLE } from '../../constants.js';
const { Text } = Typography;
const baseClassName = 'consultation-info';

type Props = {
  item: Consultation | Appointment,
  isAppointment?: boolean,
  hideActions?: boolean
}
const ConsultationInfo = (props: Props): React$Element<"div"> => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isStartingCall,
    isJoiningCall,
    startCallForAppointment,
    joinConsultationAction,
  } = useConsultationActions();

  const { item, isAppointment, hideActions } = props;

  const { participant2: guestParticipant } = useConsultationParticipants(item);
  if(!item) {
    return (<Text>{t('No data available')}</Text>);
  }
  const getUsersFullName = (user) => !user ? '': `${user?.first_name || ''} ${user?.last_name || ''}`

  let participantName = getUsersFullName(guestParticipant?.user);
  let participantSpeciality = guestParticipant?.user?.details?.speciality
    ? `${guestParticipant?.user?.details?.speciality}`
    : '';

  let genericStatusTag = '';
  let genericTextTag = '';

  let appointmentIsNow = null;
  let shouldCallDoctorNow = null;
  let shouldJoinConsultation = null;
  let hasFinishedWithConsultation = null;

  if (!isAppointment) {
    // Consultation Item
    let { statusTag, textTag } = getConsultationStatusInfo(item.status, t);
    genericStatusTag = statusTag;
    genericTextTag = textTag;
    shouldJoinConsultation = item.status === 'in_progress';
  } else {
    //Appointment item
    const tagInfo = getTagStatusForAppointment(item);
    const status = getCalculatedStatusForAppointment(item);

    genericStatusTag = tagInfo?.status;
    genericTextTag = tagInfo?.label;
    appointmentIsNow =
      status === 'confirmed' &&
      moment().isSameOrAfter(item?.start) &&
      moment().isSameOrBefore(item?.max_call_availability);
    shouldCallDoctorNow =
      appointmentIsNow && item?.type === 'online';
    shouldJoinConsultation = status === 'in_progress';
    hasFinishedWithConsultation =
      status === 'finished' && item?.consultation != null;
  }

  const getTagClassName = (genericStatusTag) => {
    // color={genericStatusTag ==='danger'? 'error' : genericStatusTag}
    let tagBaseClass = 'ant-tag-';
    switch (genericStatusTag) {
      case 'danger':
        return `${tagBaseClass}error`;
      default:
        if (genericStatusTag) {
          return `${tagBaseClass}${genericStatusTag}`;
        }
        return `${tagBaseClass}`;
    }
  }

  // ======================================================
  // RENDER FUNCTIONS
  // ======================================================
  const renderTime = () => {
    const timeToBeRendered = isAppointment ? item.start : item.created_at;
    const calculateTime = moment(timeToBeRendered).format('ddd D/M/YYYY - h:mm:ss a');
    return (<Text className="time" strong>{calculateTime}</Text>);
  }

  const renderAppointmentType = () => {
    if (item.type === 'online' || item.type === 'physical') {
      const Icon = item.type==='online' ? <VideoCameraOutlined /> : <EnvironmentOutlined />
      return (
        <>
          {Icon}
          <Text strong>
            {getAppointmentTypeText(item.type, item.doctor)}
          </Text>
        </>
      )
    }
    return null;
  }

  const renderCallDoctorNow = () => {
    if(shouldCallDoctorNow) {
      return (
        <div>
          <Text className="appointment-info">{t('Your appointment is now. Call the doctor now.')}</Text>
          <Button
            type="primary"
            icon={<VideoCameraOutlined />}
            loading={isStartingCall}
            onClick={(e) => {
              e.preventDefault();
              // dispatch(openCallInitializationModal())
              startCallForAppointment(item);
            }}>
            {t('Call now the doctor')}
          </Button>
        </div>
      )
    }
    return null;
  }

  const renderJoinConsultation = () => {
    if (shouldJoinConsultation) {
      return (
        <Button
          type="secondary"
          icon={<VideoCameraOutlined />}
          loading={isJoiningCall}
          onClick={(e) => {
            e.preventDefault();
            joinConsultationAction(
              isAppointment ? item?.consultation?.id : item?.id
            );
          }}>
          {t('Join the call')}
        </Button>
      )
    }
    return null;
  }

  const renderCallInfo = () => {
    if (hasFinishedWithConsultation) {
      return (
        <Button
          type="secondary"
          onClick={(e) => {
            e.preventDefault();
            history.push(NAV_VISITS_SINGLE.replace(
              ':id',
              isAppointment ? item?.consultation?.id : item?.id
            ));
          }}>
          {t('See more about the call')}
        </Button>
      )
    }
    return null;
  }

  return (<div className={baseClassName}>
    <Row justify="space-between">
      <Col md={14}>
        <div className={`${baseClassName}__info mb-2`}>
          <CalendarOutlined />
          {renderTime()}
        </div>
        {
          guestParticipant
            ? <div className={`${baseClassName}__body-info mb-2`}>
              <UsergroupAddOutlined />
              <Text className="time" strong>{`${participantName} - ${participantSpeciality}`}</Text>
            </div>
            : null
        }
        {isAppointment && renderAppointmentType()}
      </Col>
      <Col md={10} className="text-md-right">
        <Tag className={getTagClassName(genericStatusTag)} >{genericTextTag}</Tag>
      </Col>
      <Row className={`${baseClassName}__appointments-info`}>
        <Col>
          <Divider />
          {!hideActions ? (
            <div className="text-center">
              {isAppointment ? (
                <>
                  {renderCallDoctorNow()}
                  {renderCallInfo(item)}
                </>
              ) : null}
              {renderJoinConsultation()}
            </div>
          ) : null}
        </Col>
      </Row>

    </Row>
  </div>);
}

export default ConsultationInfo;
