import React from 'react';
import 'moment/locale/el';
import packageJson from '../package.json';
import { HomeOutlined, VideoCameraOutlined, CalendarOutlined, CheckSquareOutlined, HeartOutlined } from '@ant-design/icons';
const {
  REACT_APP_API_BASE_URL, REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE, REACT_APP_DEBUG,
  REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE, REACT_APP_CALL_TO_DOCTOR,
  REACT_APP_DOCTOR, REACT_APP_COMMIT_ID, REACT_APP_TERMS_OF_SERVICE_URL, REACT_APP_PRIVACY_POLICY_URL,
  REACT_APP_CONTACT_US_PHONE, REACT_APP_DIETITIAN_PHONE, REACT_APP_NOTIFICATIONS_POLLING_INTERVAL,
} = process.env;

export const DEBUG = REACT_APP_DEBUG;

export const API_BASE_URL = REACT_APP_API_BASE_URL;
export const TERMS_OF_SERVICE_URL = REACT_APP_TERMS_OF_SERVICE_URL;
export const PRIVACY_POLICY_URL = REACT_APP_PRIVACY_POLICY_URL;
export const CONTACT_US_PHONE = REACT_APP_CONTACT_US_PHONE;
export const DIETITIAN_PHONE = REACT_APP_DIETITIAN_PHONE;
export const GA_TRACKING_CODE = REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE;
export const AUTH0_AUDIENCE = REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_DOMAIN = REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = REACT_APP_AUTH0_CLIENT_ID;
export const CALL_TO_DOCTOR = REACT_APP_CALL_TO_DOCTOR;
export const DOCTOR = REACT_APP_DOCTOR;
export const COMMIT_ID = REACT_APP_COMMIT_ID.substring(0, 7);

const pVersion = packageJson.version || '';

export const APP_VERSION = `${pVersion} (${COMMIT_ID})`;

// Video Call
export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// Video Call - These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// Store links
export const GOOGLE_PLAY_APP_URL = 'https://play.google.com/store/apps/details?id=gr.generali.myedoctor';
export const APP_STORE_APP_URL = 'https://apps.apple.com/gr/app/my-e-doctor/id1534843347';

// Social Links
export const FACEBOOK_URL = 'https://www.facebook.com/GeneraliHellas/';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UC-TEdBFYpAr0A5C4kham3Jw';

// Navigation routes
export const NAV_ROOT = '/';
export const NAV_LOGIN = '/login';
export const NAV_VISITS = '/visits';
export const NAV_VISITS_SESSION = '/visits/session';
export const NAV_VISITS_SINGLE = '/visits/:id';
export const NAV_APPOINTMENTS = '/appointments';
export const NAV_APPOINTMENTS_SINGLE = '/appointments/:id';
export const NAV_APPOINTMENTS_DOCTORS = '/appointments/doctors';
export const NAV_APPOINTMENTS_DOCTORS_SINGLE = '/appointments/doctors/:id';
export const NAV_SYMPTOM_CHECKER = '/symptom-checker';
export const NAV_PROFILE = '/profile';
export const NAV_NOT_FOUND = '/not-found';
export const NAV_NOTIFICATIONS = '/notifications';

export const MAIN_MENU_ITEMS = [
  {
    key: '1',
    title: 'Αρχική',
    icon: <HomeOutlined />,
    route: NAV_ROOT
  },
  {
    key: '2',
    title: 'Επισκέψεις',
    icon: <VideoCameraOutlined />,
    route: NAV_VISITS
  },
  {
    key: '3',
    title: 'Ραντεβού',
    icon: <CalendarOutlined />,
    route: NAV_APPOINTMENTS
  },
  {
    key: '4',
    title: 'Έλεγχος',
    icon: <CheckSquareOutlined />,
    route: NAV_SYMPTOM_CHECKER
  },
  {
    key: '5',
    title: 'Προφίλ',
    icon: <HeartOutlined />,
    route: NAV_PROFILE
  }
]

export const NOTIFICATION_MENU_ITEM = {
  key: '6',
  title: 'Ειδοποιήσεις',
  icon: <HeartOutlined />,
  route: NAV_NOTIFICATIONS
}

/**
 * The value is on seconds
 */
export const ERROR_APPEARANCE_DURATION = 5;

// Polling intervals

/**
 * Interval for polling for new notifications. Value is in seconds.
 */
export const POLLING_INTERVAL_NEW_NOTIFICATIONS =
  !Number.isNaN(parseInt(REACT_APP_NOTIFICATIONS_POLLING_INTERVAL))
    ? parseInt(REACT_APP_NOTIFICATIONS_POLLING_INTERVAL)
    : 10;
