import React from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
// Components imports
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

//Misc imports
import * as authActions from '../../store/slices/authSlice';

const LogoutButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  return <Button
    type="link"
    icon={<LogoutOutlined />}
    block
    onClick={() => {
      dispatch(authActions.logout());
      logout({
        returnTo: window.location.origin
      })
    }
    }>{t('Log out')}</Button>;
};

export default LogoutButton;
