// @flow
import { isMobile, removeKeysWithUndefinedValue } from '../../../utils';
import { getResolution } from './renderDimensions';

const settings: Object = {
  trackSwitchOffMode: undefined,
  dominantSpeakerPriority: 'standard',
  bandwidthProfileMode: 'collaboration',
  maxTracks: isMobile ? '5' : '10',
  maxAudioBitrate: '16000',
  renderDimensionLow: 'low',
  renderDimensionStandard: '960p',
  renderDimensionHigh: 'wide1080p',
};

export const getDefaultConnectionOptions = (): Object => {

  // See: https://sdk.twilio.com/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions: Object = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
      // when no value is passed for an option. It will throw an error when 'undefined' is passed.
      video: removeKeysWithUndefinedValue({
        mode: settings.bandwidthProfileMode,
        dominantSpeakerPriority: settings.dominantSpeakerPriority,
        renderDimensions: {
          low: getResolution(settings.renderDimensionLow),
          standard: getResolution(settings.renderDimensionStandard),
          high: getResolution(settings.renderDimensionHigh),
        },
        maxTracks: Number(settings.maxTracks),
        trackSwitchOffMode: settings.trackSwitchOffMode,
      }),
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },

    // Comment this line if you are playing music.
    maxAudioBitrate: Number(settings.maxAudioBitrate),

    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. Simulcast should be disabled if
    // you are using Peer-to-Peer or 'Go' Rooms.
    preferredVideoCodecs: [{
      codec: 'VP8',
      simulcast: true,
      // simulcast: roomType !== 'peer-to-peer' && roomType !== 'go'
    }],
  };

  // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
  if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
  }

  return connectionOptions;
}
