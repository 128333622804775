import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button, Popconfirm, Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import useVideoContext from '../../../plugins/video_call/hooks/useVideoContext';
import { useCurrentConsultation } from '../../../hooks/useCurrentConsultation';
import { clearCurrentConsultation } from '../../../store/slices/consultationSlice';
import { NAV_VISITS } from '../../../constants';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '12px',
  },
  text: {
    textAlign: 'center',
  },
};

export default function EndCallButton(props: {
  style?: Object,
  text?: string,
}) {
  const { t } = useTranslation();
  const { room } = useVideoContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const { consultation } = useCurrentConsultation();
  const [confirmVisible, setConfirmVisible] = useState();

  const onEnd = () => {
    room.disconnect();
    history.push(`${NAV_VISITS}/${consultation.id}`);
    dispatch(clearCurrentConsultation());
  };

  return (
    <div
      style={{
        ...styles.container,
        ...(props.style ?? {}),
      }}>
      <Popconfirm
        title={t('Do you want to leave the call?')}
        visible={confirmVisible}
        cancelText={t('No')}
        onCancel={() => setConfirmVisible(false)}
        okText={t('Yes')}
        onConfirm={onEnd}>
        <Button
          shape="circle"
          type="danger"
          onClick={() => setConfirmVisible(true)}
          icon={<PhoneOutlined />}
        />
      </Popconfirm>
      {props.text !== null ? (
        <Typography.Text style={styles.text}>
          {props.text || t('End call')}
        </Typography.Text>
      ) : null}
    </div>
  );
}
