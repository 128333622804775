// @flow
import React, { useCallback } from 'react';
import { Button, Typography, Spin, List, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import InfoCard from '../InfoCard/InfoCard';
import { useTranslation } from 'react-i18next';
import { useDataLoader } from '../../hooks/useDataLoader';
import type { DataLoaderHookProps } from '../../hooks/useDataLoader';
import type { Consultation, User } from '../../config/types';
import { composeStyles } from '../../utils/styling';
import { colors } from '../../styles/';
import { getConsultations, getConsultationsList } from '../../store/slices/consultationSlice';

type Props = {
  consultation?: Consultation,
  saving?: boolean,
  onSaveConsultationReport?: (consultation: Consultation) => any,
};

const { Text } = Typography;

const LoadingIndicator = (props) => (
  <div style={[props.style, styles.indicator]}>
    <Spin size={'small'} />
  </div>
);

const MPEditReportItemAddNew = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const list = useSelector(getConsultationsList);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const currentUser: User = useSelector((state) => state.profile.user);

  const apiCaller = useCallback(
    (params: Object) => {
      return dispatch(
        getConsultations({
          ...params,
          status: 'completed',
          shared_in_medical_profile: 0,
        })
      );
    },
    [dispatch]
  );

  const {
    page,
    isLoading,
    canRenderContent,
    reloadData,
    renderLoadMoreFooter,
    renderRefreshControl,
    renderEmptySpace
  }: DataLoaderHookProps = useDataLoader(apiCaller, null, {
    disableAutoLoad: false
  });

  return (
    <div style={styles.container}>
      <Row>
        <Col>
          {
            canRenderContent
              ? (<>
                <List
                  itemLayout="horizontal"
                  dataSource={list}
                  renderItem={item => (
                    <List.Item
                      data-item={JSON.stringify(item)}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedItem(item);
                        props.onSaveConsultationReport?.(item);
                      }}>
                      <InfoCard
                        item={item}
                        canItemBeSelected={true}
                        isItemSelected={item.id === selectedItem?.id}
                      />
                    </List.Item>
                  )}
                />
                {
                  list.length > 0 ? (
                    <div className="d-flex jc-c">
                      {renderLoadMoreFooter()}
                    </div>
                  ) : null
                }
              </>)
              : <Text>{t('No calls available')}</Text>
          }
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  flatlistStyle: {
    backgroundColor: colors.white,
  },
  item: {
    marginBottom: 0,
    marginTop: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.steam_gray,
  },
  footer: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  containerShadow: {
    elevation: 20,
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowRadius: 4,
    shadowOpacity: 3,
  },
  confirmationContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.white,
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  confirmationText: {
    marginBottom: 16,
    textAlign: 'center',
  },
  indicator: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default MPEditReportItemAddNew;
