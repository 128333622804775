// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  createNewConsultation,
  getConsultationById,
  setCurrentConsultation,
} from '../store/slices/consultationSlice';

import { NAV_VISITS_SESSION } from '../constants';
import message from '../utils/message';

// import * as RootNavigation from '../navigators/NavigationService';
import type { Appointment } from '../config/types';
import { unwrapResult } from '@reduxjs/toolkit';

/**
 * This hook provides functions for making actions related to consultations like
 * fetching consultation and navigating to video call screen.
 *
 * @returns
 */
export const useConsultationActions = (): {
  isStartingCall: boolean,
  isJoiningCall: boolean,
  startCallForAppointment: (appointment: Appointment) => any,
  joinConsultationAction: (consultationId: number) => any,
} => {
  const [isStartingCall, setIsStartingCall] = React.useState(false);
  const [isJoiningCall, setIsJoiningCall] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const startCallForAppointment = React.useCallback(
    async (appointment: Appointment) => {
      const consultation = {
        allow_doctor_medical_access: appointment.allow_doctor_medical_access,
        files: (appointment.files || []).map((item) => item.id),
        description: appointment.description,
        doctor: appointment.doctor.id,
        appointment: appointment.id,
        call_center: false,
      };
      try {
        setIsStartingCall(true);
        await dispatch(createNewConsultation(consultation)).then(unwrapResult);
        setIsStartingCall(false);
      } catch (error) {
        setIsStartingCall(false);
        message.error(error.message);
      }
    },
    [dispatch, t],
  );

  const joinConsultationAction = React.useCallback(
    async (consultationId: number) => {
      try {
        setIsJoiningCall(true);
        const payload = await dispatch(getConsultationById(consultationId)).then(unwrapResult);
        // const { payload } = result;
        let doctor = payload.participants?.find(
          (item) => item.type === 'doctor',
        );
        setIsJoiningCall(false);

        dispatch(setCurrentConsultation(payload));

        if (payload?.status === 'in_progress' || doctor?.status === 'joined') {
          history.push(`${NAV_VISITS_SESSION}/${payload.id}`)
        }
        // RootNavigation.navigate('VideoCallNavigator', { screen: 'VideoCall' });
      } catch (error) {
        setIsJoiningCall(false);
        message.error(error.message);
      }
    },
    [dispatch, t, history],
  );

  return {
    isStartingCall,
    isJoiningCall,
    startCallForAppointment,
    joinConsultationAction,
  };
};
