// @flow
import React, { useState, useEffect, Component } from 'react';
import { Button, Divider, Input, Typography, Select, DatePicker, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUserFullname } from '../../utils/index';
import Icon from '@ant-design/icons';
import moment from 'moment';
import type { MPProfile } from '../../config/types-mp';
import { EditOutlined } from '@ant-design/icons';
import { useInputState } from '../../hooks/useInputState';
import { useSelector, useDispatch } from 'react-redux';
import { safeParseInt, removeKeysWithUndefinedValue } from '../../utils';
import message from '../../utils/message';
import {
  updateMedicalProfile,
} from '../../store/slices/medicalProfileSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { colors } from '../../styles';

const EditIcon = (props) => <Icon {...props} name="edit-outline" />;

type Props = {
  profile?: MPProfile,
  /** non-patient users can possibly have access through the a consultation */
  editable?: boolean,
  children?: React.Node,
  style?: any,
  onEditButtonPress?: () => void,
};
const { Text } = Typography;
const { Option } = Select;

/**
 *
 * @param {Props} props Component's properties.
 */
const PersonalInformation = (props: Props) => {
  const { editable, style, profile } = props;
  const { t } = useTranslation();
  const [isEditing, setEditing] = useState(false);
  const consultation_id: ?number = profile?.consultationId;
  const patient_id = useSelector((state) => state.profile.user.id);
  const dispatch = useDispatch();
  const [isWeightWrong, setWeightWrong] = React.useState(false);
  const [isHeightWrong, setHeightWrong] = React.useState(false);

  const getUserRow = (key: string, value: string, displayValue: string, isEditing: boolean, cleanTop: boolean = false, selector: Component) => {
    const topStyle = cleanTop ? { marginTop: 0 } : {};
    const message = null;
    return (
      <>
        {!cleanTop ? <Divider /> : null}
        <div style={{
          ...styles.row,
          ...styles.input,
          ...topStyle,
        }}>
          <Text category={'s1'} style={styles.rowText}>
            {key}
          </Text>
          {isEditing ?
            (selector != null ? selector :
              <div style={{
                ...styles.column,
                ...styles.input,
              }}>
                <Input defaultValue={value} category={'p1'}{...value} />
                {isWeightWrong && key === t('Weight') ? <Text style={styles.errorMsg}>{t('Value on the weight is wrong please add a correct value')}</Text> : null}
                {isHeightWrong && key === t('Height') ? <Text style={styles.errorMsg}>{t('Value on the height is wrong please add a correct value')}</Text> : null}
              </div>
            ) :
            <Text category={'p1'}>
              {displayValue}
            </Text>}
        </div>
      </>
    );
  };


  const genderOpts = [
    { title: t('Man'), value: 'm' },
    { title: t('Woman'), value: 'f' },
  ];
  const bloodTypeOpts = [
    { title: t("I don't know"), value: 'n/a' },
    { title: 'A+', value: 'a+' },
    { title: 'A-', value: 'a-' },
    { title: 'B+', value: 'b+' },
    { title: 'B-', value: 'b-' },
    { title: '0+', value: '0+' },
    { title: '0-', value: '0-' },
    { title: 'AB+', value: 'ab+' },
    { title: 'AB-', value: 'ab-' },
  ];

  let existingBirthDate;
  if (profile?.birth_date != null) {
    existingBirthDate = moment(profile.birth_date).toDate();
  }

  const [birthDate, setBirthDate] = React.useState(existingBirthDate);
  const heightState = useInputState(
    `${profile?.height != null ? profile.height : ''}`,
  );
  const weightState = useInputState(
    `${profile?.weight != null ? profile.weight : ''}`,
  );

  const [selectedGenderIndex, setSelectedGenderIndex] = React.useState(
    genderOpts.findIndex((item) => item.value === profile?.gender),
  );
  const displayGenderValue = genderOpts[selectedGenderIndex]?.title;

  const [selectedBloodTypeIndex, setSelectedBloodTypeIndex] = React.useState(
    bloodTypeOpts.findIndex((item) => item.value === profile?.blood_type),
  );
  const displayBloodTypeValue =
    bloodTypeOpts[selectedBloodTypeIndex]?.title;
  const [savingErrorMeta, setSavingErrorMeta] = React.useState({});
  const [saving, setSaving] = React.useState(false);

  const now = new Date();
  const hundrendYearsAgo = new Date(
    now.getFullYear() - 100,
    now.getMonth(),
    now.getDate(),
  );

  const onDeleteBirthDate = () => setBirthDate(null);

  const fullname = getUserFullname(profile?.patient);
  let gender;
  if (profile?.gender === 'm') {
    gender = t('Man');
  } else if (profile?.gender === 'f') {
    gender = t('Woman');
  }

  const onSave = React.useCallback(async () => {
    setWeightWrong(false);
    setHeightWrong(false);
    setSaving(true);
    const data = removeKeysWithUndefinedValue({
      birth_date:
        birthDate != null
          ? moment(birthDate).format('YYYY-MM-DD')
          : birthDate === null
            ? null
            : undefined,
      gender: genderOpts[selectedGenderIndex]?.value || null,
      height: safeParseInt(heightState.value) || null,
      weight: safeParseInt(weightState.value) || null,
      blood_type: bloodTypeOpts[selectedBloodTypeIndex]?.value || null,
    });

    if (Object.keys(data).length === 0) {
      // ok, there is no change..
      // just go back

    } else {
      try {

        await dispatch(
          updateMedicalProfile({ patient_id, consultation_id, data }),
        ).then(unwrapResult);
        setEditing(false)
        setSaving(false);
      } catch (error) {
        setSaving(false);
        if (error.meta.weight != null) {
          setWeightWrong(true);
        }
        if (error.meta.height != null) {
          setHeightWrong(true);
        }
        message.error(error.message);
      }
    }
  });

  const bloodSelection = () => {
    return (
      <Select
        className={'fullWidth'}
        allowClear={true}
        selectedIndex={selectedBloodTypeIndex}
        placeholder={t('Select…')}
        defaultValue={displayBloodTypeValue || t('Select…')}
        value={displayBloodTypeValue || t('Select…')}
        status={savingErrorMeta.type != null ? 'danger' : null}
        caption={
          savingErrorMeta.type != null
            ? t('This field is required')
            : null
        }
        onChange={(selected, options) => {
          let index = bloodTypeOpts.findIndex(item => item.value === options?.key);
          setSelectedBloodTypeIndex(index)
        }}
        onSelect={(selected, options) => {
          let index = bloodTypeOpts.findIndex(item => item.value === options?.key);
          setSelectedBloodTypeIndex(index)
        }
        }>
        {bloodTypeOpts.map((item) => (
          <Option key={item.value} value={item.value}>{item.title}</Option>
        ))}
      </Select>
    );
  }

  const genderSelction = () => {
    return (
      <Select
        className={'fullWidth'}
        allowClear={true}
        selectedIndex={selectedGenderIndex}
        placeholder={t('Select…')}
        defaultValue={displayGenderValue || t('Select…')}
        value={displayGenderValue || t('Select…')}
        status={savingErrorMeta.type != null ? 'danger' : null}
        caption={
          savingErrorMeta.type != null
            ? t('This field is required')
            : null
        }
        onChange={(selected, options) => {
          let index = genderOpts.findIndex(item => item.value === options?.key);
          setSelectedGenderIndex(index)
        }}
        onSelect={(selected, options) => {
          let index = genderOpts.findIndex(item => item.value === options?.key);
          setSelectedGenderIndex(index)
        }
        }>
        {genderOpts.map((item) => (
          <Option key={item.value} value={item.value}>{item.title}</Option>
        ))}
      </Select>
    );
  }

  const birthdateSelection = () => {
    return (
      <DatePicker
        className={'fullWidth'}
        allowClear={true}
        placeholder={moment(profile?.birth_date).format('DD MMM YYYY')}
        onSelect={(nextDate) => setBirthDate(nextDate)}
        min={hundrendYearsAgo}
        max={now}
      />
    )
  }

  const bloodType =
    profile?.blood_type === 'n/a'
      ? t("I don't know")
      : profile?.blood_type != null
        ? `${profile?.blood_type}`.toUpperCase()
        : t('Not available', { context: 'medical record' });
  return (
    <div>
      <div className={"container profile-header"}>
        {fullname !== '' ? (
          <h1 className={'profile-title'}>{fullname}</h1>
        ) : null}
        <Button
          icon={<EditOutlined />}
          type="primary"
          size={'small'}
          onClick={() => {
            setWeightWrong(false);
            setHeightWrong(false);
            setEditing(!isEditing);
            weightState.onChange({ target: { value: profile?.weight } });
            heightState.onChange({ target: { value: profile?.height } });
            setSelectedGenderIndex(genderOpts.findIndex((item) => item.value === profile?.gender));
            setSelectedBloodTypeIndex(bloodTypeOpts.findIndex((item) => item.value === profile?.blood_type));
          }}
          style={styles.button}>
          {t('Edit')}
        </Button>
      </div>
      {
        getUserRow(
          t('Age'),
          null,
          profile?.birth_date != null
            ? t('{{years}} years old', {
              years: moment().diff(moment(profile?.birth_date), 'years'),
            })
            : t('Not available', { context: 'medical record' }),
          isEditing,
          fullname === '' && !editable,
          birthdateSelection(),
        )
      }
      {
        getUserRow(
          t('Gender'),
          null,
          gender || t('Not available', { context: 'medical record' }),
          isEditing,
          false,
          genderSelction()
        )
      }
      {
        getUserRow(
          t('Height'),
          heightState,
          profile?.height != null
            ? profile.height + ' cm'
            : t('Not available', { context: 'medical record' }),
          isEditing
        )
      }
      {
        getUserRow(
          t('Weight'),
          weightState,
          profile?.weight != null
            ? profile.weight + ' kg'
            : t('Not available', { context: 'medical record' }),
          isEditing
        )
      }
      { getUserRow(t('Blood type'), null, bloodType, isEditing, false, bloodSelection())}
      {
        isEditing ?
          <div className={"profile-save-button"}>
            <Button
              type="primary"
              size={'medium'}
              loading={saving}
              onClick={onSave}
              style={styles.button}>
              {t('Save')}
            </Button>
          </div> :
          null
      }
    </div>
  );
};

const styles = {
  container: {
    flexDirection: 'row'
  },
  userName: {
    textAlign: 'center',
    color: '#484848',
    marginBottom: 16,
  },
  button: {
    borderWidth: 0,
    backgroundColor: '#E9573D',
    marginTop: 16
  },
  row: {
    flexDirection: 'row',
    marginVertical: 18,
    display: 'flex',
    alignItems: 'center',
    minHeight: 47,
  },
  column: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
  input: {

  },
  rowText: {
    width: '20%'
  },
  errorMsg: {
    color: colors.brand
  }
};

export default PersonalInformation;
