//Basic redux toolkit imports
import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import * as NotificationsAPI from '../../api/notifications';
import { createSelector } from 'reselect';
// Async thunk to get the user notifications based on params
export const getUserNotifications = createAsyncThunk(
  'notifications/getUserNotifications',
  async (params, thunkAPI) => {
    try {
      const { data } = await NotificationsAPI.listUserNotifications({...params});
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
)
// Async thunk to get the number of the user notifiations
export const getUserNotificationsCount = createAsyncThunk(
  'notifications/getUserNotificationsCount',
  async (thunkAPI) => {
    try {
      const { data } = await NotificationsAPI.listUserNotifications({
        page_size: 1,
        read: 0
      });
      return data.count;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }

  }
);

// Async thunk to get any new/unread user notifications
export const getNewUserNotifications = createAsyncThunk(
  'notifications/getNewUserNotifications',
  async (thunkAPI) => {
    try {
      const { data } = await NotificationsAPI.listUserNotifications({
        page_size: 1,
        read: 0
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }

  }
);

// Async thunk to mark all notifications as read
export const markAllUserNotificationsAsRead = createAsyncThunk(
  'notifications/markAllAsRead',
  async (thunkAPI) => {
    try {
      const { data } = await NotificationsAPI.markAllUserNotificationsAsRead();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }

  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    list: [],
    userNotifications: [],
    fetchingUserNotifications: false,
    fetchedUserNotificationsOnce: false,
    unreadNotificationsCount: 0
  },
  reducers: {
    // TODO listen for auth/logout to init state
    markNotificationAsReadLocally: (state, { payload }) => {
      const _userNotification = {
        id: payload,
        read: true
      };
      const tmp = { ...state.userNotifications };
      if (tmp[_userNotification.id]) {
        tmp[_userNotification.id] = {
          ...tmp[_userNotification.id],
          ..._userNotification
        };
      } else {
        tmp[_userNotification.id] = _userNotification;
      }
      state.userNotifications = tmp;
    },
    markAllNotificationsAsReadLocally: (state) => {
      const tmp = { ...state.userNotifications };
      Object.values(tmp).forEach((notifObj) => {
        tmp[notifObj.id].read = true;
      });
      state.userNotifications = tmp;
    },
  },
  extraReducers: {
    [getUserNotifications.fulfilled]: (state, action) => {
      const page = action.meta?.arg?.page || 1;
      let list = [];
      const objs = {};
      const serverResultIDs = action.payload.results.map((res) => {
        objs[res.id] = {
          ...res
        };
        return res.id;
      });
      if (page > 1) {
        // keep the old ones.. probably it's because of pagination
        list = [...state.list];
      }
      list = list.concat(serverResultIDs);
      const s = new Set(list); // use `Set` in order to filter out any duplicate entries

      state.list = [...s];
      state.userNotifications = {
        ...state.userNotifications,
        ...objs
      };
      state.fetchingUserNotifications = false;
      state.fetchedUserNotificationsOnce = true;
    },
    [getUserNotificationsCount.fulfilled]: (state, { payload }) => {
      state.unreadNotificationsCount = payload;
    },
    [getNewUserNotifications.fulfilled]: (state, { payload }) => {
      const list = [...state.list];
      const objs = {};
      const serverResultIDs = payload.results.map((res) => {
        objs[res.id] = { ...res };
        return res.id;
      });
      const s = new Set([...serverResultIDs, ...list]); // use `Set` in order to filter out any duplicate entries

      state.list = [...s];
      state.userNotifications = {
        ...state.userNotifications,
        ...objs,
      };
      state.unreadNotificationsCount = payload.count;
    },
    [markAllUserNotificationsAsRead.fulfilled]: (state, { payload }) => {
      state.unreadNotificationsCount = 0;
    }

  }
});

export const getUnreadNotificationsCount = ({ notifications }) => notifications.unreadNotificationsCount;
export const userNotifications = ({ notifications }) => notifications.userNotifications;

const getNotificationsList = ({ notifications }) => notifications.list;

export const getAllUserNotifications = createSelector(
  [getNotificationsList, userNotifications],
  (list, notificationsObject) => {
    return list.map((_id) => notificationsObject[_id]);
  }
);

// Destructure and export the plain action creators
export const { markNotificationAsReadLocally, markAllNotificationsAsReadLocally } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
