// @flow
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Components imports
import { Button, Typography, Row, Col, Popover, Modal, Image } from 'antd';

import CallToAction from '../../components/CallToAction';
import Icon from '@ant-design/icons';

import { isMobile } from '../../utils';

import { CheckSquareOutlined, CalendarOutlined, HeartOutlined, LoginOutlined } from '@ant-design/icons';
import { ReactComponent as DoctorIcon } from '../../assets/images/home/doctor-icon.svg';
import NutritionistIcon from '../../assets/images/home/nutritionist-icon.png';
import ModalDietitianImage from '../../assets/images/home/Nutricion-header-image.jpg';
import AffideaLogo from '../../assets/images/home/affidea_logo.png';
import AppStoreIcon from '../../assets/icons/App-Store-Icon.svg';
import GooglePlayIcon from '../../assets/icons/Google-Play-Icon.svg';

import AppStoreIconEL from '../../assets/icons/app-store-el.svg';
import GooglePlayIconEL from '../../assets/icons/google-play-el.svg';

// Misc imports
import {
  NAV_APPOINTMENTS_DOCTORS,
  NAV_PROFILE,
  NAV_SYMPTOM_CHECKER,
  GOOGLE_PLAY_APP_URL,
  APP_STORE_APP_URL,
  DIETITIAN_PHONE,
} from '../../constants';
import { openCallInitializationModal } from '../../store/slices/applicationSlice';
import { isUserAuthenticated } from '../../store/slices/authSlice';

// Const declarations
const { Text, Title } = Typography;
const baseClassName = 'home-screen';

const HomeScreen = (): React$Element<any> => {
  const { t, i18n } = useTranslation();
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isUserAuthenticated);
  const heroStyle = !isAuthenticated ? { minHeight: '500px' } : null;

  const content = (
    <div>
      <div className={`parentImages`}>
        <img alt="" className={`imageOne`} src={ModalDietitianImage} width={600} />
        <img alt="" className={`imageTwo`} src={AffideaLogo} width={80} />
      </div>
      <br />
      <Trans style={{ textAlign: 'center', marginRight: 28 }}>
        <div style={{ marginRight: 8, width: 600 }}>
          Get professional support to deal with ailments directly related to
          your dietary choices, control your weight and adopt a healthier
          lifestyle.<br /> <br /> Find out about the privileged service packages by calling for
          availability and appointments at
          <Text category={'p1'} style={{ fontWeight: 'bold' }} >
            {' '} {{ DIETITIAN_PHONE }}, Monday – Friday, 09:00 - 21:00
          </Text>
        </div>
      </Trans>
    </div>
  );
  return (
    <div className={`${baseClassName}`}>
      {/* HERO SECTION */}
      <div className={`container-fluid ${baseClassName}__hero-section`} style={heroStyle}>
        <div className="container">
          <Row gutter={15}>
            <Col xl={10} lg={12} md={12}>
              <Text className="d-block mb-3">{t('Hero section copy')}</Text>
              {!isAuthenticated ? (
                <div style={{ flex: 1, textAlign: 'center' }}>
                  <Button
                    className="mb-3 mobile-btn"
                    type="primary"
                    size="large"
                    icon={<LoginOutlined />}
                    onClick={() => loginWithRedirect()}>
                    {t('Log in')}
                  </Button>
                  <a
                    href="https://www.youtube.com/watch?v=TM_dHNeIH0c"
                    target="_blank"
                    rel="noopener noreferrer"
                  > {t('Watch the video')}
                  </a>
                </div>
              ) : (
                <>
                  <Button
                    className="mb-3"
                    type="primary"
                    size="large"
                    onClick={() => dispatch(openCallInitializationModal())}>
                    <Icon component={DoctorIcon} size={60} />
                    {t('Call now a doctor')}
                  </Button>
                  <Button
                    icon={<CheckSquareOutlined />}
                    className="custom-secondary mb-3"
                    size="large"
                    onClick={() => history.push(NAV_SYMPTOM_CHECKER)}
                  >
                    {t('Check your symptoms')}
                  </Button>
                  <div className={`${baseClassName}__cta-area`}>
                    <CallToAction
                      icon={<CalendarOutlined />}
                      title={t('Make an appointment')}
                      onClick={() => history.push(NAV_APPOINTMENTS_DOCTORS)}
                    />
                    <CallToAction
                      icon={<HeartOutlined />}
                      title={t('Update your medical profile')}
                      onClick={() => history.push(NAV_PROFILE)}
                    />
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {/* END OF HERO SECTION */}

      {/* NUTRITIONIST SECTION */}
      {isAuthenticated ? (
        <div className={`container-fluid ${baseClassName}__nutritionist-banner text-center`}>
          <Row justify="center">
            <Col xl={6} lg={12}>
              <Title level={5}>{t('Adopt healthy eating habits under the guidance of a nutritionist.')}</Title>
              <Button
                icon={<img src={NutritionistIcon} alt="nutritionist-icon" />}
                className="nutritionist-btn"
                onClick={() => Modal.info({ title: t('Book an appointment with a dietitian'), content, centered: true, width: 740, okButtonProps: { style: { marginRight: 38 } } })}
              >
                {t('Book an appointment')}
              </Button>

            </Col>
          </Row>
        </div>
      ) : null
      }
      {/* END OF NUTRITIONIST SECTION  */}

      {/* MOBILE APP SECTION */}
      <div className={`container-fluid ${baseClassName}__stores text-center`}>
        <Row justify="center">
          <Col xl={6} lg={12} xs={12}>
            <Title level={5}>{t('Download the mobile application')}</Title>
            <Row gutter={6}>
              <Col md={12}>
                <a
                  href={APP_STORE_APP_URL}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img className="mr-sm-2 mr-md-3 mb-3 w-100" src={i18n.language.includes('el') ? AppStoreIconEL : AppStoreIcon} alt="appstore-icon" />
                </a>
              </Col>
              <Col md={12}>
                <a
                  href={GOOGLE_PLAY_APP_URL}
                  target="_blank" rel="noopener noreferrer"
                >
                  <img className="mb-3 w-100" src={i18n.language.includes('el') ? GooglePlayIconEL : GooglePlayIcon} alt="googleplay-icon" />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* MOBILE APP SECTION */}
    </div >
  )

}

export default HomeScreen;
