// @flow
import * as React from 'react';
import {
  Button,
  Select,
  Spin,
  Input,
  Modal,
  Popconfirm,
} from 'antd';
import FilesManager from '../filesManager/FilesManager';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMedicalProfileResourceItem,
  updateMedicalProfileResourceItem,
  deleteMedicalProfileResourceItem,
  selectors
} from '../../store/slices/medicalProfileSlice';
import { useFilesManagerHandler } from '../../hooks/useFilesManagerHandler';
import { useInputState } from '../../hooks/useInputState';
import { removeKeysWithUndefinedValue } from '../../utils';
import type { MPAllergy, MPResourceChoiceObject } from '../../config/types-mp';
import EditRow from '../editRow/EditRow';
import message from '../../utils/message';
import { unwrapResult } from '@reduxjs/toolkit';

const { Option } = Select;
const { TextArea } = Input;

const LoadingIndicator = (props) => (
  <div style={props.style}>
    <Spin size={'small'} />
  </div>
);

const MPEditAllergyItem = (props: Props) => {
  if (props?.title == null) {
    console.warn(
      'MPEditAllergyScreen expects "title" to be passed as navigation parameters',
    );
  }
  const { t } = useTranslation();
  const allergy: MPAllergy = props?.dataItem || {};
  const readOnly: boolean = Boolean(props?.readOnly);
  const consultation_id: ?number = props?.consultationId;
  const patient_id = useSelector((state) => state.profile.user.id);
  const itemInfo: Object = useSelector((state) =>
    selectors.getOptionsForResourceItem(state.medical_profile, 'allergies'),
  );
  const dispatch = useDispatch();

  const typeChoices: Array<MPResourceChoiceObject> =
    itemInfo?.type?.choices || [];

  const notesInput = React.useRef(null);
  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [savingErrorMeta, setSavingErrorMeta] = React.useState({});
  const {
    files,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  } = useFilesManagerHandler(allergy.files);

  // MEDICATION PROPERTY related --- type
  const [selTypeIndex, setSelTypeIndex] = React.useState(
    typeChoices.findIndex((item) => item.value === allergy.type),
  );
  const displayDurationValue = typeChoices[selTypeIndex]?.display_name;

  // MEDICATION PROPERTY related --- text props
  console.log('allergy', allergy);
  const notesState = useInputState(allergy?.notes);

  const onSave = React.useCallback(async () => {
    const data = removeKeysWithUndefinedValue({
      notes: notesState.value === '' ? null : notesState.value,
      type: typeChoices[selTypeIndex]?.value,
      files,
    });

    if (Object.keys(data).length === 0) {
      // ok, there is no change..
      // just go back
      props.onCloseModal(false);
    } else {
      try {
        setSaving(true);
        setSavingErrorMeta({});
        if (allergy.id) {
          await dispatch(
            updateMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              itemId: allergy.id,
              data,
              resource: 'allergies',
            }),
          ).then(unwrapResult);
        } else {
          await dispatch(
            createMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              data,
              resource: 'allergies',
            }),
          ).then(unwrapResult);
        }
        props.onCloseModal(false);
      } catch (error) {
        setSaving(false);
        setSavingErrorMeta(error.meta);
        message.error(error.message);
      }
    }
  }, [
    notesState,
    files,
    selTypeIndex,
    typeChoices,
    patient_id,
    consultation_id,
    allergy,
    t,
    dispatch,
  ]);

  const trySave = React.useCallback(() => {
    continueWithFilesUploadCaution()
      .then(onSave)
      .catch((_) => console.log('user cancelled'));
  }, [continueWithFilesUploadCaution, onSave]);

  const onDelete = () => {
    try {
      setDeleting(true);
      if (allergy.id != null) {
        dispatch(
          deleteMedicalProfileResourceItem({
            patient_id,
            consultation_id,
            itemId: allergy.id,
            resource: 'allergies',
          }),
        ).then(unwrapResult)
          .then(() => {
            props.onCloseModal(false);
          });
      }
    } catch (error) {
      setDeleting(false);
      message.error(error.message);
    }
  };

  return (
    <Modal
      closable={false}
      maskClosable={false}
      title={t('Allergies')}
      className={'profile-modal'}
      okText={t('Save')}
      centered={true}
      confirmLoading={saving}
      bodyStyle={styles.modalBackground}
      cancelText={t('Cancel')}
      onOk={trySave}
      onCancel={() => props.onCloseModal(!props.visible)}
      visible={props.visible}>
      <EditRow
        text={t('Type', { context: 'medical profile measurement' })}
        value={displayDurationValue}>
        {!readOnly ? (
          <Select
            className={'fullWidth'}
            selectedIndex={selTypeIndex}
            placeholder={t('Select…')}
            defaultValue={displayDurationValue || t('Select…')}
            value={displayDurationValue || t('Select…')}
            status={savingErrorMeta.type != null ? 'danger' : null}
            caption={
              savingErrorMeta.type != null
                ? t('This field is required')
                : null
            }
            onChange={(selected, options) => {
              let index = typeChoices.findIndex(item => item.value === options.key);
              setSelTypeIndex(index)
            }}
            onSelect={(selected, options) => {
              let index = typeChoices.findIndex(item => item.value === options.key);
              setSelTypeIndex(index)
            }
            }>
            {typeChoices.map((item) => (
              <Option key={item.value} value={item.value}>{item.display_name}</Option>
            ))}
          </Select>
        ) : null}
      </EditRow>
      <EditRow
        text={t('Notes', { context: 'medical profile examination' })}
        value={notesState.value}>
        {!readOnly ? (
          <TextArea
            ref={notesInput}
            rows={4}
            value={notesState.value}
            disabled={readOnly}
            style={styles.input}
            textStyle={styles.notesText}
            {...notesState} />
        ) : null}
      </EditRow>
      <EditRow text={t('Attachments')}>
        <FilesManager
          autoUploadNewFiles={true}
          editable={!readOnly}
          files={allergy.files}
          onFilesUpdated={onFilesUpdated}
        />
      </EditRow>
      {
        allergy.id != null && !readOnly ? (
          <Popconfirm
            placement="bottom"
            title={t('Are you sure you want to delete this allergy?')}
            onConfirm={onDelete}
            okText={t('Yes')}
            cancelText={t('No')}>
            <Button
              style={styles.deleteButton}
              loading={deleting}
              size={'middle'}
            >
              {t('Delete allergy')}
            </Button>
          </Popconfirm>
        ) : null
      }
    </Modal>
  );
};

const styles = {
  container: {
    flex: 1,
    paddingLeft: 16,
  },
  toolbar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  input: {
    backgroundColor: '#ffffff',
  },
  inputText: {},
  notesText: {
    minHeight: 80,
    textAlignVertical: 'top',
  },
  checkbox: {
    alignSelf: 'flex-start',
  },
  deleteButton: {
    alignSelf: 'center',
    marginVertical: 24,
  },
  modalBackground: {
    backgroundColor: 'rgba(72, 72, 72, 0.1)',
  }
};

export default MPEditAllergyItem;
