import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  createAppointment,
  updateAppointment as api_updateAppointment,
  getAppointment,
  listAppointments,
} from '../../api/appointments';
import { logout } from './authSlice';
import { getCalculatedStatusForAppointment } from '../../utils/appointments';
import { getConsultationById } from '../commonAsyncThunks';
import { createNewConsultation, updateConsultationWithData } from './consultationSlice';
import moment from 'moment';

const getAppointmentById = createAsyncThunk(
  'appointments/getAppointment',
  async (data, thunkAPI) => {
    try {
      const response = await getAppointment(data);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const getAppointments = createAsyncThunk(
  'appointments/getAppointmentsList',
  async (data, thunkAPI) => {
    try {
      const response = await listAppointments(data);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const createNewAppointment = createAsyncThunk(
  'appointments/createAppointment',
  async (data, thunkAPI) => {
    try {
      const response = await createAppointment(data);
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const updateAppointment = createAsyncThunk(
  'appointments/updateAppointment',
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await api_updateAppointment(id, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const getNewAppointmentsCount = createAsyncThunk(
  'appointments/getNewAppointmentsCount',
  async (data, thunkAPI) => {
    try {
      const response = await listAppointments({
        start__gte: new Date().toISOString(),
        page_size: 1,
        status: 'new',
      });
      return response.data;
    } catch (error) {
      console.warn(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
)

export const initialState = {
  list: [],
  appointments: {},
  fetchingAppointments: false,
  fetchedAppointmentsOnce: false,
  newAppointmentsCount: 0,
  current: {}
};

const newConsultationInfoHandler = (state, action) => {
  const consultation = action.payload || {};
  const appointmentId = consultation.appointment;

  if (
    appointmentId == null ||
    (appointmentId != null && state.appointments[appointmentId] == null)
  ) {
    return ;
  }

  const tmp = { ...state.appointments };
  const existingAppointment = tmp[appointmentId];
  tmp[appointmentId] = {
    ...existingAppointment,
    consultation: {
      ...existingAppointment.consultation,
      ...consultation,
    },
  };

  state.appointments = tmp;
};

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {},
  extraReducers: {
    [getAppointments.pending]: (state, action) => {
      state.fetchingAppointments = true;
    },
    [getAppointments.rejected]: (state, action) => {
      console.log('state: ', state);
      state.fetchingAppointments = false;
    },
    [getAppointments.fulfilled]: (state, action) => {
      const page = action.meta?.arg?.page || 1;
      let list = [];
      const objs = {};
      const serverResultIDs = action.payload.results.map((res) => {
        objs[res.id] = {
          ...res,
        };
        return res.id;
      });
      if (page > 1) {
        // keep the old ones.. probably it's because of pagination
        list = [...state.list];
      }
      list = list.concat(serverResultIDs);
      const s = new Set(list); // use `Set` in order to filter out any duplicate entries
      state.list = [...s];
      state.appointments = { ...state.appointments, ...objs };
      state.fetchingAppointments = false;
      state.fetchedAppointmentsOnce = true;
    },
    [createNewAppointment.fulfilled]: (state, action) => {
      const obj = action.payload || {};
      state.appointments = {
        ...state.appointments,
        [obj.id]: {
          ...obj,
        }
      };
      state.list = [obj.id, ...state.list];
    },
    [getNewAppointmentsCount.fulfilled]: (state, action) => {
      state.newAppointmentsCount = action.payload.count;
    },
    [getAppointmentById.fulfilled]: (state, action) => {
      console.log('action', action);
      const _appointment = { ...action.payload };

      const tmp = { ...state.appointments };
      tmp[_appointment.id] = _appointment;

      let tmpList = [...state.list];
      if (!tmpList.includes(_appointment.id)) {
        tmpList = [_appointment.id, ...tmpList];
      }

      state.list = tmpList;
      state.appointments = tmp;
      state.current = _appointment;
    },
    [getAppointmentById.rejected]: (state, action) => {
      console.log('getConsultationById.rejected');
      console.log(action.error.message);
      throw new Error(action.error.message);
    },
    [createNewConsultation.fulfilled]: newConsultationInfoHandler,
    [getConsultationById.fulfilled]: newConsultationInfoHandler,
    [updateConsultationWithData.fulfilled]: newConsultationInfoHandler,
    [updateAppointment.fulfilled]: (state, action) => {
      const _appointment = { ...action.payload };

      const tmp = { ...state.appointments };
      if (tmp[_appointment.id]) {
        tmp[_appointment.id] = {
          ...tmp[_appointment.id],
          ..._appointment,
        };
      } else {
        tmp[_appointment.id] = _appointment;
      }

      if (state.current?.id === _appointment.id) {
        state.current = _appointment;
      }
      state.appointments = tmp;
    },
    [logout]: (state) => {
      console.log('logout');
      console.log('state: ', state);
      console.log('initialState: ', initialState);
      state = initialState;
    },
  }
});

const getAppointmentsObject = ({ appointments }) => appointments.appointments;
const getAppointmentsStateList = ({ appointments }) => appointments.list;
export const getLocalAppointment = (id) => ({ appointments }) => appointments.appointments[id];

export const getAppointmentsList = createSelector(
  [getAppointmentsStateList, getAppointmentsObject],
  (list, appointmentsObject) => {
    if(!list) {
      return [];
    }
    return list.map((_id) => appointmentsObject[_id]);
  },
);

export const getAppointmentsStartingNow = createSelector(
  [getAppointmentsStateList, getAppointmentsObject],
  (list, appointmentsObject) => {
    if(!list) {
      return [];
    }
    return list
      .map((_id) => appointmentsObject[_id])
      .filter((appointment) => {
        const status = getCalculatedStatusForAppointment(appointment);
        const appointmentIsNow =
          status === 'confirmed' &&
          moment().isSameOrAfter(appointment?.start) &&
          moment().isSameOrBefore(appointment?.max_call_availability);
        const shouldCallDoctorNow =
          appointmentIsNow && appointment?.type === 'online';
        return shouldCallDoctorNow;
      });
  },
);

export { getAppointments, getNewAppointmentsCount, getAppointmentById, createNewAppointment, updateAppointment };

export const appointmentsReducer = appointmentsSlice.reducer;
