// @flow
import React, { useState } from 'react';
import { Alert } from 'antd';
import {
  useDevices,
  useVideoContext,
} from '../../../../plugins/video_call/hooks';
import { translate } from '../../../../locales';

export function getSnackbarContent(
  hasAudio: boolean,
  hasVideo: boolean,
  error?: Error,
) {
  let headline = '';
  let message = '';

  // eslint-disable-next-line default-case
  switch (true) {
    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = translate('Unable to Access Media:');

      if (error.message === 'Permission denied by system') {
        // Chrome only
        message =
          translate('The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.');
      } else {
        message =
          translate('The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.');
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = translate('Cannot Find Microphone or Camera:');
      message =
        translate('The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.');
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = translate('Error Acquiring Media:');
      message = `${error.name} ${error.message}`;
      break;

    case !hasAudio && !hasVideo:
      headline = translate('No Camera or Microphone Detected:');
      message =
        translate('Other participants in the room will be unable to see and hear you.');
      break;

    case !hasVideo:
      headline = translate('No Camera Detected:');
      message = translate('Other participants in the room will be unable to see you.');
      break;

    case !hasAudio:
      headline = translate('No Microphone Detected:');
      message = translate('Other participants in the room will be unable to hear you.');
  }

  return {
    headline,
    message,
  };
}

const styles = {
  container: {
    margin: '24px',
  },
};

export default function MediaErrorAlert({ error }: { error?: Error }) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent(
    hasAudioInputDevices,
    hasVideoInputDevices,
    error,
  );

  if (!isSnackbarOpen) {
    return null;
  }

  return (
    <Alert
      style={styles.container}
      message={headline}
      description={message}
      type="error"
      closable
      onClose={() => setIsSnackbarDismissed(true)}
    />
  );
}
