// @flow
import React from 'react';
import { Route } from 'react-router-dom';

type Props = {
  component: Object | Function,
  isAuthenticated?: boolean,
  location?: Location
};

const PublicRoute = (props: Props): React$Element<any> => {
  const { component: Component, ...rest } = props;
  return (
    <Route {...rest} render={props => <Component {...props} />} />
  );
};

export default PublicRoute;
