import { useEffect, useState } from 'react';
import { useCurrentConsultation } from '../../../hooks/useCurrentConsultation';

/**
 * Whether or not a specific participant is reconnecting
 *
 * @export
 * @param {Participant} participant
 * @returns
 */
export default function useParticipantDisplayInfo(participant) {
  const [name, setName] = useState(participant.identity);
  const [subtitle, setSubtitle] = useState(null);
  const { consultation } = useCurrentConsultation();

  useEffect(() => {
    let newName = participant.identity;
    let newSubtitle = null;

    const identityParts = participant.identity.split('_');
    if (identityParts.length === 2) {
      const tmp = consultation?.participants?.find((p) => `${p.user.id}` === identityParts[1]);
      if (tmp) {
        const tmpName = `${tmp.user.first_name || ''} ${tmp.user.last_name || ''}`.trim();
        if (tmpName) {
          newName = tmpName;
        }
        newSubtitle = tmp.user.details?.speciality;
      }
    }

    setName(newName);
    setSubtitle(newSubtitle);
  }, [participant, consultation]);

  return {
    name,
    subtitle
  };
}
