// @flow
import React from 'react';
import { Typography, message } from 'antd';
import { ERROR_APPEARANCE_DURATION } from '../constants';
import { CloseCircleOutlined } from '@ant-design/icons';
import { colors } from '../styles';
import type { Node } from 'react';

const { Text } = Typography;
// const closeButtonUnicode = '\u{2716}'; // &#10006; translates to -> ✖
// const nbsp = '\u{A0}'; // &nbsp; translates to a space

export interface ConfigProps {
  /**
   * Customized CSS class
   */
  className: string;
  /**
   * The content of the message
   */
  content: Node;
  /**
   * Time(seconds) before auto-dismiss, don't dismiss if set to 0	number. Default - 3.
   */
  duration: Number;
  /**
   * Customized Icon	ReactNode
   */
  icon: Node;
  /**
   * The unique identifier of the Message
   */
  key: string | number;
  /**
   * Customized inline style (CSSProperties).
   *
   * @example
   * { marginTop: '60px' }
   */
  style: any;
  /**
   * Specify a function that will be called when the message is clicked
   */
  onClick: Function;
  /**
   * Specify a function that will be called when the message is closed
   */
  onClose: Function;
}

const getCustomizedConfigForParam = (param) => {
  if (typeof param === 'string') {
    const key = Math.floor(100000 + Math.random() * 900000);
    return {
      key,
      content: (
        <>
          <Text>{param}</Text>
          <CloseCircleOutlined
            style={{ marginLeft: '8px', color: colors.brand_dark }}
            onClick={() => message.destroy(key)}
          />
        </>
      ),
      duration: ERROR_APPEARANCE_DURATION,
    };
  }
  return param;
};

const _message = {
  show: (param: string | ConfigProps) => {
    message.open(getCustomizedConfigForParam(param));
  },
  info: (param: string | ConfigProps) => {
    message.info(getCustomizedConfigForParam(param));
  },
  error: (param: string | ConfigProps) => {
    message.error(getCustomizedConfigForParam(param));
  },
  success: (param: string | ConfigProps) => {
    message.success(getCustomizedConfigForParam(param));
  },
  warning: (param: string | ConfigProps) => {
    message.warning(getCustomizedConfigForParam(param));
  },
  loading: (param: string | ConfigProps) => {
    message.loading(getCustomizedConfigForParam(param));
  },
};

export default _message;
