// @flow
import * as React from 'react';
import {
  Button,
  DatePicker,
  Popconfirm,
  Select,
  Spin,
  Modal,
} from 'antd';
import FilesManager from '../filesManager/FilesManager';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMedicalProfileResourceItem,
  updateMedicalProfileResourceItem,
  deleteMedicalProfileResourceItem,
  measurementSelectors
} from '../../store/slices/medicalProfileSlice';
import { useFilesManagerHandler } from '../../hooks/useFilesManagerHandler';
import { unwrapResult } from '@reduxjs/toolkit'

import MPEditMeasurementValues from './MPEditMeasurementValues';
import moment from 'moment';
import type {
  MPMeasurement,
  MPResourceChoiceObject,
} from '../../config/types-mp';
import EditRow from '../editRow/EditRow';
import { removeKeysWithUndefinedValue } from '../../utils';
import message from '../../utils/message';

const { Option } = Select;

const MPEditMeasurement = (props: Props) => {
  if (props?.title == null) {
    console.warn(
      'MPEditMeasurementScreen expects "title" to be passed as navigation parameters',
    );
  }
  const { t } = useTranslation();
  const measurement: MPMeasurement = props?.dataItem || {};
  const readOnly: boolean = Boolean(props?.params?.readOnly);
  const consultation_id: ?number = props?.params?.consultationId;
  const patient_id = useSelector((state) => state.profile.user.id);
  const types: Array<MPResourceChoiceObject> = useSelector((state) =>
    measurementSelectors.getMeasurementTypes(state.medical_profile),
  );
  const dispatch = useDispatch();

  const valuesComp = React.useRef(null);
  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const {
    files,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  } = useFilesManagerHandler(measurement.files);

  // TODO: handle time instead of handling only the date
  let measurementDate;
  if (measurement.date != null) {
    measurementDate = moment(measurement.date).toDate();
  } else {
    measurementDate = new Date();
  }
  const [date, setDate] = React.useState(measurementDate);
  const [values, setValues] = React.useState(measurement.values || []);

  const [selTypeIndex, setSelTypeIndex] = React.useState(
    types.findIndex((item) => item.value === measurement.type),
  );
  const displayTypeValue = types[selTypeIndex]?.display_name;
  const typeValue = types[selTypeIndex]?.value;
  const now = new Date();
  const hundrendYearsAgo = new Date(
    now.getFullYear() - 100,
    now.getMonth(),
    now.getDate(),
  );

  const onSave = React.useCallback(async () => {
    const data = removeKeysWithUndefinedValue({
      date: date ? moment(date).format() : undefined,
      type: types[selTypeIndex]?.value,
      values: valuesComp.current?.getValues() || [],
      files,
    });

    if (Object.keys(data).length === 0) {
      // ok, there is no change..
      // just go back
      props.onCloseModal(false);
    } else {
      try {
        setSaving(true);
        if (measurement.id) {
          const response = await dispatch(
            updateMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              itemId: measurement.id,
              data,
              resource: 'measurements',
            }),
          ).then(unwrapResult);
        } else {
          await dispatch(
            createMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              data,
              resource: 'measurements',
            }),
          ).then(unwrapResult);
        }
        props.onCloseModal(false);
      } catch (error) {
        setSaving(false);
        message.error(error.message);
      }
    }
  }, [
    date,
    types,
    files,
    valuesComp,
    selTypeIndex,
    patient_id,
    consultation_id,
    measurement,
    t,
    dispatch,
  ]);

  const trySave = React.useCallback(() => {
    continueWithFilesUploadCaution()
      .then(onSave)
      .catch((_) => console.log('user cancelled'));
  }, [continueWithFilesUploadCaution, onSave]);

  const onDelete = () => {
    try {
      setDeleting(true);
      if (measurement.id != null) {
        dispatch(
          deleteMedicalProfileResourceItem({
            patient_id,
            consultation_id,
            itemId: measurement.id,
            resource: 'measurements',
          }),
        ).then(unwrapResult)
          .then(() => {
            props.onCloseModal(false);
          });
      }
    } catch (error) {
      setDeleting(false);
      message.error(error.message);
    }
  };

  return (
    <Modal
      closable={false}
      maskClosable={false}
      className={'profile-modal'}
      okText={t('Save')}
      centered={true}
      title={t('Examinations')}
      bodyStyle={styles.modalBackground}
      cancelText={t('Cancel')}
      onOk={trySave}
      confirmLoading={saving}
      onCancel={() => props.onCloseModal(!props.visible)}
      visible={props.visible}>
      <EditRow
        text={t('Type', { context: 'medical profile measurement' })}
        value={displayTypeValue}>
        {!readOnly ? (
          <Select
            className={'fullWidth'}
            disabled={measurement.id != null}
            selectedIndex={selTypeIndex}
            placeholder={t('Select…')}
            value={displayTypeValue || t('Select…')}
            defaultValue={displayTypeValue || t('Select…')}
            onSelect={(selected, options) => {
              console.log('options', options);
              let index = types.findIndex(item => {
                return item.value === options.key
              });
              setSelTypeIndex(index);
            }}
            onChange={(selected, options) => {
              let index = types.findIndex(item => item.value === options.key);
              setSelTypeIndex(index)
            }}
          >
            {types.map((item) => (
              <Option key={item.value} value={item.value}>{item.display_name}</Option>
            ))}
          </Select>
        ) : null}
      </EditRow>
      <EditRow
        text={t('Date', { context: 'medical profile measurement' })}>
        {!readOnly ? (
          <DatePicker
            className={'fullWidth'}
            placeholder={moment(date).format('ddd, DD MMM YYYY')}
            controlStyle={styles.input}
            format={'ddd, DD MMM YYYY'}
            onSelect={(nextDate) => setDate(nextDate)}
            min={hundrendYearsAgo}
            max={now}
          />
        ) : null}
      </EditRow>
      <MPEditMeasurementValues
        ref={valuesComp}
        type={typeValue}
        values={values}
        readOnly={readOnly}
        onValuesChanged={(newVals) => setValues(newVals)}
      />
      <EditRow text={t('Attachments')}>
        <FilesManager
          autoUploadNewFiles={true}
          editable={!readOnly}
          files={measurement.files}
          onFilesUpdated={onFilesUpdated}
        />
      </EditRow>
      {measurement.id != null && !readOnly ? (
        <Popconfirm
          placement="bottom"
          title={t('Are you sure you want to delete this measurement?')}
          onConfirm={onDelete}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Button
            style={styles.deleteButton}
            loading={deleting}
            size={'middle'}
          >
            {t('Delete measurement')}
          </Button>
        </Popconfirm>
      ) : null}
    </Modal>
  );
};

const styles = {
  container: {
    flex: 1,
    paddingLeft: 16,
  },
  toolbar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  input: {
    backgroundColor: '#ffffff',
  },
  deleteButton: {
    alignSelf: 'center',
    marginVertical: 24,
  },
  modalBackground: {
    backgroundColor: 'rgba(72, 72, 72, 0.1)'
  }
};

export default MPEditMeasurement;
