// @flow
import React from 'react';

type Props = {
  text: string,
};

/**
 * A component that respects the line change (\n).
 * Use it when you need to render a string that includes a **\n**
 * character but doesn't change to a new line.
 *
 * @param {*} props Only needs a `text` prop
 * @returns
 */
const NewLineText = (props: Props): any => {
  const { text } = props;
  return text ? <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span> : null;
};

export default NewLineText;
