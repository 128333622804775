//@flow
import { createAxiosInstance } from './_axios';
import { getAllDefaultHeaders } from './lib/defaults';
import type { ApiResponseArray, File } from '../config/types';

const filesApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 *
 *
 * @param {number} fileId
 * @param {boolean} [minified=false]
 * @returns {string}
 */
export const getUrlForFile = (
  fileId: number,
  minified: boolean = false,
): string => {
  const suffix = minified ? '/minified' : '';
  return `api/uploads/${fileId}${suffix}`;
};

/**
 * Uploads a new file to the server.
 *
 * @param {{ uri: string, type: string, fileName?: string }} [{
 *   uri,
 *   type,
 *   fileName,
 *   blob,
 * }={}]
 * @returns {Promise<File>}
 */
export const uploadFile = ({
  uri,
  type,
  fileName,
  blob,
}: { uri?: string, type?: string, fileName?: string, blob?: Object } = {}): Promise<{data: File}> => {
  const data = new FormData();

  if (blob) {
    data.append('file', blob);
  } else {
    // $FlowFixMe
    data.append('file', {
      name: fileName || (type?.startsWith('image') ? 'pic.jpg' : 'file.pdf'),
      type: type,
      uri,
    });
  }

  const defaultHeaders = getAllDefaultHeaders();
  defaultHeaders['Content-Type'] = 'multipart/form-data';
  delete defaultHeaders.Content;

  return filesApi.post('api/uploads', data, { headers: defaultHeaders });
};

/**
 * Lists all files that the "current" user has uploaded.
 *
 * @returns {Promise<ApiResponseArray<File>>}
 */
export const listFiles = (): Promise<ApiResponseArray<File>> =>
  filesApi.get('api/uploads');

/**
 * Retrieves the actual file with a specific id (not the json info.. the file).
 *
 * @param {number} fileId
 * @param {boolean} [minified=false]
 * @returns {Promise<File>}
 */
export const getFile = (
  fileId: number,
  minified: boolean = false,
): Promise<any> => filesApi.get(getUrlForFile(fileId, minified));

/**
 * Retrieves info for a specific file
 *
 * @param {number | string} fileId
 * @returns {Promise<File>}
 */
export const getFileMeta = (fileId: number | string): Promise<any> =>
  filesApi.get(`api/uploads/${fileId}/meta`);

/**
 * Deletes a specific file.
 *
 * @param {number} fileId
 */
export const deleteFile = (fileId: number): Promise<any> =>
  filesApi.delete(`api/uploads/${fileId}`);
