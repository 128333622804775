// @flow
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Components imports
import MainMenu from '../MainMenu';
import LoginButton from '../LoginButton';
import { Layout, Row, Col, Button, Drawer } from 'antd';
import Logo from '../../assets/images/e_doctor_horizontal_red@3x.png';
import { MenuOutlined } from '@ant-design/icons';

// Misc imports
import { NAV_ROOT } from '../../constants';

// Constant declarations
import { APP_VERSION } from '../../constants';
import { isMobile } from '../../utils';
const { Header } = Layout;
const baseClassName = 'header-bar';

type Props = {
  isAuthenticated: boolean,
  acceptedTermsOfService: boolean
};

const MainMenuWrapper = (props: Props): React$Element<any> => {
  const { isAuthenticated, acceptedTermsOfService=true } = props;

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Header className={`${baseClassName}__wrapper clearfix`}>
      <div className="container">
        <Row justify="space-between">
          <Col lg={4} xs={16}>
            <div className={`${baseClassName}__logo`}>
              <Link
                to={{
                  pathname: NAV_ROOT
                }}>
                <img src={Logo} alt="my_eDoctor_logo"/>
              </Link>
            </div>
          </Col>

          {
            acceptedTermsOfService
              ? <>
                <Col Col lg={20} xs={0} className="as-c text-right">
                  {
                    isAuthenticated
                      ? <MainMenu mode="horizontal" onItemSelected={onClose} />
                      : <LoginButton />
                  }
                </Col>
                <Col lg={0} xs={8} className="as-c">
                  {(!isMobile || (isMobile && isAuthenticated)) && (
                    <Button
                      className={`${baseClassName}__drawer-trigger right`}
                      type="link"
                      icon={<MenuOutlined />}
                      onClick={showDrawer}
                    />
                  )}
                  { isAuthenticated
                    ? <Drawer
                      // title="Basic Drawer"
                      placement="right"
                      closable={false}
                      onClose={onClose}
                      visible={visible}
                    >
                      <MainMenu mode="inline" onItemSelected={onClose} />
                      <div className="app-version">{APP_VERSION}</div>
                    </Drawer>
                    : <Drawer>
                      <LoginButton />
                      <div className="app-version">{APP_VERSION}</div>
                    </Drawer>
                  }
                </Col>
              </>
              : null
          }

        </Row>
      </div>
    </Header>
  )
}

export default MainMenuWrapper;
