// @flow
import { useEffect, useState } from 'react';
import useVideoContext from './useVideoContext';

export type RoomStateType = 'disconnected' | 'connected' | 'reconnecting';

/**
 * Returns the state of the current room. Possible values are: 'disconnected' | 'connected' | 'reconnecting'
 *
 * @export
 * @returns {'disconnected' | 'connected' | 'reconnecting'} The rooms state
 */
export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState('disconnected');

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state);
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
  }, [room]);

  return state;
}
