// @flow
import React, { createContext, ReactNode, useCallback, useState } from 'react';
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
} from 'twilio-video';
import { SelectedParticipantProvider } from './useSelectedParticipant';

import AttachVisibilityHandler from './AttachVisibilityHandler';
import useHandleRoomDisconnection from './useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom';
import useScreenShareToggle from './useScreenShareToggle';

import { getDefaultConnectionOptions } from '../utils';
import type { Consultation } from '../../../config/types';

type ErrorCallback = (error: TwilioError | Error) => void;

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface VideoProviderConnectionInfo {
  consultation: Consultation
}

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  /**
   * Connects to twilio room.
   */
  connect: (token: string, connectionInfo?: VideoProviderConnectionInfo | null) => Promise<void>;
  connectionInfo: VideoProviderConnectionInfo | null,
  onError: ErrorCallback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions,
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
}

export const VideoContext = createContext<IVideoContext>(null);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

export const VideoProvider = (props: VideoProviderProps) => {
  const { options, children, onError = () => {} } = props;

  const [connectionInfo, setConnectionInfo] = useState(null);

  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError],
  );

  const onDisconnectedCallback = useCallback(
    () => setConnectionInfo(null),
    []
  );

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    onDisconnectedCallback,
    options,
  );

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError,
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const _connectCallback = useCallback(
    (token, _connectionInfo) => {
      connect(token);
      setConnectionInfo(_connectionInfo)
    },
    [connect],
  );

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect: _connectCallback,
        connectionInfo,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
      }}>
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/*
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
};

VideoProvider.defaultProps = {
  options: getDefaultConnectionOptions(),
};
