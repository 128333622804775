import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../hooks/useQuery';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';

//Components imports
import { Row, Col, Button, Spin, Typography } from 'antd';
import NavDatePicker from '../../components/NavDatePicker';
import AvatarWithAuth from '../../components/AvatarWithAuth';
import AppointmentTime from '../../components/AppointmentTime';
import NewLineText from '../../components/NewLineText';
import { ArrowRightOutlined } from '@ant-design/icons';

//Misc imports
import { getQueryString } from '../../api/lib/utils';
import { getUserFullname, removeKeysWithUndefinedValue } from '../../utils';
import { getDoctorProfile } from '../../store/slices/doctorsSlice';
import { openBookAppointmentModal } from '../../store/slices/applicationSlice';
import message from '../../utils/message';

//const declaration
const { Text, Title } = Typography;
const baseClassName = 'appointments-doctors-single';

const AppointmentsDoctorsSingleScreen = (props) => {
  const query = useQuery();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [doctor, setDoctor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // eslint-disable-next-line react/prop-types
  const { id } = props?.match?.params;
  const start = query.get('start');
  const date = moment(start || new Date()).format('YYYY-MM-DD');
  const selectedSlot = doctor?.slots?.find(
    (tmpSlot) => start === tmpSlot.start,
  );

  const onSelectDate = (date, dateString) => {
    const newSearchStr = getQueryString(
      removeKeysWithUndefinedValue({
        start: date != null ? date : undefined,
      }),
    );
    history.replace({ search: newSearchStr });
  };

  const onSelectSlot = (slot) => {
    const newSearchStr = getQueryString(
      removeKeysWithUndefinedValue({
        start: slot.next_appointment
          ? moment(slot.start).format('YYYY-MM-DD')
          : slot.start,
      }),
    );
    history.replace({ search: newSearchStr });
  };

  const onBookAppointment = () => {
    dispatch(
      openBookAppointmentModal({
        doctor,
        slot: selectedSlot,
      }),
    );
  };

  // ======================================================
  // USE-EFFECTS
  // ======================================================
  useEffect(() => {
    if (id != null) {
      setIsLoading(true);
      (async () => {
        try {
          const action = await dispatch(
            getDoctorProfile({
              id,
              params: {
                appointment_date: date,
                next_appointment: true,
              },
            }),
          );
          const data = unwrapResult(action);
          setDoctor(data);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          message.error(err.message);
          setIsLoading(false);
          setHasError(true);
          setDoctor(null);
        }
      })();
    }
  }, [id, date, dispatch]);

  const slotStyle = doctor?.slots?.[0]?.next_appointment
    ? { width: '100%' }
    : {};
  return (
    <div className={`${baseClassName} container`}>
      <Row align="center">
        <Col lg={16} md={24}>
          {isLoading && !doctor && (
            <div className="text-center">
              <Spin />
            </div>
          )}
          {hasError ? (
            <Text type="danger">{t('Could not fetch the doctor')}</Text>
          ) : (
            <>
              <Row>
                <Col>
                  <div className={`${baseClassName}__header`}>
                    <AvatarWithAuth
                      className={`${baseClassName}__avatar`}
                      size={115}
                      imgUrl={{ uri: doctor?.avatar }}
                    />
                    <Title level={4}>{getUserFullname(doctor)}</Title>
                    {doctor?.speciality ? (
                      <Text>{doctor?.speciality}</Text>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={`${baseClassName}__section-title-container`}>
                    <Text className={`${baseClassName}__section-title`}>
                      {t('Book an appointment')}
                    </Text>
                    <div className={`${baseClassName}__section-divider`} />
                  </div>
                  <NavDatePicker
                    minDate={moment().format('YYYY-MM-DD')}
                    maxDate={moment().add(3, 'months').format('YYYY-MM-DD')}
                    defaultOpen={date == null}
                    value={date != null ? moment(date, 'YYYY-MM-DD') : null}
                    onChange={onSelectDate}
                  />
                  <div className={`${baseClassName}__slots-container`}>
                    {isLoading && doctor ? (
                      <div className={`${baseClassName}__slots-loader`}>
                        <Spin />
                      </div>
                    ) : doctor?.slots?.length > 0 ? (
                      doctor?.slots?.map((slot) => (
                        <AppointmentTime
                          key={slot.start}
                          style={slotStyle}
                          item={slot}
                          selected={selectedSlot?.start === slot.start}
                          onClick={onSelectSlot}
                        />
                      ))
                    ) : (
                      <Text>
                        {t(
                          'There are no available slots for appointment on this specific day.',
                        )}
                      </Text>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={`${baseClassName}__section-title-container`}>
                    <Text className={`${baseClassName}__section-title`}>
                      {t('Brief resume')}
                    </Text>
                    <div className={`${baseClassName}__section-divider`} />
                  </div>
                  <Text>
                    {doctor?.description ? (
                      <NewLineText text={doctor.description} />
                    ) : (
                      t('There is no description provided from the doctor')
                    )}
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={`${baseClassName}__section-button`}>
                    <Button
                      disabled={selectedSlot == null}
                      type={'primary'}
                      onClick={onBookAppointment}>
                      {t('Book an appointment')}
                      <ArrowRightOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AppointmentsDoctorsSingleScreen;
