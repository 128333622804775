import React from 'react';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
// import { LocalAudioTrack } from 'twilio-video';
// import { FormControl, MenuItem, Typography, Select, Grid } from '@material-ui/core';
import { Typography, Row, Col, Select } from 'antd';

import { SELECTED_AUDIO_INPUT_KEY } from '../../../../constants';
import useDevices from '../../../../plugins/video_call/hooks/useDevices';
import useMediaStreamTrack from '../../../../plugins/video_call/hooks/useMediaStreamTrack';
import useVideoContext from '../../../../plugins/video_call/hooks/useVideoContext';

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find((track) => track.kind === 'audio');
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <Typography.Text strong>Audio Input</Typography.Text>
      <Row gutter={[16, 16]}>
        <Col>
          {audioInputDevices.length > 1 ? (
            <Select
              style={{ width: '200px' }}
              placeholder="Select an input device"
              defaultValue={localAudioInputDeviceId || ''}
              onChange={(value) => replaceTrack(value)}>
              {audioInputDevices.map((device) => (
                <Select.Option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Typography.Text>
              {localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
            </Typography.Text>
          )}
        </Col>
        <Col>
          <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
        </Col>
      </Row>
    </div>
  );
}
