//@flow
import { createAxiosInstance } from './_axios';
import { getDefaultPageSize } from './lib/defaults';
import { getQueryString } from './lib/utils';
import type {
  ApiResponseArray,
  Appointment,
  AppointmentReservedPeriod,
} from '../config/types';

const appointmentsApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 * Creates a new Appointment.
 *
 * @param {Appointment} data Appointment's data.
 */
export const createAppointment = (data: Appointment): Promise<Appointment> =>
  appointmentsApi.post('api/appointments', data);

/**
 * Update an existing Appointment.
 *
 * @param {number} id Appointment's id.
 * @param {Appointment} data Appointment data to update.
 */
export const updateAppointment = (
  id: number,
  data: Appointment,
): Promise<Appointment> =>
  appointmentsApi.patch(`api/appointments/${id}`, data);

/**
 * Retrieves a single Appointment.
 *
 * @param {number} id Appointment's id.
 */
export const getAppointment = (id: number): Promise<Appointment> =>
  appointmentsApi.get(`api/appointments/${id}`);

/**
 * Lists all Appointments.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listAppointments = (
  params: Object,
): Promise<ApiResponseArray<Appointment>> => {
  const callParams = {
    page_size: getDefaultPageSize(),
    ...(params || {}),
  };
  return appointmentsApi.get(`api/appointments${getQueryString(callParams)}`);
};

export const listAppointmentReservedPeriods = (
  doctorId: number,
  params?: Object,
): Promise<ApiResponseArray<AppointmentReservedPeriod>> => {
  return appointmentsApi.get(`api/doctors/${doctorId}/away${getQueryString(params)}`);
};

export const getAppointmentReservedPeriod = (doctorId: number, id: number):Promise<AppointmentReservedPeriod> => {
  return appointmentsApi.get(`api/doctors/${doctorId}/away/${id}`);
};
