// @flow
import React, { useRef, useEffect } from 'react';
import { Track } from 'twilio-video';
import useMediaStreamTrack from '../../../plugins/video_call/hooks/useMediaStreamTrack';
import useVideoTrackDimensions from '../../../plugins/video_call/hooks/useVideoTrackDimensions';

interface VideoTrackProps {
  track: any;
  isLocal?: boolean;
  priority?: Track.Priority | null;
  videoStyle?: Object;
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
  videoStyle,
  autoFitVideo,
}: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track?.setPriority && priority) {
      track.setPriority(priority);
    }
    track?.attach(el);
    return () => {
      track?.detach(el);
      if (track?.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment';
  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    objectFit:
      Boolean(autoFitVideo) === false
        ? 'contain'
        : isPortrait || track?.name?.includes('screen')
          ? 'contain'
          : 'cover',
    width: '100%',
    height: '100%',
  };

  return (
    <video
      ref={ref}
      style={{
        ...style,
        ...(videoStyle || {}),
      }}
    />
  );
}
