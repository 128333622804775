import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Auto scrolls when user navigates to different screen.
 *
 * @param {*} props
 * @returns
 */
const ScrollToTopHandler = (props) => {
  const history = useHistory();
  const [location, setLocation] = useState(history.location);

  useEffect(() => {
    const removeListener = history.listen((newLocation) => {
      if (location?.pathname !== newLocation?.pathname) {
        window.scrollTo(0, 0);
      }
      setLocation(newLocation);
    });
    return removeListener;
  }, [history, location]);

  return null;
}

export default ScrollToTopHandler;
