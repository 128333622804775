// @flow
import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import { colors } from '../../styles';
import { useTranslation } from 'react-i18next';
import { composeStyles } from '../../utils/styling';
import type { Slot } from './../../config/types';

const { Text } = Typography;

type Props = {
  item: Slot,
  className?: string,
  style?: any,
  category?: any,
  onClick?: Function,
  selected?: boolean,
  renderedInFullScreen?: boolean,
};

/**
 * Generic Time component to display text.
 * @param {Props} props Component's properties.
 */
const AppointmentTime = (props: Props): any => {
  const { t } = useTranslation();

  const pressedBtnStyle = props.selected ? { backgroundColor: colors.brand_dark } : {};
  const dynamicWidth = props.item?.next_appointment ? '345px' : '100px';
  const { style } = props;
  return (
    <div
      className={props.className}
      style={composeStyles([
        styles.container,
        pressedBtnStyle,
        { width: dynamicWidth, height: '44px' },
        style,
      ])}
      onClick={() => props.onClick && props.onClick(props.item)}>
      <Text style={styles.time} category={'s1'}>
        {props.item?.next_appointment
          ? `${t('Next available:')} ${moment(props.item.start).format('ddd, D MMMM')}`
          : moment(props.item.start).format('h:mm a')}
      </Text>
    </div>
  );
};

AppointmentTime.defaultProps = {
  renderedInFullScreen: true,
};

const styles = {
  container: {
    marginTop: 16,
    marginRight: 8,
    alignSelf: 'flex-start',
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor: colors.secondary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  time: {
    color: colors.white,
    textAlign: 'center',
  },
};

export default AppointmentTime;
