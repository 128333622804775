// @flow
import * as React from 'react';
import {
  Button,
  DatePicker,
  Input,
  Spin,
  Modal,
  Popconfirm,
} from 'antd';
import FilesManager from '../filesManager/FilesManager';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMedicalProfileResourceItem,
  updateMedicalProfileResourceItem,
  deleteMedicalProfileResourceItem,
} from '../../store/slices/medicalProfileSlice';
import { useInputState } from '../../hooks/useInputState';
import { useFilesManagerHandler } from '../../hooks/useFilesManagerHandler';
import { removeKeysWithUndefinedValue } from '../../utils';
import message from '../../utils/message';
import moment from 'moment';
import type { MPExamination } from '../../config/types-mp';
import EditRow from '../editRow/EditRow';
import { unwrapResult } from '@reduxjs/toolkit';

const LoadingIndicator = (props) => (
  <div style={props.style}>
    <Spin size={'small'} />
  </div>
);

const { TextArea } = Input;

const MPEditExaminationItem = (props: Props) => {
  if (props?.title == null) {
    console.warn(
      'MPEditExaminationScreen expects "title" to be passed as navigation parameters',
    );
  }
  const { t } = useTranslation();
  const examination: MPExamination = props?.dataItem || {};
  console.log('examination', examination);
  const readOnly: boolean = Boolean(props?.readOnly);
  const consultation_id: ?number = props?.consultationId;
  const patient_id = useSelector((state) => state.profile.user.id);

  const dispatch = useDispatch();
  const notesInput = React.useRef(null);

  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const {
    files,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  } = useFilesManagerHandler(examination.files);

  // TODO: handle time instead of handling only the date
  let examinationDate;
  if (examination.date != null) {
    examinationDate = moment(examination.date).toDate();
  } else {
    examinationDate = new Date();
  }
  const [date, setDate] = React.useState(examinationDate);
  const typeState = useInputState(examination.type);
  const notesState = useInputState(examination.notes);
  const now = new Date();
  const hundrendYearsAgo = new Date(
    now.getFullYear() - 100,
    now.getMonth(),
    now.getDate(),
  );

  const onSave = React.useCallback(async () => {
    const data = removeKeysWithUndefinedValue({
      date: date ? moment(date).format() : undefined,
      type: typeState.value,
      notes: notesState.value === '' ? null : notesState.value,
      files,
    });

    if (Object.keys(data).length === 0) {
      // ok, there is no change..
      // just go back
    } else {
      try {
        setSaving(true);
        if (examination?.id) {
          await dispatch(
            updateMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              itemId: examination.id,
              data,
              resource: 'examinations',
            }),
          ).then(unwrapResult);
        } else {
          await dispatch(
            createMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              data,
              resource: 'examinations',
            }),
          ).then(unwrapResult);
        }
        props.onCloseModal(!props.visible);
      } catch (error) {
        setSaving(false);
        message.error(error.message);
      }
    }
  }, [
    date,
    files,
    typeState,
    notesState,
    examination,
    patient_id,
    consultation_id,
    dispatch,
    t,
  ]);

  const trySave = React.useCallback(() => {
    continueWithFilesUploadCaution()
      .then(onSave)
      .catch((_) => console.log('user cancelled'));
  }, [continueWithFilesUploadCaution, onSave]);

  const onDelete = () => {
    try {
      setDeleting(true);
      if (examination.id != null) {
        dispatch(
          deleteMedicalProfileResourceItem({
            patient_id,
            consultation_id,
            itemId: examination.id,
            resource: 'examinations',
          }),
        ).then(unwrapResult)
          .then(() => {
            props.onCloseModal(false);
          });
      }
    } catch (error) {
      setDeleting(false);
      message.error(error.message);
    }
  };

  return (
    <Modal
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      okText={t('Save')}
      centered={true}
      title={t('Examinations')}
      className={'profile-modal'}
      bodyStyle={styles.modalBackground}
      confirmLoading={saving}
      cancelText={t('Cancel')}
      onOk={trySave}
      onCancel={() => props.onCloseModal(!props.visible)}
      visible={props.visible}>
      <EditRow
        text={t('Type', { context: 'medical profile measurement' })}
        value={typeState.value}>
        {!readOnly ? (
          <Input
            disabled={readOnly}
            style={styles.input}
            value={typeState.value}
            textStyle={styles.inputText}
            maxLength={255}
            onSubmitEditing={() => notesInput?.current?.focus()}
            {...typeState}
          />
        ) : null}
      </EditRow>
      <EditRow
        text={t('Date', { context: 'medical profile measurement' })}
        value={moment(date).format('DD MMM YYYY')}>
        {!readOnly ? (
          <DatePicker
            className={'fullWidth'}
            placeholder={moment(date).format('ddd, DD MMM YYYY')}
            disabled={readOnly}
            format={'ddd, DD MMM YYYY'}
            onSelect={(nextDate) => setDate(nextDate)}
            min={hundrendYearsAgo}
            max={now}
          />
        ) : null}
      </EditRow>
      <EditRow
        text={t('Notes', { context: 'medical profile examination' })}
        value={notesState.value}>
        {!readOnly ? (
          <TextArea
            rows={4}
            ref={notesInput}
            value={notesState.value}
            disabled={readOnly}
            style={styles.input}
            textStyle={styles.notesText}
            {...notesState}
          />
        ) : null}
      </EditRow>
      <EditRow text={t('Attachments')}>
        <FilesManager
          autoUploadNewFiles={true}
          editable={!readOnly}
          files={examination.files}
          onFilesUpdated={onFilesUpdated}
        />
      </EditRow>
      {examination.id != null && !readOnly ? (
        <Popconfirm
          placement="bottom"
          title={t('Are you sure you want to delete this examination?')}
          onConfirm={onDelete}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Button
            loading={deleting}
            size={'middle'}
            style={styles.deleteButton}>
            {t('Delete examination')}
          </Button>
        </Popconfirm>
      ) : null}
    </Modal>
  );
};

const styles = {
  container: {
    flex: 1,
    paddingLeft: 16,
  },
  toolbar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  input: {
    backgroundColor: '#ffffff',
  },
  inputText: {},
  notesText: {
    minHeight: 80,
    textAlignVertical: 'top',
  },
  deleteButton: {
    alignSelf: 'center',
    marginVertical: 24,
  },
  modalBackground: {
    backgroundColor: 'rgba(72, 72, 72, 0.1)'
  }
};

export default MPEditExaminationItem;
