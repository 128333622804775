//@flow
const Buffer = require('buffer/').Buffer;

/**
 * Compare two dates from timestamp, currently used to compare the
 * date from access token to see if is invalid.
 * @param {date one} dateOne timestamp
 * @param {date two} dateTwo timestamp
 */
const compareTwoDates = (dateOne: number, dateTwo: number) => {
  if (dateOne < dateTwo) {
    return true;
  }
  return false;
};

/**
 * Passing accesstoken or JWT token and return the object of it
 * with data we may need, for example expiration date.
 * @param {Access token} token
 */
const parseJwt = (token: string) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(Buffer.from(base64, 'base64').toString());
};

/**
 * Check if the token is expired so we can log out the user.
 * @param {*} accessToken
 */
export const isTokenExpired = (accessToken: string): boolean => {
  let isExpired = false;
  if (accessToken != null) {
    let currentTime = new Date();
    isExpired = compareTwoDates(
      parseJwt(accessToken).exp,
      currentTime.getTime() / 1000
    );
  }
  return isExpired;
};

/**
 * Check if the parameter passed is a valid jwt token
 * @param token The token
 * @returns {boolean} `true` if valid jwt token, else `false`
 */
export const isValidJwtToken = (token: string): boolean => {
  if (typeof token === 'string') {
    if (token.split('.').length === 3) {
      return true;
    }
  }
  return false;
};

const jwtUtils = {
  isTokenExpired,
  isValidJwtToken
}
export default jwtUtils;
