// @flow
import * as React from 'react';
import { getAuthorizationHeaders } from '../../api/lib/defaults';
import { getFileMeta } from '../../api/files';
import { isImageFile } from './utils/files';
import { API_BASE_URL } from '../../constants';
import jwtUtils from '../../utils/jwt';
import axios from 'axios';
import type { File } from '../../config/types';

export const useImgSourceForFile = (file?: File, minified: boolean): { imgSource?: string | null, imgDownloading: boolean, imgDownloadFailed: boolean } => {
  const [imgSource, setImgSource] = React.useState();
  const [imgDownloading, setImgDownloading] = React.useState(false);
  const [imgDownloadFailed, setImgDownloadFailed] = React.useState(false);

  React.useEffect(() => {
    let source;
    if (isImageFile(file)) {
      if (minified) {
        source = file?.minified ?? file?.file;
      } else {
        source = file?.public_url ?? file?.file;
      }

      if (!source?.startsWith('http') && file?.blob) {
        source = URL.createObjectURL(file.blob);
        setImgSource(source);
      } else if (source?.startsWith(API_BASE_URL) && source !== file?.public_url) {
        // should download the image
        setImgDownloading(true);
        axios.get(source, {
          headers: getAuthorizationHeaders(),
          responseType: 'arraybuffer'
        })
          .then((response) => {
            const base64 = Buffer.from(response.data).toString('base64');
            const newSource = 'data:' + response.headers['content-type'] + ';base64,' + base64;
            setImgSource(newSource);
            setImgDownloading(false);
          })
          .catch((error) => {
            setImgSource(null);
            setImgDownloading(false);
            setImgDownloadFailed(true);
          })
      } else if (source?.startsWith(API_BASE_URL) && source === file?.public_url) {
        const token = source?.split('/').pop();
        if (file?.id && token && jwtUtils.isValidJwtToken(token) && jwtUtils.isTokenExpired(token)) {
          setImgDownloading(true);
          getFileMeta(file?.id)
            .then((res) => {
              if (res.data?.public_url || res.data?.file) {
                const url = res.data.public_url || res.data.file;
                setImgSource(url);
              } else {
                // something went terribly wrong...
                // api should give us a `public_url` or at least a `file`
                setImgSource(null);
              }
              setImgDownloading(false);
              setImgDownloadFailed(false);
            })
            .catch((error) => {
              setImgSource(null);
              setImgDownloading(false);
              setImgDownloadFailed(false);
            })
        }
      }
    }

  }, [file, minified]);

  return {
    imgSource,
    imgDownloading,
    imgDownloadFailed,
  }
};
