/**
 * Accepts a style that is either an Object or an Array of objects.
 * Use that to support transition from ReactNative where you can do
 * something like that:
 *
 * @example
 * <div style={[{margin: '5px'}, {padding: '5px'}]}>...</div>
 *
 * @param {*} style
 * @returns
 */
export const composeStyles = (style) => {
  if (style != null && Array.isArray(style)) {
    return style.reduce((accumulator, current) => ({
      ...accumulator,
      ...composeStyles(current || {}),
    }));
  }
  return style;
};
