// @flow
import { useEffect } from 'react';

type Callback = (...args: any[]) => void;

export default function useHandleTrackPublicationFailed(room: any | null, onError: Callback) {
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', onError);
      return () => {
        room.localParticipant.off('trackPublicationFailed', onError);
      };
    }
  }, [room, onError]);
}
