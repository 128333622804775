import { useDispatch } from 'react-redux';
import * as SymptomsCheckerActions from '../../store/slices/symptomsCheckerSlice';

export const useSymptomsCheckerActions = () => {
  const dispatch = useDispatch();
  const setIntroInitiated = (...args) =>
    dispatch(SymptomsCheckerActions.setIntroInitiated(...args));
  const setState = (...args) =>
    dispatch(SymptomsCheckerActions.setState(...args));
  const setSex = (...args) => dispatch(SymptomsCheckerActions.setSex(...args));
  const setAge = (...args) => dispatch(SymptomsCheckerActions.setAge(...args));
  const setIsTyping = (...args) =>
    dispatch(SymptomsCheckerActions.setIsTyping(...args));
  const setSymptoms = (...args) =>
    dispatch(SymptomsCheckerActions.setSymptoms(...args));
  const setExistingEvidences = (...args) =>
    dispatch(SymptomsCheckerActions.setExistingEvidences(...args));
  const setQuickReplies = (...args) =>
    dispatch(SymptomsCheckerActions.setQuickReplies(...args));
  const setQuickReplyMeta = (...args) =>
    dispatch(SymptomsCheckerActions.setQuickReplyMeta(...args));
  const setChatMessages = (...args) =>
    dispatch(SymptomsCheckerActions.setChatMessages(...args));
  const setDiagnosisDataToRetry = (...args) =>
    dispatch(SymptomsCheckerActions.setDiagnosisDataToRetry(...args));
  const appendChatMessage = (...args) =>
    dispatch(SymptomsCheckerActions.appendChatMessage(...args));
  const resetChecker = (...args) => dispatch(SymptomsCheckerActions.reset());

  return {
    setIntroInitiated,
    setState,
    setSex,
    setAge,
    setIsTyping,
    setSymptoms,
    setExistingEvidences,
    setQuickReplies,
    setQuickReplyMeta,
    setChatMessages,
    setDiagnosisDataToRetry,
    appendChatMessage,
    resetChecker
  };
};
