import * as React from 'react';

/**
 * React hook for using setInterval.
 * Source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {*} callback
 * @param {*} delay
 */
export const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
