import React from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import Menu from '../Menu/Menu';

const useStyles = () => {
  const theme = {
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    sidebarWidth: 355,
  };

  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      backgroundColor: '#000',
    },
  };
};

export default function Room() {
  const classes = useStyles();
  return (
    <div style={classes.container}>
      <MainParticipant />
      <ParticipantList />
      <Menu />
    </div>
  );
}
