//Basic redux toolkit imports
import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import * as MedicalProfileAPI from '../../api/medical_profile';
// import { createSelector } from 'reselect';
import { logout } from './authSlice';

export const getMedicalProfile = createAsyncThunk(
  'medical_profile/getMedicalProfile',
  async (params, thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.getMedicalProfile();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateMedicalProfile = createAsyncThunk(
  'medical_profile/updateMedicalProfile',
  async (opts: MedicalProfileIDsOpts & { data: MPProfile }, thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.updateMedicalProfile(opts);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// *****************************************
// *****************************************
// ******                             ******
// ******      Generic Resources      ******
// ******                             ******
// *****************************************
// *****************************************

export const getMedicalProfileResourceOptions = createAsyncThunk(
  'medical_profile/getMedicalProfileResourceOptions',
  async (opts:
    | (MedicalProfileIDsOpts & {
      resource: MedicalProfileResource,
    })
    | MedicalProfileResource, thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.getMedicalProfileResourceOptions(opts);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const listMedicalProfileResourceItems = createAsyncThunk(
  'medical_profile/listMedicalProfileResourceItems',
  async (opts: MedicalProfileIDsOpts & {
    params?: Object,
    resource: MedicalProfileResource,
  }, thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.listMedicalProfileResourceItems(opts);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMedicalProfileResourceItem = createAsyncThunk(
  'medical_profile/getMedicalProfileResourceItem',
  async (opts: MedicalProfileIDsOpts & {
    itemId: number,
    resource: MedicalProfileResource,
  },
  thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.getMedicalProfileResourceItem(opts);

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMedicalProfileResourceItem = createAsyncThunk(
  'medical_profile/createMedicalProfileResourceItem',
  async (opts: MedicalProfileIDsOpts & {
    data: T,
    resource: MedicalProfileResource,
  },
  thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.createMedicalProfileResourceItem(opts);
      console.log('data on create on error also', data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateMedicalProfileResourceItem = createAsyncThunk(
  'medical_profile/updateMedicalProfileResourceItem',
  async (opts: MedicalProfileIDsOpts & {
    itemId: number,
    data: T,
    resource: MedicalProfileResource,
  },
  thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.updateMedicalProfileResourceItem(opts);
      console.log('data on update on error also', data);
      return data;
    } catch (error) {

      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteMedicalProfileResourceItem = createAsyncThunk(
  'medical_profile/deleteMedicalProfileResourceItem',
  async (opts: MedicalProfileIDsOpts & {
    itemId: number,
    resource: MedicalProfileResource,
  },
  thunkAPI) => {
    try {
      const { data } = await MedicalProfileAPI.deleteMedicalProfileResourceItem(opts);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Handles the case for get, update, create resource item succesfully.
 * @param {} state
 * @param {*} param1
 */
const resourceItemSuccess = (state, { payload, meta }) => {
  const { resource } = meta.arg;
  if (resource != null) {
    let existingItems = state.resources?.[resource] || [];
    const existingIndex = existingItems.findIndex(
      (item) => item.id === payload.id,
    );
    if (existingIndex > -1) {
      existingItems[existingIndex] = {
        ...existingItems[existingIndex],
        ...payload,
      };
    } else {
      existingItems = [payload, ...existingItems];
    }

    const existingPagination = state.resourcePagination?.[resource] || {};

    state.resources = {
      ...(state.resources || {}),
      [resource]: [...existingItems],
    };
    state.resourcePagination = {
      ...(state.resourcePagination || {}),
      [resource]: {
        ...existingPagination,
        totalDataEntries: existingPagination?.totalDataEntries || 1,
        page: existingPagination?.page || 1,
      },
    };
  }
}

const medicalProfileSlice = createSlice({
  name: 'medical_profile',
  initialState: {
    profile: {},
    isFetchingProfile: false,
    resources: {},
    resourceOpts: {},
    resourcePagination: {},
  },
  reducers: {
    clearAllMedicalProfileData: (state) => {
      state = {
        profile: {},
        isFetchingProfile: false,
        resources: {},
        resourceOpts: state.resourceOpts,
        resourcePagination: {},
      }
    }
  },
  extraReducers: {

    [getMedicalProfile.pending]: (state) => {

      state.isFetchingProfile = true;
    },
    [getMedicalProfile.rejected]: (state, { payload, meta }) => {

      state.isFetchingProfile = false;
    },
    [getMedicalProfile.fulfilled]: (state, { payload, meta }) => {
      state.isFetchingProfile = false;
      state.profile = payload;
    },
    [updateMedicalProfile.fulfilled]: (state, { payload, meta }) => {
      const existingProfile = state.profile;
      let profileObj = {};
      if (
        existingProfile?.patient?.id == null ||
        meta.arg.patient_id === existingProfile?.patient?.id
      ) {
        // make sure the update is for the "current patient"
        // or that there isn't a "current" one, so, make that one as "current"
        profileObj = {
          ...existingProfile,
          ...payload,
        };
      }
      state.profile = profileObj;
    },
    [getMedicalProfileResourceOptions.fulfilled]: (state, { payload, meta }) => {
      const options = meta.arg;
      const resource =
        typeof options === 'string' ? options : options?.resource;
      if (resource != null) {
        state.resourceOpts = {
          ...(state.resourceOpts || {}),
          [resource]: payload?.actions?.POST || {},
        }
      }
    },
    [listMedicalProfileResourceItems.pending]: (state, { payload, meta }) => {
      const { resource } = meta.arg;
      const existingPagination = state.resourcePagination?.[resource] || {};
      if (resource != null) {
        state.resourcePagination = {
          ...(state.resourcePagination || {}),
          [resource]: {
            ...existingPagination,
            page: meta.arg.params?.page || 1,
          },
        }
      }
    },
    [listMedicalProfileResourceItems.fulfilled]: (state, { payload, meta }) => {
      const { resource } = meta.arg;
      if (resource != null) {
        state.resources = {
          ...(state.resources || {}),
          [resource]: payload.results,
        };
        state.resourcePagination = {
          ...(state.resourcePagination || {}),
          [resource]: {
            totalDataEntries: payload.count,
            page: meta.arg.params?.page || 1,
          },
        };
      }
    },
    [getMedicalProfileResourceItem.fulfilled]: resourceItemSuccess,
    [updateMedicalProfileResourceItem.fulfilled]: resourceItemSuccess,
    [createMedicalProfileResourceItem.fulfilled]: resourceItemSuccess,
    [deleteMedicalProfileResourceItem.fulfilled]: (state, { payload, meta }) => {
      const { resource } = meta.arg;
      if (resource != null) {
        let tmpArray = [...(state.resources?.[resource] || [])];
        const index = tmpArray.findIndex((item) => item.id === meta.arg.itemId);
        if (index > -1) {
          tmpArray.splice(index, 1);
        }
        state.resources = {
          ...(state.resources || {}),
          [resource]: tmpArray,
        };
      }
    },
    [logout]: (state) => {
      state = {
        profile: {},
        isFetchingProfile: false,
        resources: {},
        resourceOpts: {},
        resourcePagination: {},
      }
    }
  }
});

export const selectors = {
  getMedicalProfile: (
    state: ReducerMedicalProfile,
    patient_id: number,
  ): MPProfile => {
    if (patient_id != null && state.profile?.patient?.id === patient_id) {
      return state.profile || {};
    }
    return {};
  },
  getOptionsForResourceItem: (
    state: ReducerMedicalProfile,
    resource: MedicalProfileResource,
  ) => {
    return state.resourceOpts[resource] || {};
  },
  getEditableFieldsForResourceItem: (
    state: ReducerMedicalProfile,
    resource: MedicalProfileResource,
  ) => {
    const protectedFields = [
      'id',
      'medical_profile_id',
      'medical_profile',
      'created_at',
      'updated_at',
    ];
    const opts = state.resourceOpts[resource] || {};
    const fields = [];
    Object.keys(opts).forEach((key) => {
      if (!protectedFields.includes(key)) {
        fields.push({
          key,
          ...opts[key],
        });
      }
    });
    return fields;
  },
};

export const measurementSelectors = {
  getMeasurementOptions: (state: ReducerMedicalProfile): Object => {
    return state.resourceOpts?.measurements || {};
  },
  getMeasurementTypeForProperty: (
    state: ReducerMedicalProfile,
    prop: string,
  ): ?'integer' | 'field' | 'choice' | 'datetime' | 'string' => {
    return state.resourceOpts?.measurements?.[prop]?.type;
  },
  getMeasurementTypes: (
    state: ReducerMedicalProfile,
  ): Array<MPResourceChoiceObject> => {
    const metaValues = state.resourceOpts?.measurements?.values?.metadata || {};
    const choices = state.resourceOpts?.measurements?.type?.choices || [];
    return choices.map((c) => ({
      ...c,
      display_name: metaValues[c.value]?.label || c.display_name,
    }));
  },
  getMeasurementTypesObject: (state: ReducerMedicalProfile): Object => {
    const metaValues = state.resourceOpts?.measurements?.values?.metadata || {};
    const choices = state.resourceOpts?.measurements?.type?.choices || [];
    const obj = {};
    choices.forEach((c) => {
      obj[c.value] = metaValues[c.value]?.label || c.display_name;
    });
    return obj;
  },
  getMeasurementMetadataForValueOfType: (
    state: ReducerMedicalProfile,
    type: string,
  ): Object => {
    return state.resourceOpts?.measurements?.values?.metadata?.[type] || {};
  },
  getMeasurementMetadataForValues: (state: ReducerMedicalProfile): Object => {
    return state.resourceOpts?.measurements?.values?.metadata || {};
  },

  /**
 * Returns a map of measurement value and its' valid field keys
 * @example
 * {
 *   'blood_oxygen': ['value'],
 *   'blood_pressure': ['systolic', 'diastolic'],
 *   'temperature': ['value'],
 * }
 * @param {*} state Medical profile reducer
 * @returns
 */
  getMeasurementValidFieldKeysPerType: (
    state: ReducerMedicalProfile,
  ): { [string]: Array<string> } => {
    const metadata = state.resourceOpts?.measurements?.values?.metadata || {};
    const objToReturn = {};
    Object.keys(metadata).forEach((key) => {
      objToReturn[key] = metadata[key].fields?.map((f) => f.key);
    });
    return objToReturn;
  },
};

export const { clearAllMedicalProfileData } = medicalProfileSlice.actions;

export const medicalProfileReducer = medicalProfileSlice.reducer;
