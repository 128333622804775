// @flow
import * as React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { composeStyles } from '../../utils/styling';

type Props = {
  style?: any,
  icon?: React.Node | null,
  onPress?: () => void,
};

const AddFileButton = (props: Props): React.Node => {
  const { style, icon, onPress, ...restProps } = props;
  return (
    <>
      {/* $FlowFixMe */}
      <Button
        style={composeStyles([
          styles.container,
          style,
        ])}
        type="primary"
        shape="circle"
        icon={icon || <PlusOutlined />}
        size={'small'}
        onClick={onPress}
        {...restProps}
      />
    </>
  );
};

const styles = {
  container: {},
};

export default AddFileButton;
