import authentication from '../../plugins/authentication';
import i18n from 'i18next';

let defaultPageSize = 10;
let defaultHeaders = {
  Content: 'application/json',
  'Content-Type': 'application/json'
};

export const setDefaultPageSize = (pageSize) => (defaultPageSize = pageSize);
export const getDefaultPageSize = () => defaultPageSize;

export const getAllDefaultHeaders = () => {
  const headers = { ...defaultHeaders };
  if (i18n.language) {
    headers['Accept-Language'] = i18n.language;
  }
  return headers;
};

/**
 * Returns headers for an authorized API call to the backend.
 * Will also include all the default headers *except* the content related headers.
 *
 * @typedef {Object} AuthorizationHeadersOptions
 * @property {boolean} strict When this is `true`, the headers object will contain only the headers needed for actual authentication and will strip all other headers (e.g. device-id or language headers).
 *
 * @param {AuthorizationHeadersOptions} [options]
 */
export const getAuthorizationHeaders = (options) => {
  const { strict } = options || {};
  const accessToken = authentication.getAuthToken();

  let header = {};
  if (accessToken) {
    header = {
      Authorization: `Bearer ${accessToken}`
    };
  }

  if (Boolean(strict) === false) {
    const dh = getAllDefaultHeaders();
    delete dh.Content;
    delete dh['Content-Type'];

    header = {
      ...header,
      ...dh
    };
  }
  return header;
};

