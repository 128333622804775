// @flow
import * as React from 'react';
import { Button, Empty, Spin, Typography } from 'antd';
// import { Alert, RefreshControl, View, StyleSheet } from 'react-native';
// import { Button, Spinner, Text } from '@icare/components';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import message from '../utils/message';

const { Title, Text } = Typography;

type LocalState = {
  canRenderContent: boolean,
  loading: boolean,
  hasLoadedOnce: boolean,
  hasPulledToRefresh: boolean,
  hasPreviousPage: boolean,
  hasNextPage: boolean,
  error: Object | null,
  currentPage: number,
  totalEntries: number,
};

export type DataLoaderHookProps = {
  /**
   * Determines whether or not a component can (or should) render its contents.
   * Read more here: https://engineering.classpro.in/react-native-how-settimout-saved-my-life-803346bf18a5
   */
  canRenderContent: boolean,
  isLoading: boolean,
  error: Object | null,
  page: number,
  totalDataEntries: number,
  hasLoadedOnce: boolean,
  hasPulledToRefresh: boolean,
  hasPreviousPage: boolean,
  hasNextPage: boolean,
  loadPage: (page: number) => Promise<any>,
  reloadData: () => Promise<any>,
  renderEmptySpace: (
    content?: string | React.Element<any>,
  ) => React.Element<any>,
  renderLoadMoreFooter: () => React.Element<any> | null,
  renderRefreshControl: () => React.Element<any> | null,
};

const LoadingIndicator = (props) => (
  <div className="d-flex jc-c ai-c">
    <Spin size={'small'} />
  </div>
);

/**
 * Hook for fetching data with pagination
 *
 * NOTE: make sure that the api caller is memoized
 *       using `useCallback` or `useMemo`.
 *
 * @example
 * ```
 * const apiCaller = useCallback((params) => {
 *  return api.callServer(userId, params);
 * });
 *
 * const dataLoader = useDataLoader(apiCaller);
 * ```
 *
 * @returns ({ canRenderContent, isLoading, error, page, hasPreviousPage, hasNextPage, loadPage, loadMore, reloadData, renderEmptySpace, renderLoadMoreFooter, renderRefreshControl })
 */
export const useDataLoader = (
  apiCaller: (params: Object) => Promise<any>,
  onError?: ((error: Object) => boolean) | null,
  options?: {
    disableAutoLoad?: boolean,
    /**
     * When `true` and `onError` function does not handle the error, the error,
     * will be handled automatically by this hook. Default value is `true`.
     */
    autoHandleErrors?: boolean,
  }
): DataLoaderHookProps => {
  const { disableAutoLoad, autoHandleErrors = true } = options || {};
  const { t } = useTranslation();
  const savedApiCaller = React.useRef();

  const [localState, setLocalState] = React.useState < LocalState > ({
    canRenderContent: false,
    loading: false,
    hasLoadedOnce: false,
    hasPulledToRefresh: false,
    hasPreviousPage: false,
    hasNextPage: false,
    error: null,
    currentPage: 1,
    totalEntries: 0
  });

  // ==============================
  // Core functions
  // ==============================

  const handleError = React.useCallback(
    (e) => {
      let handled = false;
      if (onError) {
        handled = onError(e);
      }
      if (!handled && autoHandleErrors) {
        message.error(e.message);
      }
    },
    [autoHandleErrors, onError]
  );

  const loadPage = React.useCallback(
    async (pageToLoad: number) => {
      if (savedApiCaller.current == null) {
        return Promise.resolve();
      }

      try {
        setLocalState((prevState) => ({
          ...prevState,
          currentPage: pageToLoad,
          loading: true
        }));
        const params = { page: pageToLoad };
        const resultAction = await savedApiCaller.current?.(params);
        const response = unwrapResult(resultAction);

        if (response != null && typeof response === 'object') {
          setLocalState((prevState) => ({
            ...prevState,
            totalEntries: response.count,
            hasLoadedOnce: true,
            hasNextPage: response.next != null,
            hasPreviousPage: response.previous != null,
            loading: false
          }));
        } else {
          setLocalState((prevState) => ({
            ...prevState,
            hasLoadedOnce: true,
            hasNextPage: false,
            hasPreviousPage: false,
            loading: false
          }));
        }
        return Promise.resolve(response);
      } catch (e) {
        setLocalState((prevState) => ({
          ...prevState,
          hasLoadedOnce: true,
          error: e,
          loading: false
        }));
        handleError(e);
        return Promise.reject(e);
      }
    },
    [savedApiCaller, handleError]
  );

  const reloadData = React.useCallback(
    (pulledToRefresh?: boolean) => {
      setLocalState((prevState) => ({
        ...prevState,
        hasPulledToRefresh: Boolean(pulledToRefresh)
      }));
      return loadPage(1).catch(handleError);
    },
    [loadPage, handleError]
  );

  // ==============================
  // UI related utility functions
  // ==============================

  const renderEmptySpace = React.useCallback(
    (content?: React.Element<any> | string) => {
      const shouldShowLoading =
        !localState.hasLoadedOnce ||
        (localState.hasLoadedOnce &&
          localState.loading &&
          !localState.hasPulledToRefresh);
      return (
        <div className="d-flex jc-c ai-c">
          {shouldShowLoading ? (
            <Spin />
          ) : content == null || typeof content === 'string' ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={content} />
          ) : (
            content
          )}
        </div>
      );
    },
    [localState]
  );

  const renderLoadMoreFooter = React.useCallback(() => {
    const isLoadingMore = localState.loading && localState.currentPage > 1;
    const canLoadMore = !localState.loading && localState.hasNextPage;

    if (isLoadingMore || canLoadMore) {
      return (
        <Button
          size={'small'}
          loading={isLoadingMore}
          style={{
            marginTop: '8px',
            marginBottom: '16px'
          }}
          onClick={() => {
            const nextPage = localState.currentPage + 1;
            loadPage(nextPage).catch(handleError);
          }}>
          {t('Load more')}
        </Button>
      );
    }

    return null;
  }, [localState, t, loadPage, handleError]);

  const renderRefreshControl = React.useCallback(() => {
    if (localState.hasLoadedOnce) {
      return (
        <Button
          loading={
            localState.hasLoadedOnce &&
            localState.hasPulledToRefresh &&
            localState.loading &&
            localState.currentPage === 1
          }
          onClick={() => reloadData(true)}
        >
          {t('Retry')}
        </Button>
      );
    }
    return null;
  }, [localState, reloadData, t]);

  // ==============================
  // Effects
  // ==============================

  React.useEffect(() => {
    // this effect is supposed to be run on "componentDidMount"
    setTimeout(() => {
      setLocalState({
        ...localState,
        canRenderContent: true
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    savedApiCaller.current = apiCaller;
  }, [apiCaller]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        await loadPage(1);
      } catch (e) {
        handleError(e);
      }
    }
    if (!disableAutoLoad) {
      fetchData();
    }
  }, [apiCaller, loadPage, handleError, disableAutoLoad]);

  return {
    canRenderContent: localState.canRenderContent,
    isLoading: localState.loading,
    error: localState.error,
    page: localState.currentPage,
    totalDataEntries: localState.totalEntries,
    hasLoadedOnce: localState.hasLoadedOnce,
    hasPulledToRefresh: localState.hasPulledToRefresh,
    hasPreviousPage: localState.hasPreviousPage,
    hasNextPage: localState.hasNextPage,
    loadPage,
    reloadData,
    renderEmptySpace,
    renderLoadMoreFooter,
    renderRefreshControl
  };
};
