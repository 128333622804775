// @flow
import moment from 'moment';
import { translate } from '../../locales';
import { isFinishedSuccessfullyConsultation } from '../index';
import type {
  Appointment,
  AppointmentCalculatedStatus,
  AppointmentType,
  Doctor,
} from '../../config/types';
import { getCityStringForDoctor } from '../index';

/**
 * Checks if a given appointment can be cancelled based on the current date time, status etc.
 *
 * @param {Appointment} [appointment] The appointment to make the check for.
 * @returns {boolean}
 */
export const canCancelAppointment = (appointment?: Appointment): boolean =>
  appointment != null &&
  appointment.status === 'confirmed' &&
  moment().isSameOrBefore(moment(appointment?.max_call_availability));

/**
 * Given an appointment, the function will calculate a new status that is
 * a combination of the appointment's status and the status of the consultation
 * for that appointment (if any).
 *
 * The status is most useful when you need to know if the
 * appointment **actually** happend.
 *
 * @param {Appointment} [appointment] The appointment to get the status for.
 * @returns {AppointmentCalculatedStatus}
 */
export const getCalculatedStatusForAppointment = (
  appointment?: Appointment,
): AppointmentCalculatedStatus => {
  if (appointment == null) {
    return 'not_happened';
  }

  const { type, consultation, finish, max_call_availability } = appointment;

  switch (appointment?.status) {
    case 'new':
      return 'new_request';
    case 'invalidated':
    case 'rejected':
      return 'cancelled_by_doctor';
    case 'cancelled':
      return 'cancelled';
    case 'missed':
      return 'not_happened';
  }

  if (type === 'online' && consultation != null) {
    if (isFinishedSuccessfullyConsultation(consultation)) {
      return 'finished';
    }
    if (consultation?.status === 'in_progress') {
      return 'in_progress';
    }
  }

  if (moment().isAfter(finish) && type === 'physical') {
    return 'finished';
  }
  if (
    moment().isAfter(max_call_availability) &&
    type === 'online' &&
    consultation == null
  ) {
    return 'not_happened';
  }

  return 'confirmed';
};

/**
 * Returns information for labeling a "tag" regarding an appointment.
 *
 * @param {Appointment} [appointment] The appointment to display information for.
 * @returns {({
 *   status: 'basic' | 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'control',
 *   label: string,
 * })}
 */
export const getTagStatusForAppointment = (
  appointment?: Appointment,
): {
  status:
    | 'basic'
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'control',
  label: string,
} | null => {
  if (appointment == null) {
    return null;
  }
  const status = getCalculatedStatusForAppointment(appointment);

  switch (status) {
    case 'new_request':
      return {
        status: 'warning',
        label: translate('CONFIRMATION EXPECTED BY DOCTOR', {
          context: 'appointment status',
        }),
      };
    case 'in_progress':
    case 'confirmed':
      return {
        status: 'success',
        label: translate('CONFIRMED', { context: 'appointment status' }),
      };
    case 'finished':
      return {
        status: 'info',
        label: translate('FINISHED', { context: 'appointment status' }),
      };
    case 'cancelled_by_doctor':
      return {
        status: 'danger',
        label: translate('CANCELLED BY THE DOCTOR', {
          context: 'appointment status',
        }),
      };
    case 'not_happened':
      return {
        status: 'danger',
        label: translate('DID NOT HAPPEN', { context: 'appointment status' }),
      };
    case 'cancelled':
      return {
        status: 'danger',
        label: translate('YOU CANCELLED THE APPOINTMENT', {
          context: 'appointment status',
        }),
      };
  }

  return null;
};

/**
 * Returns a text to display about the type of an appointment.
 *
 * @param {AppointmentType} type The appointment's type.
 * @param {Doctor} [doctor] The doctor that the patient has the appointment with.
 * @returns
 */
export const getAppointmentTypeText = (
  type: AppointmentType,
  doctor?: Doctor,
) => {
  let text = '';
  if (type === 'online') {
    text = translate('Tele appointment via video call');
  } else if (type === 'physical') {
    if (doctor != null && doctor.street !== '') {
      text = [doctor.street || '', getCityStringForDoctor(doctor)]
        .filter((tmp) => tmp !== '')
        .join(', ');
    } else {
      text = translate('Appointment at the office');
    }
  }
  return text;
};

export const canJoinConsultationForAppointment = (
  appointment?: Appointment,
): boolean => {
  let canJoin = false;
  if (appointment?.type === 'online') {
    const consultation = appointment.consultation;
    canJoin = consultation?.status === 'in_progress';
  }
  return canJoin;
};

export const hasFinishedWithConsultation = (
  appointment?: Appointment,
): boolean => {
  let hasFinished = false;
  if (appointment?.type === 'online') {
    const consultation = appointment.consultation;
    hasFinished =
      consultation?.status === 'finished' ||
      consultation?.status === 'completed';
  }
  return hasFinished;
};
