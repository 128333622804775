// @flow
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Components imports
import { Spin, Layout, Affix } from 'antd';
import PrivateRoute from '../Routes/PrivateRoute';
import PublicRoute from '../Routes/PublicRoute';
import NotFoundScreen from '../NotFoundScreen';
import HomeScreen from '../HomeScreen';
import LoginScreen from '../LoginScreen';
import VisitsScreen from '../VisitsScreen';
import VisitsSessionScreen from '../VisitsSessionScreen';
import VisitsScreenSingle from '../VisitsScreenSingle';
import ProfileScreen from '../ProfileScreen';
import AppointmentsScreen from '../AppointmentsScreen';
import AppointmentsDoctorsListScreen from '../AppointmentsDoctorsListScreen';
import AppointmentsDoctorsSingleScreen from '../AppointmentsDoctorsSingleScreen';
import AppointmentsScreenSingle from '../AppointmentsScreenSingle';
import SymptomsCheckerScreen from '../SymptomsCheckerScreen';
import NotificationsScreen from '../NotificationsScreen';
import ScrollToTopHandler from '../../components/ScrollToTopHandler';
import MainMenuWrapper from '../../components/MainMenuWrapper';
import FooterContainer from '../../components/FooterContainer';
import ActiveAppointmentsBanner from '../../components/ActiveAppointmentsBanner';
import TOS from '../../components/TOS';
// $FlowFixMe
import ReconnectingNotification from '../../components/video_call/ReconnectingNotification/ReconnectingNotification';
import MiniVideoCallPlayer from '../../components/video_call/MiniVideoCallPlayer';
import AppointmentInvalidationModal from '../../components/modals/AppointmentInvalidationModal/AppointmentInvalidationModal';
import BookAppointmentModal from '../../components/modals/BookAppointmentModal/BookAppointmentModal';
import CallNowDoctorModal from '../../components/modals/CallNowDoctorModal/CallNowDoctorModal';
import OutgoingCallModal from '../../components/modals/OutgoingCallModal/OutgoingCallModal';
import DownloadAppsModal from '../../components/modals/DownloadAppsModal/DownloadAppsModal';

// Misc imports
import { login, logout as logoutAction, getToken } from '../../store/slices/authSlice';
import { getProfile } from '../../store/slices/profileSlice';
import { getUserNotificationsCount } from '../../store/slices/notificationsSlice';
import { getMedicalProfileResourceOptions } from '../../store/slices/medicalProfileSlice';
import { closeBookAppointmentModal, closeCallInitializationModal, closeAppointmentInvalidationModal } from '../../store/slices/applicationSlice';
import { getAppointments } from '../../store/slices/appointmentsSlice';
import { listDoctorsSpecialities } from '../../store/slices/doctorsSlice';
import jwtUtils from '../../utils/jwt';
import { isEmptyObject } from '../../utils';
import { useOutgoingConsultation } from '../../hooks/useOutgoingConsultation';
import { useParticipantsChangeHandler } from '../../hooks/useParticipantsChangeHandler';
import { useNewNotificationsPolling } from '../../hooks/useNewNotificationsPolling';

// Constant declarations
import {
  NAV_ROOT, NAV_LOGIN, NAV_VISITS, NAV_APPOINTMENTS, NAV_SYMPTOM_CHECKER,
  NAV_PROFILE, NAV_VISITS_SESSION, NAV_VISITS_SINGLE, NAV_APPOINTMENTS_SINGLE,
  NAV_APPOINTMENTS_DOCTORS, NAV_APPOINTMENTS_DOCTORS_SINGLE, NAV_NOTIFICATIONS
} from '../../constants';
const { Content } = Layout;

const Root = (): React$Element<any> => {
  const { logout, isAuthenticated, isLoading, user, getAccessTokenSilently, ...rest } = useAuth0();
  const dispatch = useDispatch();
  useParticipantsChangeHandler();
  useNewNotificationsPolling();
  const storedToken = useSelector(getToken);
  const { tos_accepted, policy_accepted, gdpr_accepted } = useSelector((state) => state.profile.user);
  const { user: storeUser } = useSelector((state) => state.profile);
  const {
    callInitModalVisible,
    callInitAppointment,
    bookAppointmentModalVisible,
    bookAppointmentSlot,
    bookAppointmentDoctor,
    appointmentInvalidationModalVisible,
    appointmentInavlidationAppointment,
  } = useSelector(state => state.application);
  const { consultation: outogingConsultation } = useOutgoingConsultation();

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);

  const acceptedTermsOfService = useMemo(() => (
    tos_accepted && policy_accepted && gdpr_accepted
  ), [tos_accepted, policy_accepted, gdpr_accepted]);

  // Use this function in order to fetch data when a visitor enters a page.
  const syncData = () => {
    dispatch(getProfile());
    dispatch(getUserNotificationsCount());
    dispatch(getMedicalProfileResourceOptions('measurements'));
    dispatch(getMedicalProfileResourceOptions('medications'));
    dispatch(getMedicalProfileResourceOptions('allergies'));
    dispatch(listDoctorsSpecialities({
      active: 1,
      accept_appointments_online: 1,
    }));
    dispatch(getAppointments({
      page_size: 1,
      finish__gte: new Date().toISOString(),
      ordering: 'start',
    }));
  }

  const fetchAuth0Token = async () => {
    try {
      const token = await getAccessTokenSilently();
      dispatch(login(token));
      setUserIsAuthenticated(true);
      syncData();
    } catch (e) {
      console.log('Could not get auth0 token', e);
    }
  }

  const closeCallModal = () => dispatch(closeCallInitializationModal());
  const closeBooKModal = () => dispatch(closeBookAppointmentModal());
  const closeInvalidationModal = () => dispatch(closeAppointmentInvalidationModal());

  // ======================================================
  // USE-EFFECTS
  // ======================================================
  useEffect(() => {
    if (jwtUtils.isTokenExpired(storedToken)) {
      dispatch(logoutAction());
      logout();
      setUserIsAuthenticated(false);
    }
    if (!storedToken) {
      if (isAuthenticated) {
        (async () => {
          await fetchAuth0Token();
        })();
      }
    } else {
      syncData();
      setUserIsAuthenticated(true);
    }
    dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if ((isLoading && !storedToken) || (isAuthenticated && isEmptyObject(storeUser))) {
    return (
      <Layout className={'site-loading'}>
        <Spin />
      </Layout>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Affix offsetTop={0}>
          <MainMenuWrapper isAuthenticated={userIsAuthenticated} acceptedTermsOfService={acceptedTermsOfService} />
        </Affix>
        <ReconnectingNotification />
        <ActiveAppointmentsBanner />
        <ScrollToTopHandler />
        <Content className="site-layout-background">
          {
            !isEmptyObject(storeUser) && !acceptedTermsOfService
              ? (<TOS />)
              : (
                <Switch>
                  <PublicRoute
                    exact
                    path={NAV_ROOT}
                    component={HomeScreen}
                  />

                  <PublicRoute
                    exact
                    path={NAV_LOGIN}
                    component={LoginScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_VISITS}
                    component={VisitsScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_VISITS_SINGLE}
                    component={VisitsScreenSingle}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={`${NAV_VISITS_SESSION}/:id`}
                    component={VisitsSessionScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={`${NAV_APPOINTMENTS}`}
                    component={AppointmentsScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_APPOINTMENTS_DOCTORS}
                    component={AppointmentsDoctorsListScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_APPOINTMENTS_DOCTORS_SINGLE}
                    component={AppointmentsDoctorsSingleScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_APPOINTMENTS_SINGLE}
                    component={AppointmentsScreenSingle}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_SYMPTOM_CHECKER}
                    component={SymptomsCheckerScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_PROFILE}
                    component={ProfileScreen}
                  />

                  <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    exact
                    path={NAV_NOTIFICATIONS}
                    component={NotificationsScreen}
                  />

                  <PublicRoute
                    component={NotFoundScreen}
                  />
                </Switch>
              )
          }

        </Content>
        <MiniVideoCallPlayer />
        <FooterContainer />
        <AppointmentInvalidationModal
          visible={appointmentInvalidationModalVisible}
          appointment={appointmentInavlidationAppointment}
          onCancel={closeInvalidationModal}
          onAppointmentCancelled={closeInvalidationModal}
        />
        <BookAppointmentModal
          visible={bookAppointmentModalVisible}
          slot={bookAppointmentSlot}
          doctor={bookAppointmentDoctor}
          onCancel={closeBooKModal}
          onAppointmentCreated={closeBooKModal}
        />
        <CallNowDoctorModal
          visible={callInitModalVisible}
          appointment={callInitAppointment}
          onCancel={closeCallModal}
          onConsultationCreated={closeCallModal}
        />
        <OutgoingCallModal visible={outogingConsultation?.status === 'new'} />
        <DownloadAppsModal />
      </Layout>
    </Layout>
  )
}

export default Root;
