import React from 'react';
import { Participant } from 'twilio-video';

import AvatarIcon from '../../../icons/AvatarIcon';

import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import useIsTrackSwitchedOff from '../../../plugins/video_call/hooks/useIsTrackSwitchedOff';
import usePublications from '../../../plugins/video_call/hooks/usePublications';
import useScreenShareParticipant from '../../../plugins/video_call/hooks/useScreenShareParticipant';
import useTrack from '../../../plugins/video_call/hooks/useTrack';
import useVideoContext from '../../../plugins/video_call/hooks/useVideoContext';
import useParticipantIsReconnecting from '../../../plugins/video_call/hooks/useParticipantIsReconnecting';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import useParticipantDisplayInfo from '../../../plugins/video_call/hooks/useParticipantDisplayInfo';

const useStyles = () => {
  return {
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    identity: {
      // background: 'rgba(0, 0, 0, 0.5)',
      // color: 'white',
      // padding: '0.1em 0.3em 0.1em 0',
      background: 'rgba(252,252,252,1)',
      // color: '#000',
      color: '#8E1230',
      borderRadius: '4px',
      marginTop: '12px',
      marginLeft: '12px',
      padding: '16px',
      fontSize: '1.2em',
      fontWeight: 'bold',
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        marginLeft: '0.3em',
      },
    },
    identityTextsContainer: {
      marginLeft: '12px',
    },
    identitySubtitle: {
      fontSize: '1em',
      fontWeight: 'normal',
      color: '#000',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      height: '100%',
      width: '100%',
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    fullWidth: {
      gridArea: '1 / 1 / 2 / 3',
      // [theme.breakpoints.down('sm')]: {
      //   gridArea: '1 / 1 / 3 / 3',
      // },
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      '& svg': {
        transform: 'scale(2)',
      },
    },
  };
};

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({
  participant,
  children,
}: MainParticipantInfoProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { room } = useVideoContext();
  const localParticipant = room.localParticipant;
  const isLocal = localParticipant === participant;

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find((p) => p.kind === 'video');
  const screenSharePublication = publications.find((p) =>
    p.trackName.includes('screen'),
  );

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack) && videoTrack.isEnabled;

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);
  const { name, subtitle } = useParticipantDisplayInfo(participant);

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      style={{
        ...classes.container,
        ...(!isRemoteParticipantScreenSharing ? classes.fullWidth : {}),
      }}>
      <div style={classes.infoContainer}>
        <div style={classes.identity}>
          <AudioLevelIndicator audioTrack={audioTrack} color={'#000'} />
          <div style={classes.identityTextsContainer}>
            <Typography.Text style={{ color: 'inherit' }}>
              {name}
              {isLocal &&
                ` ${t('(You)', {
                  context: 'Indicating that is you during a video call',
                })}`}
              {screenSharePublication && ' - Screen'}
            </Typography.Text>
            <br />
            <Typography.Text style={classes.identitySubtitle}>
              {subtitle}
            </Typography.Text>
          </div>
        </div>
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div style={classes.avatarContainer}>
          <AvatarIcon />
        </div>
      )}
      {isParticipantReconnecting && (
        <div style={classes.reconnectingContainer}>
          <Typography.Text style={{ color: 'white' }}>
            {t('Reconnecting…')}
          </Typography.Text>
        </div>
      )}
      {children}
    </div>
  );
}
