// @flow
import * as React from 'react';
import { colors } from '../../styles';
import { Button, Spin, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { iconForFileType, isImageFile, humanFriendlyFileSize } from './utils/files';
import { composeStyles } from '../../utils/styling';
import { useImgSourceForFile } from './useImgSourceForFile';
import type { File } from '../../config/types';

type Props = {
  file?: File,
  style?: any,
  editable?: boolean,
  loading?: boolean,
  error?: boolean,
  onPress?: (file: File) => any,
  onDeletePress?: (file: File) => any,
};

const THUMB_BORDER_RADIUS = 8;
const baseClassName = 'file-thumb';

const FileThumb = (props: Props): React.Node => {
  const {
    editable,
    error,
    file,
    loading,
    style,
    onPress,
    onDeletePress,
  } = props;
  const [downloading, setDownloading] = React.useState(false);
  const [downloadFailed, setDownloadFailed] = React.useState(false);
  const { imgSource, imgDownloading, imgDownloadFailed } = useImgSourceForFile(file, true);

  const pressedThumbStyle: Object = loading ? styles.overlayPressed : {};
  const errorStyle = error ? styles.failedToLoad : {};
  return (
    <Tooltip title={(
      <div>
        {file?.filename}
        {file?.blob != null ? (
          <>
            <br />----------<br />
            {humanFriendlyFileSize(file?.size || 0)}
          </>
        ) : null}
      </div>
    )}>
      <div
        className={`${baseClassName}__container`}
        style={composeStyles([
          styles.container,
          style,
        ])}>
        {isImageFile(file) && !imgDownloading && !imgDownloadFailed ? (
          <img
            style={styles.image}
            alt={file?.filename}
            src={imgSource}
            onLoadStart={() => {
              setDownloadFailed(false);
              setDownloading(true);
            }}
            onLoad={() => setDownloading(false)}
            onError={() => setDownloadFailed(true)}
            onClick={() => onPress && onPress(file || {})}
          />
        ) : null}
        {loading ||
          error ||
          imgSource == null ||
          imgDownloading ||
          imgDownloadFailed ||
          downloading ||
          downloadFailed ? (
            <div
              style={composeStyles([
                styles.overlay,
                pressedThumbStyle,
                errorStyle,
              ])}
              onClick={() => onPress && onPress(file || {})}>
              {loading || downloading || imgDownloading ? <Spin /> : null}
              {(imgSource == null || imgDownloadFailed || downloadFailed) && !loading && !imgDownloading ? (
                iconForFileType(file?.type || '', {
                  style: styles.noSourceIcon,
                })
              ) : null}
            </div>
          ) : null}
        {editable ? (
          <Button
            type={'primary'}
            shape={'circle'}
            icon={<CloseOutlined />}
            size={'small'}
            className={`${baseClassName}__close-btn`}
            style={styles.closeButtonIcon}
            onClick={() => {
              if (onDeletePress) {
                onDeletePress(file || {});
              }
            }} />
        ) : null}
      </div>
    </Tooltip>
  );
};

const styles = {
  container: {
    backgroundColor: colors.light_gray,
    borderRadius: THUMB_BORDER_RADIUS,
  },
  image: {
    height: '60px',
    width: '60px',
    borderRadius: THUMB_BORDER_RADIUS,
    objectFit: 'cover',
  },
  overlay: {
    display: 'flex',

    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: THUMB_BORDER_RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayPressed: {
    backgroundColor: colors.black_20,
    borderRadius: THUMB_BORDER_RADIUS,
  },
  failedToLoad: {
    backgroundColor: colors.error_40,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '25px',
    height: '25px',
    borderRadius: 25 / 2,
    backgroundColor: colors.brand,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButtonIcon: {
    position: 'absolute',
    right: 0,
    width: '25px',
    height: '25px',
    minWidth: '25px',
  },
  noSourceIcon: {
    fontSize: '25px',
    color: colors.white,
  },
};

export default FileThumb;
