//@flow
import { createAxiosInstance } from './_axios';
import { getDefaultPageSize } from './lib/defaults';
import { getQueryString } from './lib/utils';
import type {
  ApiResponseArray,
  Doctor,
  City,
  Speciality,
} from '../config/types';

const api = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 * Get doctor profile with params
 */
export const getDoctorProfile = (id: number, params: Object): Promise<{ data: Doctor }> => {
  return api.get(`api/doctors/${id}${getQueryString(params)}`);
};

/**
 * Lists all doctors.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listDoctors = (
  params: Object,
): Promise<{ data: ApiResponseArray<Doctor> }> => {
  const callParams = {
    page_size: getDefaultPageSize(),
    ...(params || {}),
  };
  return api.get(`api/doctors${getQueryString(callParams)}`);
};

/**
 * Lists all cities.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listCities = (params: Object): Promise<{ data: Array<City> }> => {
  return api.get(`api/cities${getQueryString(params)}`);
};

/**
 * Lists all specialities.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listDoctorsSpecialities = (
  params: Object,
): Promise<{ data: Array<Speciality> }> => {
  return api.get(`api/doctors/specialities${getQueryString(params)}`);
};
