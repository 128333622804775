import React, { useState } from 'react';
import { Typography, Select } from 'antd';
// import { useAppState } from '../../../state';
import useDevices from '../../../../plugins/video_call/hooks/useDevices';

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useState();
  const activeOutputLabel = audioOutputDevices.find(
    (device) => device.deviceId === activeSinkId,
  )?.label;

  return (
    <div className="inputSelect">
      <Typography.Text strong>Audio Output</Typography.Text>
      <br />
      {audioOutputDevices.length > 1 ? (
        <Select
          style={{ width: '200px' }}
          onChange={(e) => setActiveSinkId(e.value)}
          defaultValue={activeSinkId}>
          {audioOutputDevices.map((device) => (
            <Select.Option value={device.deviceId} key={device.deviceId}>
              {device.label}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Typography.Text>
          {activeOutputLabel || 'System Default Audio Output'}
        </Typography.Text>
      )}
    </div>
  );
}
