// @flow
import React, { createRef } from 'react';
import * as api from '../../api/files';
import FilesGallery from '../filesGallery/FilesGallery';
import FileThumb from './FileThumb';
import AddFileButton from './AddFileButton';
import { filterImages, isFileGotFromServer, isImageFile } from './utils/files';
import { getUrlForFileDisplay } from '../../utils';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { composeStyles } from '../../utils/styling';
import type { File } from '../../config/types';

/**
 * Accepts an array of files in a react-dropzone format and converts
 * them to a format that is unified to all icare projects (web/mobile).
 * @param {*} files Array of files that user added.
 * @returns
 */
const convertLocalFilesToCustomType = (files) => {
  return files.map((tmp) => {
    const { path, name, type, size } = tmp;
    return {
      id: `local-${name}-${Date.now() + Math.random().toFixed(3)}`,
      file: path,
      filename: name,
      type,
      size,
      blob: tmp,
    };
  });
}

const arraysEqual = (a, b) => {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  for (var i = 0; i < a.length; ++i) {
    const itemA = typeof a[i] === 'number' ? a[i] : a[i].id;
    const itemB = typeof b[i] === 'number' ? b[i] : b[i].id;
    if (itemA !== itemB) {
      return false;
    }
  }
  return true;
};

type Props = {
  files?: Array<File>,
  style?: any,
  className?: string,
  thumbStyle?: any,
  editable?: boolean,
  /**
   * When `true`, the new files that the user adds, will be automatically be
   * uploaded to the server. Default - `true`
   */
  autoUploadNewFiles?: boolean,
  /**
   * When this value changes, the component will update the internal state with
   * the `files` from props.
   */
  resetIdentifier?: string,
  t: Function,
  onFileThumbPress: (file: File, index: number) => boolean,
  onFilesUpdated: (files: Array<File>) => void,
};

type State = {
  localFiles: Array<File>,
  filesGalleryVisible: boolean,
  filesGalleryIndex: number,
};

class FilesManager extends React.Component<Props, State> {
  dropzoneRef: any;

  static defaultProps = {
    autoUploadNewFiles: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      localFiles: props.files || [],
      filesGalleryVisible: false,
      filesGalleryIndex: 0,
    };

    this.dropzoneRef = createRef();
  }

  componentDidUpdate(prevProps: Props, prevStage: State) {
    if (prevProps.resetIdentifier !== this.props.resetIdentifier) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ localFiles: this.props.files });
    } else {
      if (!arraysEqual(prevProps.files, this.props.files)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ localFiles: this.props.files });
      }
    }
  }

  // Files Gallery

  filesGallery_onRequestClose = () => {
    this.setState({ filesGalleryVisible: false });
  };

  // ----------

  notifyParent = () => {
    if (this.props.onFilesUpdated) {
      this.props.onFilesUpdated(this.state.localFiles);
    }
  };

  updateLocalFile = (localId: any, updateObj: Object) => {
    const existingFiles = [...this.state.localFiles];
    const index = existingFiles.findIndex((tmp) => tmp.id === localId);
    if (index > -1) {
      existingFiles[index] = {
        ...existingFiles[index],
        ...(updateObj || {}),
      };
      this.setState({ localFiles: existingFiles }, this.notifyParent);
    }
  };

  uploadFile = (file: File) => {
    const localId = file.id;
    if (file.file != null) {
      this.updateLocalFile(localId, { status: 'uploading' });
      api
        .uploadFile({ blob: file.blob })
        .then((result) => {
          console.log('uploaded', result.data);
          this.updateLocalFile(localId, { ...result.data, status: null });
        })
        .catch((e) => {
          console.log('failed to upload', e);
          this.updateLocalFile(localId, { status: 'failed' });
        });
    }
  };

  onAddNewPress = () => {
    this.dropzoneRef?.current?.open();
  };

  onAddNewFiles = (files) => {
    this.setState(
      {
        localFiles: [...this.state.localFiles, ...files],
      },
      () => {
        if (this.props.autoUploadNewFiles) {
          for (let i = 0; i < files.length; i++) {
            setTimeout(() => this.uploadFile(files[i]));
          }
        } else {
          this.notifyParent();
        }
      },
    );
  };

  onDrop = (acceptedFiles) => {
    console.log('accepted files', acceptedFiles);
    this.onAddNewFiles(convertLocalFilesToCustomType(acceptedFiles));
  };

  onFileThumbPress = async (file: File) => {
    if (!isImageFile(file)) {
      const url = await getUrlForFileDisplay(file);
      window.open(url, '_blank');
      return;
    }

    // the gallery displays only images
    // so, index should be relative to images
    //
    // For example:
    // files:    [image, image, pdf, image]
    // indicies: [  0  ,   1  ,  2 ,   3  ]
    // the last file should have index **2** when gallery opens
    const index = this.state.localFiles
      .filter(filterImages)
      .findIndex((tmp) => tmp.id === file.id);
    let handled = false;
    if (this.props.onFileThumbPress) {
      // when letting other components handle thumb-press,
      // the index should reflect the real position in the `files` array
      // (index `3` based on the Example above)
      const realIndex = this.state.localFiles.findIndex((tmp) => tmp.id === file.id);
      handled = this.props.onFileThumbPress(file, realIndex);
    }

    if (!handled && index > -1) {
      this.setState({
        filesGalleryVisible: true,
        filesGalleryIndex: index,
      });
    }
  };

  onDeleteFile = (file: File) => {
    if (isFileGotFromServer(file) && typeof file.id === 'number') {
      api.deleteFile(file.id).catch((e) => console.log(e));
    }

    const existingFiles = [...this.state.localFiles];
    const index = existingFiles.findIndex((tmp) => tmp.id === file.id);
    if (index > -1) {
      existingFiles.splice(index, 1);
    }
    this.setState({ localFiles: existingFiles }, this.notifyParent);
  };

  render() {
    const { localFiles } = this.state;
    return (
      <Dropzone
        ref={this.dropzoneRef}
        accept={['image/jpeg', 'image/png', 'image/gif', 'application/pdf']}
        onDropAccepted={this.onDrop}
        noClick={true}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={this.props.className}
            style={composeStyles([
              styles.container,
              this.props.style,
            ])}
            {...getRootProps()}>
            <input {...getInputProps()} />
            {localFiles?.map((f) => (
              <FileThumb
                style={composeStyles([
                  styles.thumb,
                  this.props.thumbStyle,
                ])}
                editable={this.props.editable}
                key={`${f.id}`}
                file={f}
                loading={f.status === 'uploading'}
                error={f.status === 'failed'}
                onPress={this.onFileThumbPress}
                onDeletePress={this.onDeleteFile}
              />
            ))}
            {this.props.editable ? (
              <AddFileButton
                style={composeStyles([
                  styles.addFile,
                  (localFiles.length === 0 ? styles.noSpaceAddFile : {}),
                ])}
                onPress={this.onAddNewPress}
              />
            ) : null}

            {this.state.filesGalleryVisible ?
              <FilesGallery
                initialPage={this.state.filesGalleryIndex}
                files={this.state.localFiles}
                onRequestClose={this.filesGallery_onRequestClose}
              />
              : null}
          </div>
        )}
      </Dropzone>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  thumb: {
    marginRight: '8px',
    marginBottom: '8px',
  },
  addFile: {
    marginBottom: '8px',
  },
  noSpaceAddFile: {
    marginLeft: '0px',
  },
};

// $FlowFixMe
export default withTranslation()(FilesManager);
