// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { updateAppointment } from '../../../store/slices/appointmentsSlice';

// Components imports
import { Button, Form, Input, Modal, Select, Typography } from 'antd';
import { VideoCameraOutlined, CloseCircleFilled } from '@ant-design/icons';

import type { Appointment } from '../../../config/types';
import { colors } from '../../../styles';
import message from '../../../utils/message';

type Props = {
  visible?: boolean,
  appointment: Appointment,
  onCancel?: (e: any) => void,
  onAppointmentCancelled?: () => void,
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const AppointmentInvalidationModal = (
  props: Props,
): React$Element<React$FragmentType> => {
  const { visible, appointment, onCancel, onAppointmentCancelled } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();

  const cancellationChoices: Array<{
    value: string,
    text: string,
    validChoice: boolean,
    requiresMoreInformation: boolean,
  }> =
    [
      // {
      //   value: 'select',
      //   text: t('Select…'),
      //   validChoice: false,
      //   requiresMoreInformation: false,
      // },
      {
        value: 'mshp',
        text: t('My symptoms have passed'),
        validChoice: true,
        requiresMoreInformation: false,
      },
      {
        value: 'na',
        text: t('Not available'),
        validChoice: true,
        requiresMoreInformation: false,
      },
      {
        value: 'personal',
        text: t('Personal reasons'),
        validChoice: true,
        requiresMoreInformation: false,
      },
      {
        value: 'other',
        text: t('Other'),
        validChoice: true,
        requiresMoreInformation: true,
      },
    ];

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const onFinish = async (values: any) => {
    console.log(values);
    const { reason, additional_information } = values;

    const choice = cancellationChoices.find((tmp) => tmp.value === reason) || {};

    const data = {
      status: 'cancelled',
      response_message: [
        choice.validChoice ? choice.text : '',
        additional_information || '',
      ]
        .join('\n\n')
        .trim(),
    };

    try {
      setLoading(true);
      const actionData = await dispatch(updateAppointment({ id: appointment?.id, data }));
      const newAppointment = unwrapResult(actionData);

      if (onAppointmentCancelled != null) {
        // $FlowFixMe
        onAppointmentCancelled(newAppointment);
      }
    } catch (e) {
      if (e.message !== 'cancelled') {
        message.error(e.message);
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      title={
        <Typography.Title level={5} style={styles.title}>
          {t('Appointment cancellation')}
        </Typography.Title>
      }
      centered
      closable={true}
      closeIcon={<CloseCircleFilled />}
      visible={visible}
      destroyOnClose={true}
      width={831}
      bodyStyle={styles.body}
      footer={null}
      onCancel={onCancel}>
      <div style={styles.contentContainer}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Typography.Title level={5} style={styles.contentTitle}>
            {t('Cancellation reason')}
          </Typography.Title>
          <Form.Item
            name="reason"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const choice = cancellationChoices.find((tmp) => tmp.value === value) || {};
                  if (!choice.validChoice) {
                    return Promise.reject(new Error(t('Please select a reason from the list')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select placeholder={t('Select…')}>
              {cancellationChoices.map((tmp) => (
                <Select.Option key={tmp.value} value={tmp.value}>
                  {tmp.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Typography.Title level={5} style={styles.contentTitle}>
            {t('Additional information')}
          </Typography.Title>
          <Form.Item
            name="additional_information"
            initialValue={''}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const choice = cancellationChoices.find((tmp) => tmp.value === getFieldValue('reason')) || {};
                  if (choice.requiresMoreInformation && !value) {
                    return Promise.reject(new Error(t('Additional information are required for reason "Other"')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <div style={styles.buttonContainer}>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              icon={<VideoCameraOutlined />}
              style={styles.actionButton}>
              {t('Cancel appointment')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const styles = {
  title: {
    textAlign: 'center',
    marginBottom: '0px',
  },
  body: {
    background: '#F5F5F5',
    borderRadius: '0 0 8px 8px',
  },
  contentTitle: {
    marginTop: '24px',
  },
  contentContainer: {
    maxWidth: '451px',
    margin: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButton: {
    flexGrow: 1,
    maxWidth: '360px',
    marginTop: '98px',
    marginBottom: '24px',
    background: colors.call_green,
  },
};

export default AppointmentInvalidationModal;
