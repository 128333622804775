import React, { useState } from 'react';

/**
 * Generic hook for keeping track of `Input` component's value.
 *
 * @example
 * ```
 * const randomFormInputState = useInputState();
 * ...
 * <Input
 *   placeholder='please, type something'
 *   {...randomFormInputState}
 * />
 * ```
 *
 * @param {string} initialValue The input's initial value.
 */
export const useInputState = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);
  return { value, onChange: (e) => setValue(e?.target?.value)};
};
