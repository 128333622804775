// @flow
import * as React from 'react';
import { Route } from 'react-router-dom';
import { PageHeader, Spin } from 'antd';
import { withAuthenticationRequired } from '@auth0/auth0-react';
type Props = {
  component: Object | Function,
  isAuthenticated: boolean,
};

const PrivateRoute = (props: Props): React$Element<any> => {
  const { component: Component, isAuthenticated, ...restProps } = props;
  return (
    <Route
      {...restProps}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default (withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.
  // eslint-disable-next-line react/display-name
  onRedirecting: () => {
    return (
      <PageHeader>
        <div className={'text-center'}>
          <Spin />
        </div>
      </PageHeader>
    );
  },
}): any);
