import { combineReducers } from '@reduxjs/toolkit';
import { profileReducer } from './profileSlice';
import { authReducer } from './authSlice';
import { consultationReducer } from './consultationSlice';
import { doctorsReducer } from './doctorsSlice';
import { notificationsReducer } from './notificationsSlice';
import { symptomsCheckerReducer } from './symptomsCheckerSlice';
import { medicalProfileReducer } from './medicalProfileSlice';
import { applicationReducer } from './applicationSlice';
import { appointmentsReducer } from './appointmentsSlice';

// Combine everything in a root reducer
const RootReducer = combineReducers({
  application: applicationReducer,
  profile: profileReducer,
  auth: authReducer,
  consultation: consultationReducer,
  doctors: doctorsReducer,
  notifications: notificationsReducer,
  symptoms_checker: symptomsCheckerReducer,
  medical_profile: medicalProfileReducer,
  appointments: appointmentsReducer
});

export default RootReducer;
