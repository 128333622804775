// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Components imports
import { Divider, Typography } from 'antd';
import InfoCardHeader from '../InfoCardHeader';
import ConsultationInfo from '../ConsultationInfo';
import type { Consultation } from '../../config/types';

// Misc imports
import { getCityStringForDoctor, getUserFullname } from '../../utils';

// const declarations
import { NAV_VISITS, NAV_APPOINTMENTS } from '../../constants.js';

const { Text } = Typography;
// const baseClassName = 'info-card';
type Props = {
  item: Consultation,
  headerOnly?: boolean,
  isAppointment?: boolean,
  linkTo?: string,
  children?: any,
  canItemBeSelected?: boolean,
  isItemSelected?: boolean,
}

const InfoCard = (props: Props): React$Element<"div"> => {
  const { t } = useTranslation();
  const { item, headerOnly, isAppointment } = props;
  if(!item) {
    return (<Text>{t('No data available')}</Text>);
  }
  const doctor = item.doctor ?? item;
  const imgSrc = doctor?.avatar;
  const doctorsFullName = getUserFullname(doctor);
  let doctorsSpeciality = '';
  if (isAppointment) {
    doctorsSpeciality = `${doctor?.speciality || ''} | ${getCityStringForDoctor(doctor)}`
  } else {
    doctorsSpeciality = doctor?.speciality || '';
  }

  const getLink = () => {
    if (!props.canItemBeSelected) {
      if (props.linkTo) {
        return props.linkTo;
      }
      if (isAppointment) {
        return `${NAV_APPOINTMENTS}/${item.id}`
      }
      return `${NAV_VISITS}/${item.id}`;
    }
  }

  return (
    <div className={props.isItemSelected ? 'info-card info-card-selected' : 'info-card'}>
      <Link
        to={getLink()}
      >
        <InfoCardHeader
          imgSrc={imgSrc}
          title={doctorsFullName}
          subTitle={doctorsSpeciality}
        />
        {
          !headerOnly
            ? <>
              <Divider />
              <ConsultationInfo
                item={item}
                isAppointment={isAppointment}
              />
            </>
            : null
        }
      </Link>
      {props.children}
    </div>
  )
}

export default InfoCard;
