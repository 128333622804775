// @flow
import { createAxiosInstance } from './_axios';
import { getDefaultPageSize } from './lib/defaults';
import type { ApiResponseArray, Consultation } from '../config/types';
import { getQueryString } from './lib/utils';

const consultationApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 * Creates a new consultation.
 *
 * @param {Consultation} data Consultation's data.
 */
export const createConsultation = (data: Consultation): Promise<Consultation> =>
  consultationApi.post('api/consultations', data);

/**
 * Update an existing consultation.
 *
 * @param {number} id Consultation's id.
 * @param {Consultation} data Consultation data to update.
 */
export const updateConsultation = (id: number, data: Consultation): Promise<Consultation> =>
  consultationApi.patch(`api/consultations/${id}`, data);

/**
 * Retrieves a single consultation.
 *
 * @param {number} id Consultation's id.
 */
export const getConsultation = (id: number): Promise<Consultation> =>
  consultationApi.get(`api/consultations/${id}`);

/**
 * Lists all consultations.
 *
 * @param {Object} params Parameters to filter the results of the api call.
 */
export const listConsultations = (
  params: Object
): Promise<ApiResponseArray<Consultation>> => {
  const callParams = {
    page_size: getDefaultPageSize(),
    ...(params || {})
  };
  return consultationApi.get(`api/consultations${getQueryString(callParams)}`);
};
