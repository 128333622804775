// @flow
import { safeParseInt } from './index';
import { translate } from '../locales/index';

export const isValidValue = (
  value: any,
  validators: Array<{ type: string, value: number }> = [],
): {
  valid: boolean,
  message?: string,
} => {
  let valid = true;
  let message;

  if (value != null) {
    let i = 0;
    const total = validators.length;

    while (i < total && valid) {
      const tempValidator = validators[i];

      if (tempValidator.type === 'min') {
        const intValue = safeParseInt(value) || 0;
        if (intValue < tempValidator.value) {
          valid = false;
          message = translate('The minimum acceptable value is {{num}}', {
            num: tempValidator.value,
          });
        }
      } else if (tempValidator.type === 'max') {
        const intValue = safeParseInt(value) || 0;
        if (intValue > tempValidator.value) {
          valid = false;
          message = translate('The maximum acceptable value is {{num}}', {
            num: tempValidator.value,
          });
        }
      } else if (tempValidator.type === 'max_length') {
        if (`${value}`.length > tempValidator.value) {
          valid = false;
          message = translate('The value cannot exceed {{num}} characters', {
            num: tempValidator.value,
          });
        }
      }
      i++;
    }
  }

  return {
    valid,
    message,
  };
};
