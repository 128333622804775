// @flow
import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import type { SCMessage } from '../../config/types';

const { Text } = Typography;
const baseClassName = 'message'
type Props = {
  message: SCMessage,
};

const SymptomsCheckerMessage = (props: Props): React$Element<any> => {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <div
      key={message.id}
      className={`${baseClassName} ${message.sender === 'other'
        ? 'incoming-message'
        : 'outgoing-message'}`}
    >
      {message.condition != null ? (
        <>
          <Text
            className={`probability ${message.sender === 'me' ? 'outgoing-message-text' : ''}`}
          >
            {t('{{number}} of 100 people with your symptoms have', {
              number: Math.floor(message.condition.probability * 100)
            })}
          </Text>
          &nbsp;
          <Text
            className={`condition-text ${message.sender === 'me' ? 'outgoing-message-text' : ''}`}
          >
            {message.condition?.name || message.message}
          </Text>
        </>
      ) : (
        <Text
          className={`${message.sender === 'me' ? 'outgoing-message-text' : ''}`}
        >
          {message.message || message.condition?.name}
        </Text>
      )}
    </div>
  );
};

export default SymptomsCheckerMessage;
