// @flow
import { createAxiosInstance } from './_axios';

const profileApi = createAxiosInstance({
  headers: {
    'Content-Type': 'application/json'
  },
  needsAuth: true
});

/**
 * Get users profile data
 *
 * @param {Object} params
 */
export const getMyProfile = (params: Object): Promise<Object> =>
  profileApi.get('api/me', {...params});

/**
 * Get patient profile data
 */
export const getPatientProfile = (id: number): Promise<Object> =>
  profileApi.get(`/api/patients/${id}`);

/**
 * Get doctor profile data
 */
export const getDoctorProfile = (id: number): Promise<Object> =>
  profileApi.get(`/api/doctors/${id}`);

/**
 * Update patient profile partially.
 * @param {doctor id} id
 * @param {data to be update} data
 */
export const updateProfile = (id: number, data: Object): Promise<Object> =>
  profileApi.patch(`api/patients/${id}`, data);
