import React from 'react';
import Participant from '../Participant/Participant';
import useMainParticipant from '../../../plugins/video_call/hooks/useMainParticipant';
import useParticipants from '../../../plugins/video_call/hooks/useParticipants';
import useVideoContext from '../../../plugins/video_call/hooks/useVideoContext';
import useSelectedParticipant from '../../../plugins/video_call/VideoProvider/useSelectedParticipant';
import useScreenShareParticipant from '../../../plugins/video_call/hooks/useScreenShareParticipant';

const useStyles = () => {
  const theme = {
    breakpoints: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      up: () => 50,
      down: () => 50,
      between: () => 50,
      only: () => 50,
      width: () => 50,
    },
    sidebarWidth: 355,
    sidebarMobilePadding: 8,
  };
  return {
    container: {
      overflowY: 'auto',
      // background: 'rgb(79, 83, 85)',
      gridArea: '1 / 2 / 1 / 3',
      zIndex: 5,
    },
    transparentBackground: {
      background: 'transparent',
    },
    scrollContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    innerScrollContainer: {
      width: `calc(${theme.sidebarWidth}px - 4em)`,
      padding: '2em 0',
    },
  };
};

export default function ParticipantList() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const localParticipant = room.localParticipant;
  const participants = useParticipants();
  const [
    selectedParticipant,
    setSelectedParticipant,
  ] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant;

  if (participants.length === 0) {
    return null; // Don't render this component if there are no remote participants.
  }

  return (
    <aside
      style={{
        ...classes.container,
        ...(isRemoteParticipantScreenSharing
          ? classes.transparentBackground
          : {}),
      }}>
      <div style={classes.scrollContainer}>
        <div style={classes.innerScrollContainer}>
          <Participant
            participant={localParticipant}
            isLocalParticipant={true}
            isSelected={mainParticipant === localParticipant}
            onClick={() => setSelectedParticipant(localParticipant)}
            hideParticipant={mainParticipant === localParticipant}
          />
          {participants.map((participant) => {
            const isSelected = participant?.sid === selectedParticipant?.sid;
            // const hideParticipant =
            //   participant === mainParticipant &&
            //   participant !== screenShareParticipant &&
            //   isSelected && participants.length < 2;
            return (
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={isSelected}
                onClick={() => setSelectedParticipant(participant)}
                hideParticipant={mainParticipant === participant}
              />
            );
          })}
        </div>
      </div>
    </aside>
  );
}
