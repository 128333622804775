//Basic redux toolkit imports
import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
//API imports
import { getMyProfile, updateProfile } from '../../api/profile';
//Misc imports
import { logout } from './authSlice';

// First, create the thunk
export const getProfile = createAsyncThunk(
  'account/getMyProfile',
  async (ean, thunkAPI) => {
    try {
      const { data } = await getMyProfile();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }

  }
)

export const patchProfile = createAsyncThunk(
  'account/patchProfile',
  async (payload, thunkAPI) => {
    console.log('payload: ', payload);
    try {
      const { data } = await updateProfile(payload.id, payload.data);
      console.log('data: ', data);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }

  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: {},
    isUpdatingProfile: false,
    updateProfileError: false,
    isFetchingProfile: false
  },
  reducers: { },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [getProfile.pending]: (state) => {
      state.isFetchingProfile = true;
    },
    [getProfile.rejected]: (state, { payload }) => {
      state.isFetchingProfile = false;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      // by just returning the payload we override the whole state with the payload
      // https://github.com/reduxjs/redux-toolkit/issues/383
      // return action.payload;
      state.isFetchingProfile = false;
      state.user = payload;
    },
    [patchProfile.pending]: (state) => {
      state.isUpdatingProfile = true;
      state.updateProfileError = false;
    },
    [patchProfile.fulfilled]: (state, { payload, meta }) => {
      state.isUpdatingProfile = false;
      state.updateProfileError = false;
      state.user = payload;
    },
    [patchProfile.rejected]: (state) => {
      state.isUpdatingProfile = false;
      state.updateProfileError = true;
    },
    [logout]: (state) => {
      state.user = {};
    }
  }
});

// export const getUser = ({ profile }) => profile.user;

// Destructure and export the plain action creators
// export const { } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
