// @flow
import React from 'react';

// Components imports
import { Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';

//Misc imports
import moment from 'moment';

// Const declarations
const baseClassName = 'NavDatePicker';
const currentFormat = 'ddd, DD MMM YYYY';
const serverFormat = 'YYYY-MM-DD';

type Props = {
  /**
   * The minimun date that the user can select.
   * Date format **YYYY-MM-DD**. For example 2021-05-13
   */
  minDate?: string,
  /**
   * The maximum date that the user can select.
   * Date format **YYYY-MM-DD**. For example 2021-05-13
   */
  maxDate?: string,
  className?: string,
  style?: any,
  defaultOpen?: boolean,
  defaultValue?: any,
  value?: any,
  onChange?: (date: any, dateString: string) => any,
};

const NavDatePicker = (props: Props): any => {
  const { defaultOpen, defaultValue, value, onChange } = props;

  const onSelectDate = (date, dateString) => {
    console.log(date, dateString);
    onChange && onChange(date != null ? moment(date).format(serverFormat) : null, dateString);
  };

  const onMoveBack = () => {
    const val = value || defaultValue;
    if (!val) {
      return;
    }
    const newDate = moment(val).add(-1, 'days');

    const { minDate } = props;
    if (minDate != null && moment(minDate).isAfter(newDate)) {
      return;
    }
    onChange && onChange(newDate.format(serverFormat), newDate.format(currentFormat));
  };

  const onMoveNext = () => {
    const val = value || defaultValue;
    if (!val) {
      return;
    }
    const newDate = moment(val).add(1, 'days');

    const { maxDate } = props;
    if (maxDate != null && moment(maxDate).isBefore(newDate)) {
      return;
    }
    onChange && onChange(newDate.format(serverFormat), newDate.format(currentFormat));
  };

  const disabledDate = current => {
    const { minDate, maxDate } = props;
    const currentDate = moment(current.format(serverFormat))

    if (minDate != null && maxDate == null) {
      return moment(minDate).isAfter(currentDate);
    } else if (minDate == null && maxDate != null) {
      return moment(maxDate).isBefore(currentDate);
    } else if (minDate != null && maxDate != null) {
      return moment(minDate).isAfter(currentDate) || moment(maxDate).isBefore(currentDate);
    }
    return false;
  };

  return (
    <div className={`${baseClassName} ${props.className || ''}`}>
      <Button
        type="primary"
        ghost={true}
        icon={<LeftOutlined />}
        onClick={onMoveBack}
      />
      <DatePicker
        className={`${baseClassName}__DatePicker`}
        defaultOpen={defaultOpen}
        defaultValue={defaultValue}
        format={currentFormat}
        value={value}
        onChange={onSelectDate}
        disabledDate={disabledDate}
      // suffixIcon={<DownOutlined />}
      />
      <Button
        type="primary"
        ghost={true}
        icon={<RightOutlined />}
        onClick={onMoveNext}
      />
    </div>
  )
}

export default NavDatePicker;
