// @flow
import * as React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Select,
  Spin,
  Input,
  ListItem,
  Modal,
  List,
  Popconfirm,
  Typography,
} from 'antd';
import FilesManager from '../filesManager/FilesManager';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMedicalProfileResourceItem,
  updateMedicalProfileResourceItem,
  deleteMedicalProfileResourceItem,
  selectors
} from '../../store/slices/medicalProfileSlice';
import { useFilesManagerHandler } from '../../hooks/useFilesManagerHandler';
import { useInputState } from '../../hooks/useInputState';
import { removeKeysWithUndefinedValue } from '../../utils';
import message from '../../utils/message';
import { debounce } from '../../utils/debounce';
import moment from 'moment';
import { listMedicines } from '../../api/medical_profile';
import EditRow from '../editRow/EditRow';
import InfoCard from '../editRow/EditRow';
import type {
  MPMedication,
  MPMedicine,
  MPResourceChoiceObject,
} from '../../config/types-mp';
import { composeStyles } from '../../utils/styling';
import { colors } from '../../styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

const LoadingIndicator = (props) => (
  <div style={props.style}>
    <Spin size={'small'} />
  </div>
);

const isSearchableString = (str?: String): boolean => {
  return typeof str === 'string' && `${str}`.trim().length >= 3;
};

const MPEditMedicationScreen = (props: Props) => {
  if (props?.title == null) {
    console.warn(
      'MPEditMedicationScreen expects "title" to be passed as navigation parameters',
    );
  }
  const { t } = useTranslation();
  const medication: MPMedication = props?.dataItem || {};
  const readOnly: boolean = Boolean(props?.readOnly);
  const consultation_id: ?number = props?.consultationId;
  const patient_id = useSelector((state) => state.profile.user.id);
  const itemInfo: Object = useSelector((state) =>
    selectors.getOptionsForResourceItem(state.medical_profile, 'medications'),
  );
  const dispatch = useDispatch();

  const durationChoices: Array<MPResourceChoiceObject> =
    itemInfo?.duration?.choices || [];
  const frequencyChoices: Array<MPResourceChoiceObject> =
    itemInfo?.frequency?.choices || [];

  const notesInput = React.useRef(null);
  const [saving, setSaving] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [savingErrorMeta, setSavingErrorMeta] = React.useState({});
  const {
    files,
    onFilesUpdated,
    continueWithFilesUploadCaution,
  } = useFilesManagerHandler(medication.files);

  // MEDICATION PROPERTY related --- started_at
  let medicationStartedAt;
  if (medication.started_at != null) {
    medicationStartedAt = moment(medication.started_at).toDate();
  } else {
    medicationStartedAt = new Date();
  }
  const [startedAt, setStartedAt] = React.useState(medicationStartedAt);

  // MEDICATION PROPERTY related --- duration
  const [selDurationIndex, setSelDurationIndex] = React.useState(
    durationChoices.findIndex((item) => item.value === medication.duration),
  );
  const displayDurationValue = durationChoices[selDurationIndex]?.display_name;

  // MEDICATION PROPERTY related --- frequency
  const [selFrequencyIndex, setSelFrequencyIndex] = React.useState(
    frequencyChoices.findIndex((item) => item.value === medication.frequency),
  );
  const displayFrequencyValue =
    frequencyChoices[selFrequencyIndex]?.display_name;

  // MEDICATION PROPERTY related --- name prop
  const nameInput = React.useRef(null);
  const nameState = useInputState(medication.name);

  // MEDICATION PROPERTY related --- text props
  const doseState = useInputState(medication.dose);
  const relatedConditionState = useInputState(medication.related_condition);
  const notesState = useInputState(medication.notes);
  console.log('medication', medication);

  // MEDICATION PROPERTY related --- duration
  const [doctorPrescribed, setDoctorPrescribed] = React.useState(
    medication.doctor_prescribed,
  );

  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState < Array < MPMedicine >> (
    [],
  );
  const now = new Date();
  const hundrendYearsAgo = new Date(
    now.getFullYear() - 100,
    now.getMonth(),
    now.getDate(),
  );

  const searchForMedicines = React.useCallback(
    debounce((searchText) => {
      if (isSearchableString(searchText)) {
        listMedicines({ search: searchText })
          .then((results) => {
            if (isSearchableString(searchText)) {
              setSearchResults(results.data);
              setShowSearchResults(true);
            }
          })
          .catch((error) => {
            console.log('Search medicines error', error);
            setSearchResults([]);
            setShowSearchResults(true);
          });
      } else {
        setSearchResults([]);
      }
    }, 500),
    [],
  );

  const onSave = React.useCallback(async () => {
    const data = removeKeysWithUndefinedValue({
      name: nameState.value === '' ? null : nameState.value,
      dose: doseState.value === '' ? null : doseState.value,
      related_condition:
        relatedConditionState.value === '' ? null : relatedConditionState.value,
      notes: notesState.value === '' ? null : notesState.value,
      started_at: startedAt
        ? moment(startedAt).format('YYYY-MM-DD')
        : undefined,
      duration: durationChoices[selDurationIndex]?.value,
      frequency: frequencyChoices[selFrequencyIndex]?.value,
      doctor_prescribed: doctorPrescribed,
      files,
    });

    if (Object.keys(data).length === 0) {
      // ok, there is no change..
      // just go back
      props.onCloseModal(false);
    } else {
      try {
        setSaving(true);
        setSavingErrorMeta({});
        if (medication.id) {
          await dispatch(
            updateMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              itemId: medication.id,
              data,
              resource: 'medications',
            }),
          ).then(unwrapResult);
        } else {
          await dispatch(
            createMedicalProfileResourceItem({
              patient_id,
              consultation_id,
              data,
              resource: 'medications',
            }),
          ).then(unwrapResult);
        }
        props.onCloseModal(false);
      } catch (error) {
        setSaving(false);
        setSavingErrorMeta(error.meta);
        message.error(error.message);
      }
    }
  }, [
    nameState,
    doseState,
    relatedConditionState,
    notesState,
    startedAt,
    files,
    selDurationIndex,
    durationChoices,
    selFrequencyIndex,
    frequencyChoices,
    doctorPrescribed,
    patient_id,
    consultation_id,
    medication,
    t,
    dispatch,
  ]);

  const trySave = React.useCallback(() => {
    continueWithFilesUploadCaution()
      .then(onSave)
      .catch((_) => console.log('user cancelled'));
  }, [continueWithFilesUploadCaution, onSave]);

  const onDelete = () => {
    try {
      setDeleting(true);
      if (medication.id != null) {
        dispatch(
          deleteMedicalProfileResourceItem({
            patient_id,
            consultation_id,
            itemId: medication.id,
            resource: 'medications',
          }),
        ).then(unwrapResult)
          .then(() => {
            props.onCloseModal(false);
          });
      }
    } catch (error) {
      setDeleting(false);
      message.error(error.message);
    }
  };

  const shouldRenderSuggestions =
    showSearchResults &&
    (searchResults.length > 1 ||
      (searchResults.length === 1 &&
        searchResults[0].name !== nameState.value));

  return (
    <Modal
      closable={false}
      okText={t('Save')}
      centered={true}
      maskClosable={false}
      title={t('Medications')}
      className={'profile-modal'}
      bodyStyle={styles.modalBackground}
      confirmLoading={saving}
      cancelText={t('Cancel')}
      onOk={onSave}
      onCancel={() => props.onCloseModal(!props.visible)}
      visible={props.visible}
    >
      <EditRow
        text={t('Name')}
        value={nameState.value}
        style={styles.lefSpace}>
        {!readOnly ? (
          <Input
            ref={nameInput}
            style={styles.input}
            onFocus={() => setShowSearchResults(true)}
            status={savingErrorMeta.name != null ? 'danger' : null}
            caption={
              savingErrorMeta.name != null
                ? t('This field is required')
                : null
            }
            value={nameState.value}
            onChange={(event) => {
              nameState.onChange(event);
              searchForMedicines(event.target.value);
            }}
          />
        ) : null}
      </EditRow>
      <div style={styles.lefSpace}>
        <EditRow
          text={t('Dose', { context: 'medical profile medication' })}
          value={doseState.value}>
          {!readOnly ? (
            <Input
              style={styles.input}
              placeholder={t('e.g. 1 tablet')}
              maxLength={itemInfo.dose?.max_length || 255}
              {...doseState}
            />
          ) : null}
        </EditRow>
        <EditRow
          text={t('Duration', { context: 'medical profile medication' })}
          value={displayDurationValue}>
          {!readOnly ? (
            <Select
              className={'fullWidth'}
              selectedIndex={selDurationIndex}
              placeholder={t('Select…')}
              defaultValue={displayDurationValue || t('Select…')}
              value={displayDurationValue || t('Select…')}
              status={savingErrorMeta.duration != null ? 'danger' : null}
              caption={
                savingErrorMeta.duration != null
                  ? t('This field is required')
                  : null
              }
              onChange={(selected, options) => {
                let index = durationChoices.findIndex(item => item.value === options.key);
                setSelDurationIndex(index)
              }}
              onSelect={(selected, options) => {
                let index = durationChoices.findIndex(item => item.value === options.key);
                setSelDurationIndex(index)
              }}>
              {durationChoices.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>{item.display_name}</Option>
                )
              })}
            </Select>
          ) : null}
        </EditRow>
        <EditRow
          text={t('Frequency', { context: 'medical profile medication' })}
          value={displayFrequencyValue}>
          {!readOnly ? (
            <Select
              className={'fullWidth'}
              selectedIndex={selFrequencyIndex}
              placeholder={t('Select…')}
              defaultValue={displayFrequencyValue || t('Select…')}
              value={displayFrequencyValue || t('Select…')}
              status={savingErrorMeta.frequency != null ? 'danger' : null}
              caption={
                savingErrorMeta.frequency != null
                  ? t('This field is required')
                  : null
              }
              onChange={(selected, options) => {
                let index = frequencyChoices.findIndex(item => item.value === options.key);
                setSelFrequencyIndex(index)
              }}
              onSelect={(selected, options) => {
                let index = frequencyChoices.findIndex(item => item.value === options.key);
                setSelFrequencyIndex(index)
              }}>
              {frequencyChoices.map((item) => (
                <Option key={item.value} value={item.value}>{item.display_name}</Option>
              ))}
            </Select>
          ) : null}
        </EditRow>
        <EditRow
          text={t('Started at', { context: 'medical profile medication' })}>
          {!readOnly ? (
            <DatePicker
              className={'fullWidth'}
              placeholder={moment(startedAt).format('ddd, DD MMM YYYY')}
              status={'basic'}
              format={'ddd, DD MMM YYYY'}
              controlStyle={styles.input}
              onSelect={(nextDate) => setStartedAt(nextDate)}
              min={hundrendYearsAgo}
              max={now}
            />
          ) : null}
        </EditRow>
        <EditRow
          text={t('Prescribed by doctor', {
            context: 'medical profile medication',
          })}
        >
          {!readOnly ? (
            <Checkbox
              checked={doctorPrescribed}
              onChange={(nextChecked) => setDoctorPrescribed(nextChecked.target.checked)}
              style={styles.checkbox}
            />
          ) : null}
        </EditRow>
        <EditRow
          text={t('Related condition', {
            context: 'medical profile medication',
          })}
          value={relatedConditionState.value}>
          {!readOnly ? (
            <Input
              style={styles.input}
              returnKeyType={'done'}
              {...relatedConditionState}
            />
          ) : null}
        </EditRow>
        <EditRow
          text={t('Notes', { context: 'medical profile examination' })}
          value={notesState.value}>
          {!readOnly ? (
            <TextArea
              ref={notesInput}
              style={styles.input}
              rows={4}
              textStyle={styles.notesText}
              {...notesState}
            />
          ) : null}
        </EditRow>
        <EditRow text={t('Attachments')}>
          <FilesManager
            autoUploadNewFiles={true}
            editable={!readOnly}
            files={medication.files}
            onFilesUpdated={onFilesUpdated}
          />
        </EditRow>
        {medication.id != null && !readOnly ? (
          <Popconfirm
            placement="bottom"
            title={t('Are you sure you want to delete this medication?')}
            onConfirm={onDelete}
            okText={t('Yes')}
            cancelText={t('No')}>
            <Button
              loading={deleting}
              size={'middle'}
              style={styles.deleteButton}>
              {t('Delete medication')}
            </Button>
          </Popconfirm>
        ) : null}
        {shouldRenderSuggestions ? (
          <div style={styles.searchContainer}>
            <div style={composeStyles([styles.searchList, styles.shadow])}>
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                <Text category={'s1'} style={styles.searchResultsText}>
                  {t('{{count}} result', { count: searchResults.length })}
                </Text>
                <CloseCircleOutlined
                  style={{ fontSize: 30, cursor: 'pointer' }}
                  onClick={() => { setShowSearchResults(false) }}
                />
              </div>
              <List
                itemLayout="horizontal"
                dataSource={searchResults}
                renderItem={item => {
                  return (
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      data-item={JSON.stringify(item.name)}
                      onClick={(e) => {
                        nameState.onChange({ target: { value: item.name } });
                        setShowSearchResults(false);
                        searchForMedicines(item.name);
                      }}>
                      {item.name}
                    </List.Item>
                  )
                }}
              />

            </div>
            <div
              style={styles.flex}
              onClick={() => setShowSearchResults(false)}>
              <div style={styles.flex} />
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

const styles = {
  container: {
    flex: 1,
  },
  lefSpace: {
    paddingLeft: 16,
  },
  toolbar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    textAlign: 'center',
  },
  input: {
    backgroundColor: '#ffffff',
  },
  inputText: {},
  notesText: {
    minHeight: 80,
    textAlignVertical: 'top',
  },
  checkbox: {
    alignSelf: 'flex-start',
  },
  deleteButton: {
    alignSelf: 'center',
    marginVertical: 24,
  },

  flex: {
    flex: 1,
  },
  searchContainer: {
    backgroundColor: colors.white,
    position: 'absolute',
    overflowY: 'scroll',
    maxHeight: '100%',
    top: 165,
    left: 0,
    right: 0,
    padding: 16,
    bottom: 80,
  },
  searchList: {
    maxHeight: 250,
    backgroundColor: '#ffffff',
  },
  searchResultsText: {
    padding: 4,
    borderRadius: 2,
    backgroundColor: 'rgba(72, 72, 72, 0.1)',
    paddingVertical: 8,
    textAlign: 'center',
  },
  shadow: {
    elevation: 2,
    shadowColor: 'rgba(0, 0, 0, 0.15)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 4,
    shadowOpacity: 3,
  },
  modalBackground: {
    backgroundColor: 'rgba(72, 72, 72, 0.1)'
  }
};

export default MPEditMedicationScreen;
