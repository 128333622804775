import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';
import * as DoctorsCalls from '../../api/doctors';
import { logout } from './authSlice';

/**
 * Action creator for get a doctors profile
 */
export const getDoctorProfile = createAsyncThunk(
  'doctors/getDoctorProfile',
  async ({ id, params }, thunkAPI) => {
    try {
      const { data } = await DoctorsCalls.getDoctorProfile(id, params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
* Action creator for listing all the doctors
*/
export const listDoctors = createAsyncThunk(
  'doctors/listDoctors',
  async (params, thunkAPI) => {
    try {
      const { data } = await DoctorsCalls.listDoctors(params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
* Action creator for listing all the cities
*/
export const listCities = createAsyncThunk(
  'doctors/listCities',
  async (params, thunkAPI) => {
    try {
      const { data } = await DoctorsCalls.listCities(params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
* Action creator for listing all the cities
*/
export const listDoctorsSpecialities = createAsyncThunk(
  'doctors/listDoctorsSpecialities',
  async (params, thunkAPI) => {
    try {
      const { data } = await DoctorsCalls.listDoctorsSpecialities(params);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  list: [],
  specialities: [],
  cities: [],
  isFetchingDoctors: false,
};

const doctorsSlice = createSlice({
  name: 'doctors',
  initialState,
  reducers: {
    clearDoctorsList: (state, action) => {
      state.list = [];
    }
  },
  extraReducers: {
    [listDoctors.pending]: (state) => {
      state.isFetchingDoctors = true;
    },
    [listDoctors.rejected]: (state, { payload }) => {
      state.isFetchingDoctors = false;
    },
    [listDoctors.fulfilled]: (state, { payload, meta }) => {
      const page = meta?.arg?.page || 1;
      let theList = [...payload.results];
      if (page > 1) {
        theList = [...state.list, ...theList];
      }
      state.list = theList;
      state.isFetchingDoctors = false;
    },
    [listCities.fulfilled]: (state, { payload }) => {
      state.cities = payload;
    },
    [listDoctorsSpecialities.fulfilled]: (state, { payload }) => {
      state.specialities = payload;
    },
    [logout]: (state) => {
      state = { ...initialState };
    }
  }
});

// export const getUser = ({ profile }) => profile.user;

// Destructure and export the plain action creators
export const { clearDoctorsList } = doctorsSlice.actions;
export const doctorsReducer = doctorsSlice.reducer;
