import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConsultation } from '../../api/consultation';
// Misc imports
// import { wait } from '../../utils';

export const getConsultationById = createAsyncThunk(
  'consultation/getConsultationById',
  async (id, thunkAPI) => {
    let idToFetch = id ?? thunkAPI.getState().current.id;
    try {
      const response = await getConsultation(idToFetch);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

